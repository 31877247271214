<template>
	<div>
		<!-- filter & print & lists actions -->
		<v-form class="filter" @submit.prevent="filterData(true); isActionsActive = true">
			<v-row dense class="align-center mx-0 mb-0">
				<!-- grade -->
				<v-col cols="6" sm="6" md="3" lg="1">
					<v-autocomplete
						v-model="filter.gradeId"
						:items="grades.filter(c => c.hasPlacementTest)"
						item-text="name"
						item-value="id"
						label="الصف"
						outlined
						hide-details
						dense
						auto-select-first
						append-icon
						:disabled="loading"
						@change="filter.placementTestId = null; isActionsActive = false"
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- gender -->
				<v-col cols="6" sm="6" md="3" lg="1">
					<v-autocomplete
						v-model.number="filter.genderId"
						:items="genders"
						item-text="plural"
						item-value="id"
						label="الجنس"
						auto-select-first
						outlined
						hide-details
						dense
						append-icon
						:disabled="loading"
						@change="filter.placementTestId = null; isActionsActive = false"
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- placement test -->
				<v-col cols="12" sm="6" md="3" lg="2">
					<v-autocomplete
						v-model="filter.placementTestId"
						:items="
							placementTests.filter(
								c => c.gradeId === filter.gradeId && c.studentsGender === filter.genderId
							)
						"
						item-value="id"
						:item-text="
							(item) =>
								moment(item.testDate).format('YYYY-MM-DD الساعة hh:mm a')
								.replace(/pm|am/g, (matched) => ({pm: 'م', am: 'ص'})[matched])
						"
						hide-details
						outlined
						dense
						class="rounded-medium"
						append-icon
						:loading="!placementTests.length"
						:disabled="!(filter.gradeId && filter.genderId !== null && filter.genderId >= 0) || loading"
						@change="isActionsActive = false"
						label="الامتحان"
					></v-autocomplete>
				</v-col>

				<!-- search button -->
				<v-col cols="auto">
					<v-btn
						type="submit"
						color="primary"
						class="rounded-xl px-5"
						key="search-btn"
						:disabled="loading || !filter.placementTestId"
					>بحث</v-btn>
				</v-col>

				<v-spacer/>

				<!-- print -->
				<v-col cols="auto">
					<v-btn
						color="lightGreen white--text"
						class="rounded-xl px-5"
						:loading="printLoading"
						:disabled="loading || printLoading || !isActionsActive"
						@click="downloadFile(true)"
					>طباعة القائمة</v-btn>
				</v-col>

				<!-- export button -->
				<v-col cols="auto">
					<v-btn
						color="lightBlue white--text"
						class="rounded-xl px-5"
						:loading="exportLoading"
						:disabled="loading || exportLoading || !isActionsActive"
						@click="downloadFile(false)"
					>
						ملف العلامات
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
		
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : students"
			fixed-header
			dense
			:items-per-page="25"
			:footer-props="{ 'items-per-page-options': [25, 50, 100] }"
			:height="$vuetify.breakpoint.height - 230"
			:loading="loading"
			class="elevation-2 rounded-medium pa-1"
		>
			<!-- no -->
			<template v-slot:item.no="{ index }">
				{{index + 1}}
			</template>

			<!-- name -->
			<template v-slot:item.firstName="{ item }">
				{{`${item.firstName} ${item.lastName ? item.lastName : '' }`}}
			</template>
			
			<!-- grade -->
			<template v-slot:item.gradeId="{ item }">
				{{getGradeById(item.gradeId) ? getGradeById(item.gradeId).name : '-'}}
			</template>

			<!-- mobile number -->
			<template v-slot:item.mobileNumber="{ item }">
				{{ item.mobileNumber | isAvailable }}
			</template>

			<!-- phone number -->
			<template v-slot:item.phoneNumber="{ item }">
				{{ item.phoneNumber | isAvailable }}
			</template>

			<!-- ninth grade total -->
			<template v-slot:item.ninthGradeTotal="{ item }">
				{{ item.ninthGradeTotal | isAvailable }}
			</template>

			<!-- state -->
			<template v-slot:item.state="{ item }">
				{{ item.state | isAvailable }}
			</template>

			<!-- has previous tests -->
			<template v-slot:item.hasPreviousTests="{ item }">
				{{ item.hasPreviousTests ? 'قديم' : 'جديد' }}
			</template>

			<!-- page text -->
			<template v-slot:footer.page-text="items"> 
				<span>{{ items.pageStart }} - {{ items.pageStop }} من {{ items.itemsLength }}</span>
			</template>
		</v-data-table>

		<!-- total -->
		<v-card elevation="1" class="flex-grow-1 mt-1 rounded-medium">
			<v-card-text class="black--text d-flex py-1 px-2">
				عدد المسجلين: 
				<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
					{{statistics.studentsCount}} طالب
				</span>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { genders } from '@/helpers/enums'
import moment from 'moment'
import rules from '@/helpers/validation rules'

export default {
	name: 'StudentsData',
	
	data: () => ({
		// helpers
		genders,

		importDialog: false,

		submitLoading: false,
		loading: false,
		printLoading: false,
		exportLoading: false,

		rules,

		headers: [
			{ text: '#', value: 'no', align: 'center', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, width: 30 },
			{ text: 'الرقم', value: 'studentNo', class: 'px-1', cellClass: 'text-body-2 px-1', divider: true },
			{ text: 'الاسم', value: 'firstName', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'هـ. الطالب', value: 'mobileNumber', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'هـ. ولي الأمر', value: 'parentsMobileNumber', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'هـ. الأرضي', value: 'phoneNumber', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'المدرسة السابقة', value: 'previousSchool', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'م. التاسع', value: 'ninthGradeTotal', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'الحالة', value: 'hasPreviousTests', class: 'px-1', cellClass: 'text-body-2 px-1' },
		],

		// filter
		filter: {
			gradeId: null,
			genderId: null,
			placementTestId: null,
		},
		isFiltered: false,
		isActionsActive: false,
	}),

	computed: {
		...mapState({
			grades: state => state.grades.grades,
			students: state => state.students.students,
			statistics: state => state.students.statistics,
			placementTests: state => state.placementTests.placementTests,
			isAdmin: state => state.auth.isAdmin
		}),

		...mapGetters({
			getGradeById: 'grades/getGradeById',
			getStudentById: 'students/getStudentById',
		}),
	},

	methods: {
		downloadFile(isPrint) {
			const actionName = isPrint ? 'placementTest' : 'exportMarksTemplate';

			if (isPrint) this.printLoading = true;
			else this.exportLoading = true;

			this.$store.dispatch(`studentsReports/${actionName}`, {
				userId: JSON.parse(localStorage.getItem('auth')).id,
				centerId: JSON.parse(localStorage.getItem('auth')).centerId,
				placementTestId: this.filter.placementTestId, 
			}).then(() => {
				this.$eventBus.$emit('show-snackbar', false, 'سيبدأ التنزيل خلال ثوانٍ ...');
			}).finally(() => {
				if (isPrint) this.printLoading = false;
				else this.exportLoading = false;
			})
		},

		filterData(isFilter, isFirstLoad) {
			this.loading = true;
			this.isFiltered = isFilter;
			this.$eventBus.$emit('disable-refresh');

			return this.$store.dispatch('students/fetchAllForTestList', {
				placementTestId: isFilter ? this.filter.placementTestId : null,
				withoutConditionalPassedStudents: true
			}).finally(() => {
				if (!isFirstLoad) {
					this.loading = false;
				}
				this.$eventBus.$emit('enable-refresh');
			})
		},

		moment,
	},

	created() {
		this.loading = true;
		Promise.all([
			this.grades.length === 0 ? this.$store.dispatch('grades/fetchAll') : null,
		]).finally(() => {
			this.loading = false;
		})

		this.$eventBus.$on('refresh', () => { this.filterData(this.isFiltered) });
	},

	mounted() {
		this.$eventBus.$emit('disable-refresh');
		this.$store.dispatch('students/reset');
	},

	metaInfo: {
		title: 'تحضير السبر',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style>
#create .v-speed-dial {
	position: absolute;
}

#create .v-btn--floating {
	position: relative;
}
</style>