<template>
	<div>
		<!-- filter -->
		<v-form class="filter" @submit.prevent="options.page = 1; filterData(true)">
			<v-row class="align-center ma-0 mt-n2">
				<!-- student no -->
				<v-col cols="12" sm="6" md="3" lg="1" class="pa-1">
					<v-text-field
						v-model="filter.studentNo"
						label="الرقم"
						outlined
						hide-details
						dense
						class="rounded-medium grey--text"
					></v-text-field>
				</v-col>

				<!-- student name -->
				<v-col cols="12" sm="6" md="3" lg="2" class="pa-1">
					<v-text-field 
						v-model="filter.studentName"
						hide-details
						class="grey--text rounded-medium grey--text"
						label="الاسم"
						dense
						outlined
					></v-text-field>
				</v-col>

				<!-- grade level -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.gradeLevelId"
						:items="gradeLevel"
						item-text="name"
						item-value="id"
						label="المرحلة"
						outlined
						hide-details
						dense
						auto-select-first
						append-icon
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- grade -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.gradeId"
						:items="grades"
						item-text="name"
						item-value="id"
						label="الصف"
						outlined
						hide-details
						dense
						auto-select-first
						append-icon
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- gender -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.genderId"
						:items="genders"
						item-text="name"
						item-value="id"
						label="الجنس"
						auto-select-first
						outlined
						hide-details
						dense
						append-icon
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- previous school -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-combobox
						v-model="filter.previousSchool"
						:items="previousSchools"
						item-text="name"
						item-value="id"
						label="المدرسة"
						auto-select-first
						outlined
						hide-details
						dense
						append-icon
						class="rounded-medium grey--text px"
					></v-combobox>
				</v-col>

				<!-- dates -->
				<v-col v-if="isAdmin" cols="12" sm="6" md="3" class="d-flex align-center pa-1">
					<!-- from date -->
					<v-menu
						ref="fromDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="filter.fromDate"
								outlined
								hide-details
								dense
								class="rounded-medium me-2"
								prepend-inner-icon="mdi-calendar"
								readonly
								clearable
								v-bind="attrs"
								v-on="on"
								label="من تاريخ"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="filter.fromDate"
							prev-month-aria-label='$vuetify.datePicker.prevMonthAriaLabel'
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1950-01-01"
							@change="$refs.fromDateMenu.save($event)"
						></v-date-picker>
					</v-menu>

					<!-- to date -->
					<v-menu
						ref="toDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template  v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="filter.toDate"
								hide-details
								outlined
								dense
								class="rounded-medium"
								prepend-inner-icon="mdi-calendar"
								readonly
								clearable
								v-bind="attrs"
								v-on="on"
								label="إلى تاريخ"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="filter.toDate"
							prev-month-aria-label='$vuetify.datePicker.prevMonthAriaLabel'
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1950-01-01"
							@change="$refs.toDateMenu.save($event)"
						></v-date-picker>
					</v-menu>
				</v-col>

				<!-- placement test -->
				<v-col v-if="isAdmin" cols="12" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.placementTestId"
						:items="placementTests"
						item-value="id"
						:item-text="(item) => getStudentPlacementTestFull(item)"
						hide-details
						outlined
						dense
						class="rounded-medium"
						append-icon
						label="الامتحان"
					></v-autocomplete>
				</v-col>

				<v-spacer/>

				<!-- search button -->
				<v-col cols="auto" class="pa-1">
					<v-btn
						type="submit"
						color="primary"
						class="rounded-xl px-5"
						key="search-btn"
						:disabled="loading"
					>بحث</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			:ids="deletePaymentId"
			moduleType="payments"
			@on-delete="fetchStudentPayments"
		>
			هل أنت متأكد من <span class="red--text text--lighten-1">حذف</span> الدفعة؟
		</bee-delete-dialog>

		<!-- pay dialog -->
		<v-dialog v-model="payDialog" content-class="rounded-xl" width="700" persistent>
			<v-form ref="payForm" @submit.prevent="savePay">
				<v-card class="payment-card py-4 px-4">
					<v-card-title>
						<v-icon color="lightGreen" class="mx-2" > mdi-wallet  </v-icon>
						<h5 class="lightBlue--text">
							الدفعات {{isRefund ? '(استرداد)' : null}}
							
							<v-progress-circular
								v-if="isRefund && paymentsLoading"
								indeterminate
								size="16"
								width="2"
								color="lightBlue lighten-1"
								class="ms-2"
							/>
						</h5>
					</v-card-title>
					<v-card-text class="pb-3">
						<!-- register -->
						<v-row v-if="!isRefund" class="align-center">
							<v-col sm="2" cols="12" class="py-sm-0 py-2">
								<span class="primary--text">تسجيل</span>
							</v-col>
							
							<!-- amount -->
							<v-col sm="4" cols="12" class="py-1 px-2">
								<v-text-field
									v-model.number="registerPay.amount"
									prefix="ل.س"
									type="number"
									hide-spin-buttons
									min="0"
									:disabled="registerPay.isPaid || (rowStudentData.exemption ? !rowStudentData.exemption.unStrictPayment : true)"
									dense
									outlined
									hide-details
									class="rounded-xl"
									:readonly="submitLoading || !canPay(rowStudentData)"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>
				
							<!-- receiptNo -->
							<v-col sm="3" cols="6" class="py-1 px-2">
								<v-text-field
									v-model.number="registerPay.receiptNo"
									label="رقم الإيصال"
									type="number"
									hide-spin-buttons
									min="0"
									outlined
									dense
									hide-details
									class="rounded-xl"
									:readonly="submitLoading || !canPay(rowStudentData)"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>
				
							<!-- noteNo -->
							<v-col sm="3" cols="6" class="py-1 px-2">
								<v-text-field
									v-model="registerPay.noteNo"
									label="رقم الدفتر"
									outlined
									dense
									class="rounded-xl"
									:readonly="submitLoading || !canPay(rowStudentData)"
									:rules="rules.required"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>

						<!-- complete & more payments & transportation -->
						<template v-if="isComplete || (!isStudentHasPlacementTest && isRegister)">
							<!-- complete -->
							<v-row class="align-center">
								<v-col sm="2" cols="12" class="py-sm-0 py-2">
									<span class="primary--text">استكمال</span>
								</v-col>
								
								<!-- amount -->
								<v-col sm="4" cols="12" class="py-1 px-2">
									<v-text-field
										v-model.number="completePay.amount"
										prefix="ل.س"
										type="number"
										hide-spin-buttons
										min="0"
										dense
										outlined
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										:disabled="!isAllowToEditCompletePayment()"
										:loading="paymentsLoading"
										:rules="rules.requiredAsNumber"
										@input="completePay.isChanged = true"
									></v-text-field>
								</v-col>
					
								<!-- receiptNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<v-text-field
										v-model.number="completePay.receiptNo"
										label="رقم الإيصال"
										type="number"
										hide-spin-buttons
										min="0"
										outlined
										dense
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="rules.requiredAsNumber"
										@input="completePay.isChanged = true"
									></v-text-field>
								</v-col>
					
								<!-- noteNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<v-text-field
										v-model="completePay.noteNo"
										label="رقم الدفتر"
										outlined
										dense
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="rules.required"
										hide-details
										@input="completePay.isChanged = true"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- more payments -->
							<v-row
								v-for="(payment, index) in morePayments"
								:key="index"
								class="align-center"
							>
								<v-col sm="2" cols="12" class="py-sm-0 py-2">
									<span class="primary--text">دفعة أخرى</span>
								</v-col>
								
								<!-- amount -->
								<v-col sm="3" cols="12" class="py-1 px-2">
									<v-text-field
										v-model.number="morePayments[index].amount"
										prefix="ل.س"
										type="number"
										hide-spin-buttons
										min="0"
										dense
										outlined
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="rules.requiredAsNumber"
										@input="morePayments[index].isChanged = true"
									></v-text-field>
								</v-col>
					
								<!-- receiptNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<v-text-field
										v-model.number="morePayments[index].receiptNo"
										label="رقم الإيصال"
										type="number"
										hide-spin-buttons
										min="0"
										outlined
										dense
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="rules.requiredAsNumber"
										@input="morePayments[index].isChanged = true"
									></v-text-field>
								</v-col>
					
								<!-- noteNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<v-text-field
										v-model="morePayments[index].noteNo"
										label="رقم الدفتر"
										outlined
										dense
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="rules.required"
										hide-details
										@input="morePayments[index].isChanged = true"
									></v-text-field>
								</v-col>

								<!-- delete -->
								<v-col v-if="!payment.isDue" sm="1" cols="auto" class="py-1 px-2">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="error lighten-1"
												depressed
												height="30"
												width="30"
												min-width="30"
												class="rounded-medium white--text"
												v-on="on"
												v-bind="attrs"
												:loading="deleteLoading"
												:disabled="submitLoading || deleteLoading"
												@click="
													deleteDialog = true;
													deletePaymentId = payment.id;
												"
											>
												<v-icon size="20">
													mdi-delete
												</v-icon>
											</v-btn>
										</template>
										حذف الدفعة
									</v-tooltip>
								</v-col>
							</v-row>

							<!-- transportation -->
							<v-row class="align-center" v-if="transportPay.show">
								<v-col sm="2" cols="12" class="py-sm-0 py-2">
									<span class="primary--text">مواصلات</span>
								</v-col>
								
								<!-- amount -->
								<v-col :sm="transportPay.isPaid ? 3 : 4" cols="12" class="py-1 px-2">
									<v-text-field
										v-model.number="transportPay.amount"
										prefix="ل.س"
										type="number"
										hide-spin-buttons
										min="0"
										dense
										outlined
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										disabled
										:loading="paymentsLoading"
									></v-text-field>
									<!-- :rules="rules.requiredAsNumber" -->
								</v-col>
					
								<!-- receiptNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<!-- FIXME remove :rules="[] || rules.requiredAsNumber"  -->
									<v-text-field
										v-model.number="transportPay.receiptNo"
										label="رقم الإيصال"
										type="number"
										hide-spin-buttons
										min="0"
										outlined
										dense
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="[] || rules.requiredAsNumber"
										@input="transportPay.isChanged = true"
									></v-text-field>
								</v-col>
					
								<!-- noteNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<!-- FIXME remove :rules="[] || rules.required"  -->
									<v-text-field
										v-model="transportPay.noteNo"
										label="رقم الدفتر"
										outlined
										dense
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="[] || rules.required"
										hide-details
										@input="transportPay.isChanged = true"
									></v-text-field>
								</v-col>
								
								<!-- delete -->
								<v-col v-if="transportPay.isPaid" sm="1" cols="auto" class="py-1 px-2">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="error lighten-1"
												depressed
												height="30"
												width="30"
												min-width="30"
												class="rounded-medium white--text"
												v-on="on"
												v-bind="attrs"
												:loading="deleteLoading"
												:disabled="submitLoading || deleteLoading"
												@click="
													deleteDialog = true;
													deletePaymentId = transportPay.id;
												"
											>
												<v-icon size="20">
													mdi-delete
												</v-icon>
											</v-btn>
										</template>
										حذف الدفعة
									</v-tooltip>
								</v-col>
							</v-row>
						</template>

						<!-- refund -->
						<template v-if="isRefund">
							<v-row
								v-for="(refundPay, index) in refundPayments"
								:key="index"
								class="align-center"
							>
								<v-col sm="2" cols="12" class="py-sm-0 py-2">
									<span class="primary--text">
										مبلغ ر. {{index + 1}}
									</span>
								</v-col>
								
								<!-- amount -->
								<v-col sm="4" cols="12" class="py-1 px-2">
									<v-text-field
										v-model.number="refundPayments[index].amount"
										prefix="ل.س"
										type="number"
										hide-spin-buttons
										min="0"
										dense
										outlined
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										disabled
										:loading="paymentsLoading"
										:rules="rules.requiredAsNumber"
									></v-text-field>
								</v-col>
					
								<!-- receiptNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<v-text-field
										v-model.number="refundPayments[index].receiptNo"
										label="رقم الإيصال"
										type="number"
										hide-spin-buttons
										min="0"
										outlined
										dense
										hide-details
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="rules.requiredAsNumber"
										@input="refundPayments[index].isChanged = true"
									></v-text-field>
								</v-col>
					
								<!-- noteNo -->
								<v-col sm="3" cols="6" class="py-1 px-2">
									<v-text-field
										v-model="refundPayments[index].noteNo"
										label="رقم الدفتر"
										outlined
										dense
										class="rounded-xl"
										:readonly="submitLoading"
										:loading="paymentsLoading"
										:rules="rules.required"
										hide-details
										@input="refundPayments[index].isChanged = true"
									></v-text-field>
								</v-col>

								<!-- has receipt been received -->
								<v-col sm="4" cols="6" class="py-1 px-2">
									<v-checkbox
										v-model="refundPayments[index].hasReceiptBeenReceived"
										label="تم استلام الإيصال"
										dense
										:readonly="submitLoading"
										:loading="paymentsLoading"
										hide-details
										class="mt-0"
										@change="refundPayments[index].isChanged = true"
									></v-checkbox>
								</v-col>
							</v-row>
						</template>

						<!-- add pay -->
						<div v-if="isComplete || (!isStudentHasPlacementTest && isRegister)" class="d-flex align-center pt-4 pb-1">
							<span class="primary--text">دفعة</span>

							<v-btn
								v-if="!isAddPay"
								color="warning"
								depressed
								height="22"
								width="22"
								min-width="22"
								class="px-0 rounded-medium white--text ms-2"
								@click="isAddPay = true"
								:disabled="paymentsLoading"
							>
								<v-icon size="18">
									mdi-plus
								</v-icon>
							</v-btn>
						</div>

						<!-- new pay -->
						<v-expand-transition>
							<v-form v-if="isAddPay" ref="newPayForm" @submit.prevent="saveNewPay">
								<v-row class="align-center ma-0">
									<!-- amount -->
									<v-col sm="4" cols="12" class="py-1 px-2">
										<v-text-field
											v-model.number="newPay.amount"
											prefix="ل.س"
											type="number"
											hide-spin-buttons
											min="0"
											dense
											outlined
											hide-details
											class="rounded-xl"
											:readonly="newPayLoading"
											:loading="paymentsLoading"
											:rules="rules.requiredAsNumber"
										></v-text-field>
									</v-col>
								
									<!-- receiptNo -->
									<v-col sm="3" cols="6" class="py-1 px-2">
										<v-text-field
											v-model.number="newPay.receiptNo"
											label="رقم الإيصال"
											type="number"
											hide-spin-buttons
											min="0"
											outlined
											dense
											hide-details
											class="rounded-xl"
											:readonly="newPayLoading"
											:loading="paymentsLoading"
											:rules="rules.requiredAsNumber"
										></v-text-field>
									</v-col>
								
									<!-- noteNo -->
									<v-col sm="3" cols="6" class="py-1 px-2">
										<v-text-field
											v-model="newPay.noteNo"
											label="رقم الدفتر"
											outlined
											dense
											class="rounded-xl"
											:readonly="newPayLoading"
											:loading="paymentsLoading"
											:rules="rules.required"
											hide-details
										></v-text-field>
									</v-col>
								
									<!-- actions -->
									<v-col sm="2" cols="12" class="d-flex justify-space-between py-sm-0 py-2">
										<!-- save -->
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													color="lightGreen"
													depressed
													height="30"
													width="30"
													min-width="30"
													class="rounded-medium white--text"
													v-on="on"
													v-bind="attrs"
													type="submit"
													:loading="newPayLoading"
													:disabled="newPayLoading"
												>
													<v-icon size="22">
														mdi-check
													</v-icon>
												</v-btn>
											</template>
											حفظ الدفعة
										</v-tooltip>

										<!-- cancel -->
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													color="error lighten-1"
													depressed
													height="30"
													width="30"
													min-width="30"
													class="rounded-medium white--text"
													v-on="on"
													v-bind="attrs"
													:disabled="newPayLoading"
												>
													<v-icon
														@click="isAddPay = false"
														size="22"
													>
														mdi-close
													</v-icon>
												</v-btn>
											</template>
											إلغاء
										</v-tooltip>
									</v-col>
								</v-row>
							</v-form>
						</v-expand-transition>

						<!-- notes -->
						<v-row class="mt-1">
							<v-col cols="12" class="px-2">
								<v-textarea
									v-model="registerPay.notes"
									label="ملاحظات"
									dense
									outlined
									hide-details
									rows="1"
									no-resize
									auto-grow
									:readonly="submitLoading || !canPay(rowStudentData)"
									class="rounded-xl"
								></v-textarea>
							</v-col>
						</v-row>

						<div v-if="!isRegister" class="d-flex justify-center align-center mt-3 px-2 black--text">
							<span class="text--darken-2 me-3">المجموع:</span>
							<span class="darkRed--text text-body-1 font-weight-bold me-1">
								{{totalStudentPayments}}
							</span>
							ل.س
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
				
						<v-btn
							v-if="canPay(rowStudentData)"
							type="submit"
							color="lightGreen white--text"
							class="rounded-xl px-4"
							:loading="submitLoading"
							:disabled="submitLoading || isNotAllowToPay(rowStudentData) || isAddPay || paymentsLoading"
						>
							{{rowStudentData.hasPaid ? 'حفظ' : 'دفع'}}
						</v-btn>
				
						<v-btn
							v-if="
								rowStudentData.hasPaid &&
								isRegister &&
								isStudentHasPlacementTest &&
								!rowStudentData.isNokhbaStudent
							"
							color="primary white--text"
							class="rounded-xl px-4"
							:loading="registerCardLoading"
							:disabled="submitLoading || registerCardLoading"
							@click="getRegisterCard(rowStudentData)"
						>
							إصدار البطاقة
						</v-btn>
				
						<v-btn
							text
							class="rounded-xl px-5"
							:disabled="submitLoading"
							@click="payDialog = false"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- tabs -->
		<v-tabs
			v-model="activeTab"
			centered
			grow
			height="35"
			class="rounded-medium elevation-2 mb-1"
			style="overflow: hidden"
			show-arrows
			center-active
			@change="filterData(isFiltered)"
		>
			<v-tab
				v-for="tab in tabs"
				:key="tab.id"
				:disabled="loading"
			>
				{{tab.name}}
			</v-tab>
		</v-tabs>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : students"
			:loading="loading"
			fixed-header
			dense
			:disable-sort="loading || !students.length"
			:height="$vuetify.breakpoint.height - 262"
			:items-per-page="25"
			:footer-props="{ 'items-per-page-options': [25, 50, 100] }"
			:options.sync="options"
			:server-items-length="totalItems"
			class="elevation-2 rounded-medium pa-1"
		>
			<!-- no -->
			<template v-slot:item.no="{ index }">
				{{index + 1}}
			</template>

			<!-- name -->
			<template v-slot:item.firstName="{ item }">
				{{`${item.firstName} ${item.lastName ? item.lastName : '' }`}}
			</template>

			<!-- grade -->
			<template v-slot:item.gradeId="{ item }">
				{{getGradeById(item.gradeId) ? getGradeById(item.gradeId).name : '-'}}
			</template>

			<!-- region -->
			<template v-slot:item.regionId="{ item }">
				{{getRegionById(item.regionId) ? getRegionById(item.regionId).name : '-'}}
			</template>

			<!-- region -->
			<template v-slot:item.mobileNumber="{ item }">
				{{ item.mobileNumber | isAvailable }}
			</template>

			<!-- ninth grade total -->
			<template v-slot:item.ninthGradeTotal="{ item }">
				{{ item.ninthGradeTotal | isAvailable }}
			</template>

			<!-- actions -->
            <template v-slot:item.actions="{ item }">
				<div class="d-flex align-center" style="padding: 2px 0">
					<template v-if="activeTab === 0">
						<v-btn
							v-if="item.hasPaid"
							color="lightBlue"
							height="32"
							dark
							class="me-1 rounded-medium"
							@click="rowStudentData = item; payDialog = true"
						>
							تم الدفع
						</v-btn>
						<v-btn
							v-else-if="canPay(item)"
							color="lightGreen"
							height="32"
							dark
							class="me-1 rounded-medium"
							@click="rowStudentData = item; payDialog = true"
						>
							دفع
						</v-btn>
					</template>

					<v-btn
						v-else
						:color="
							(isComplete && item.hasPaidPrimaryPayment) ||
							(isRefund && item.hasPaidWithdrawPayment)
								? 'lightBlue' :
								'lightGreen'
						"
						dark
						class="me-1 rounded-medium"
						@click="rowStudentData = item; payDialog = true"
					>
						{{
							isComplete
							? (!item.hasPaidPrimaryPayment ? 'استكمال' : 'تم الاستكمال')
							: (!item.hasPaidWithdrawPayment ? 'استرداد' : 'تم الاسترداد')
						}}
					</v-btn>

					<v-tooltip top>
						<template v-slot:activator="{ attrs, on }">
							<v-icon
								v-if="item.isNokhbaStudent"
								color="primary"
								size="24"
								v-bind="attrs"
								v-on="on"
							>
								mdi-star-circle
							</v-icon>
						</template>
						طالب نخبة
					</v-tooltip>

					<v-tooltip top>
						<template v-slot:activator="{ attrs, on }">
							<v-icon
								v-if="item.hasExemption"
								color="deepOrange"
								size="25"
								class="ms-1"
								v-bind="attrs"
								v-on="on"
							>
								mdi-alert-octagon-outline
							</v-icon>
						</template>
						لديه استثناء
					</v-tooltip>
				</div>
            </template>

			<template v-slot:footer.page-text="items"> 
				<span>{{ items.pageStart }} - {{ items.pageStop }} من {{ items.itemsLength }}</span>
			</template>
        </v-data-table>

		<!-- totals -->
		<v-card class="mt-1 justify-end rounded-medium">
			<v-card-text class="d-flex flex-wrap justify-md-start justify-center black--text d-flex px-2 py-1">
				<!-- register -->
				<template v-if="isRegister">
						دفعات التسجيل: 
						<span class="text-body-1 font-weight-medium darkRed--text mx-1">
							{{formatNumber(paymentStatistics.registrationPayments)}}
							<span class="black--text">/</span>
							{{formatNumber(paymentStatistics.paidRegistrationPayments)}}
						</span>
				</template>

				<!-- complete -->
				<template v-if="isComplete">
					<div class="me-md-6">
						دفعات الاستكمال: 
						<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
							{{formatNumber(paymentStatistics.primaryPayments)}}
							<span class="black--text">/</span>
							{{formatNumber(paymentStatistics.paidPrimaryPayments)}}
						</span>
					</div>

					<div>
						دفعات المواصلات: 
						<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
							{{formatNumber(paymentStatistics.transportationPayments)}}
							<span class="black--text">/</span>
							{{formatNumber(paymentStatistics.paidTransportationPayments)}}
						</span>
					</div>
				</template>

				<!-- refund -->
				<template v-if="isRefund">
					دفعات الاسترداد: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						{{formatNumber(paymentStatistics.withDrawPayments)}}
						<span class="black--text">/</span>
						{{formatNumber(paymentStatistics.paidWithDrawPayments)}}
					</span>
				</template>
			</v-card-text>
		</v-card>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { genders, gradeLevel, paymentTypes, studentsDataStatus } from '@/helpers/enums'
import moment from 'moment/moment'
import rules from '@/helpers/validation rules'
import { BASE_API_URL } from '@/constants'
import BeeDeleteDialog from '@/components/BeeDeleteDialog.vue'
import { getFullDate, getSortData } from '@/helpers/helpers'
export default {
	components: { BeeDeleteDialog },
	name: 'Accounting',
	
	data: (vm) => ({
		// helpers
		gradeLevel,
		genders,
		rules,

		payDialog: false,
		isAddPay: false,
		paymentsLoading: false,
		newPayLoading: false,

		rowStudentData: {},

		loading: false,
		submitLoading: false,
		registerCardLoading: false,

		deleteLoading: false,
		deletePaymentId: null,
		deleteDialog: false,

		// pagination
		options: {},
		totalItems: null,

		// filter
		filter: {
			studentNo: null,
			studentName: null,
			gradeLevelId: null,
			gradeId: null,
			genderId: null,
			previousSchool: null,
			fromDate: null,
			toDate: null,
			placementTestId: null,
		},
		paymentStatistics: {},
		isFiltered: false,
		
		// tabs
		activeTab: 0,
		tabs: [
			{ id: 1, name: 'التسجيل' },
			{ id: 2, name: 'استكمال' },
			{ id: 3, name: 'مستكملين' },
			{ id: 4, name: 'استرداد' },
			{ id: 5, name: 'منسحبين' },
		],

		/* DATA */
		// pay dialog
		registerPay: {
			amount: null,
			receiptNo: null,
			noteNo: null,
			notes: null,
			isPaid: false,
		},
		completePay: {
			id: null,
			amount: null,
			receiptNo: null,
			noteNo: null,
			isPaid: false,
		},
		transportPay: {
			id: null,
			amount: null,
			receiptNo: null,
			noteNo: null,
			isPaid: false,
			show: false
		},
		refundPay: {
			id: null,
			amount: null,
			receiptNo: null,
			noteNo: null,
			isPaid: false,
			hasReceiptBeenReceived: false,
		},
		refundPayments: [],

		newPay: {
			amount: null,
			receiptNo: null,
			noteNo: null,
			isPaid: false,
		},
		morePayments: []
	}),

	watch: {
		payDialog(val) {
			if (val) {
				this.registerPay.amount =
					this.getGradeById(this.rowStudentData.gradeId).payments.registrationPayment;
				const fillRegistrationPaymentData = () => {
					this.registerPay.amount = this.rowStudentData.registrationPayment.amount;
					this.registerPay.noteNo = this.rowStudentData.registrationPayment.noteNo;
					this.registerPay.receiptNo = this.rowStudentData.registrationPayment.receiptNo;
					this.registerPay.notes = this.rowStudentData.registrationPayment.note;
				}
				if (this.rowStudentData.registrationPayment && this.isStudentHasPlacementTest) {
					fillRegistrationPaymentData();

					if (!this.isRegister) {
						this.fetchStudentPayments();
					}
				} else if (
					// this state only for Nokhba Students and students' grade which don't have placement test
					this.rowStudentData.isNokhbaStudent ||
					!this.isStudentHasPlacementTest
				) {
					if (this.rowStudentData.hasPaid) {
						fillRegistrationPaymentData();
					}
					this.fetchStudentPayments();
				}
			} else {
				this.rowStudentData = {};
				this.isAddPay = false;
				this.resetPaymentsFields();
				this.$refs.payForm.resetValidation();
			}
		},

		options(val, oldVal) {
			if (oldVal.page) this.filterData(this.isFiltered);
		}
	},

	computed: {
		...mapState({
			grades: state => state.grades.grades,
			regions: state => state.regions.regions,
			students: state => state.students.students,
			previousSchools: state => state.students.previousSchools,
			currentCenterId: state => state.centers.currentCenterId,
			placementTests: state => state.placementTests.placementTests,
			activePlacementTests: state => state.placementTests.activePlacementTests,
			isAdmin: state => state.auth.isAdmin
		}),

		...mapGetters({
			getGradeById: 'grades/getGradeById',
			getRegionById: 'regions/getRegionById',
			getStudentById: 'students/getStudentById',
			getCenterById: 'centers/getCenterById',
			getPlacementTestById: 'placementTests/getPlacementTestById',
		}),

		headers() {
			return [
				{ text: '#', value: 'no', align: 'center', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, width: 30 },
				{ text: 'الرقم', value: 'studentNo', divider: true, class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ text: 'الاسم', value: 'firstName', class: 'px-1', cellClass: 'text-body-2 px-1' },
				{ text: 'الأب', value: 'fatherName', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ text: 'الأم', value: 'motherName', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ 
					text: 'الصف',
					value: 'gradeId',
					width: 80,
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sort: (a, b) => {
						const name1 = this.getGradeById(a).name;
						const name2 = this.getGradeById(b).name;
						return name1.localeCompare(name2);
					}
				},
				{ text: 'هـ. الطالب', value: 'mobileNumber', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ text: 'هـ. ولي الأمر', value: 'parentsMobileNumber', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{
					text: 'المنطقة',
					value: 'regionId',
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sort: (a, b) => {
						const name1 = this.getRegionById(a).name;
						const name2 = this.getRegionById(b).name;
						return name1.localeCompare(name2);
					}
				},
				{ text: 'المدرسة السابقة', value: 'previousSchool', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ text: 'م. التاسع', value: 'ninthGradeTotal', class: 'px-1', cellClass: 'text-body-2 px-1' },
				{ text: '', value: 'actions', sortable: false, class: 'px-1', cellClass: 'text-body-2 px-1', width: 150 },
			]
		},

		totalStudentPayments() {
			return (
				(!this.isRefund ? this.registerPay.amount : 0) +
				(this.completePay.receiptNo ? (this.completePay.amount || 0) : 0) +
				(this.transportPay.receiptNo ? (this.transportPay.amount || 0) : 0) +
				(this.refundPay.receiptNo ? (this.refundPay.amount || 0) : 0) +
				(
					this.morePayments.length
						? this.morePayments.map(c => c.amount).reduce((a, b) => (a || 0) + (b || 0))
						: 0
				) +
				(
					this.refundPayments.length
						? this.refundPayments.map(c => c.amount).reduce((a, b) => (a || 0) + (b || 0))
						: 0
				)
			).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
		},

		isRegister() { return this.activeTab === 0 },
		isComplete() { return [1, 2].includes(this.activeTab) },
		isRefund() { return [3, 4].includes(this.activeTab) },

		isStudentHasPlacementTest() {
			return this.getGradeById(this.rowStudentData.gradeId)?.hasPlacementTest;
		}
	},
	
	methods: {
		// filter
		filterData(isFilter, isFirstLoad) {
			this.loading = true;
			this.isFiltered = isFilter;
			this.$eventBus.$emit('disable-refresh');

			const { page, itemsPerPage, sortBy, sortDesc } = this.options;

			const { name: byName, grade: byGrade, ninthGrade: byNinthGrade } =
				getSortData({ sortBy, sortDesc }, {
					name: 'firstName',
					grade: 'gradeId',
					ninthGrade: 'ninthGradeTotal',
					marks: 'marks',
					state: 'state',
				});

			let studentState = null;

			switch (this.activeTab) {
			case 1: studentState = studentsDataStatus.notCompleted; break;
			case 2: studentState = studentsDataStatus.completed; break;
			case 3: studentState = studentsDataStatus.withdrawn; break;
			case 4: studentState = studentsDataStatus.withdrawnAndRecovered; break;
			}
			const isNeedPreventDatesFilters =
				(this.filter.studentName || this.filter.studentNo || this.filter.placementTestId) &&
				!this.isAdmin;
			const actionName = !this.isComplete ? 'fetchAll' : 'fetchAllRegistered'
			return Promise.all([
				this.$store.dispatch(`students/${actionName}`, {
					name: isFilter ? this.filter.studentName : null,
					studentNo: isFilter ? this.filter.studentNo : null,
					gradeLevel: isFilter ? this.filter.gradeLevelId : null,
					gradeId: isFilter ? this.filter.gradeId : null,
					gender: isFilter ? this.filter.genderId : null,
					previousSchool: isFilter ? this.filter.previousSchool : null,
					startDate: isFilter && !isNeedPreventDatesFilters ? this.filter.fromDate : null,
					endDate: isFilter && !isNeedPreventDatesFilters ? this.filter.toDate : null,
					placementTestId: isFilter ? this.filter.placementTestId : null,
					studentStatus: studentState,
					onlyCompletedStudents: this.activeTab === 1,
					page: page || 1,
					perPage: itemsPerPage || 25,
					orderByName: byName,
					orderByGrade: byGrade,
					orderByNinthGradeTotal: byNinthGrade,
					orderByMarks: null,
				}).then((data) => {
					this.totalItems = data.totalCount;
				}),
				this.$store.dispatch('payments/fetchStatistics', {
					name: isFilter ? this.filter.studentName : null,
					studentNo: isFilter ? this.filter.studentNo : null,
					gradeLevel: isFilter ? this.filter.gradeLevelId : null,
					gradeId: isFilter ? this.filter.gradeId : null,
					gender: isFilter ? this.filter.genderId : null,
					startDate: isFilter && !isNeedPreventDatesFilters ? this.filter.fromDate : null,
					endDate: isFilter && !isNeedPreventDatesFilters ? this.filter.toDate : null,
					placementTestId: isFilter ? this.filter.placementTestId : null,
				}).then(data => {
					this.paymentStatistics = data;
				})
			]).finally(() => {
				if (!isFirstLoad) {
					this.loading = false;
				} 
				this.$eventBus.$emit('enable-refresh');
			})
		},

		// payment dialog
		fetchStudentPayments(morePaymentsOnly) {
			this.paymentsLoading = true;
			this.$store.dispatch('payments/fetchStudentPayments', {
				studentId: this.rowStudentData.id
			}).then((data) => {
				const registrationPay = data.find(c => c.paymentType === paymentTypes.registration);
				const completePay = data.find(c => c.paymentType === paymentTypes.primary);
				const transportPay = data.find(c => c.paymentType === paymentTypes.transportation);
				const refundPayments = data.filter(c => c.paymentType === paymentTypes.withDraw);
				const morePayments = data.filter(c => c.paymentType === paymentTypes.other);

				if (registrationPay) {
					this.registerPay.id = registrationPay.id;
					this.registerPay.isPaid = !registrationPay.isDue;
				}

				// FIXME sprit isStudentHasPlacementTest condition to new way
				if (this.isComplete || (this.isRegister && !this.isStudentHasPlacementTest)) {
					if (!morePaymentsOnly) {	
						if (completePay) {
							this.completePay.id = completePay.id;
							this.completePay.amount = completePay.amount;
							this.completePay.noteNo = completePay.noteNo;
							this.completePay.receiptNo = completePay.receiptNo;
							this.completePay.isPaid = !completePay.isDue;
						}

						if (!transportPay) {
							this.transportPay.show = false;
						} else {
							this.transportPay.id = transportPay.id;
							this.transportPay.amount = transportPay.amount;
							this.transportPay.noteNo = transportPay.noteNo;
							this.transportPay.receiptNo = transportPay.receiptNo;
							this.transportPay.isPaid = !transportPay.isDue;
							this.transportPay.show = true;
						}
					}
					
					this.morePayments = [];
					if (morePayments.length) {
						morePayments.forEach(payment => {
							this.morePayments.push({
								id: payment.id,
								amount: payment.amount, 
								receiptNo: payment.receiptNo,
								noteNo: payment.noteNo,
								isPaid: !payment.isDue
							})
						});
					}
				}
				
				if (this.isRefund) {
					refundPayments.forEach((payment) => {
						this.refundPayments.push({
							id: payment.id,
							amount: payment.amount,
							noteNo: payment.noteNo,
							receiptNo: payment.receiptNo,
							isPaid: !payment.isDue,
							hasReceiptBeenReceived: payment.hasReceiptBeenReceived,
						})
					})
				}
			}).finally(() => {
				this.paymentsLoading = false;
			})
		},
		
		saveNewPay() {
			if (this.$refs.newPayForm.validate()) {
				this.newPayLoading = true;
				this.$store.dispatch('payments/createNewPayment', {
					studentId: this.rowStudentData.id,
					amount: this.newPay.amount,
					noteNo: this.newPay.noteNo,
					receiptNo: this.newPay.receiptNo,
					note: null
				}).then(() => {
					this.isAddPay = false;
					this.fetchStudentPayments(true);
					this.newPay = {
						amount: null,
						receiptNo: null,
						noteNo: null,
						isPaid: false,
					};
					this.$eventBus.$emit('show-snackbar', false, 'تم إنشاء الدفعة');
				}).finally(() => {
					this.newPayLoading = false;
				})
			}
		},

		async savePay() {
			if (this.$refs.payForm.validate()) {
				this.submitLoading = true;
				const promises = [];

				const isNokhbaStudent = this.rowStudentData.isNokhbaStudent;
				
				// if student's grade has placement test save or update register payment
				if (this.rowStudentData.placementTestId) {
					const actionName = this.rowStudentData.hasPaid ? 'updateRegistrationPayment' : 'create';
					await this.$store.dispatch(`payments/${actionName}`, {
						id: this.rowStudentData.registrationPayment?.id ?? null,
						studentId: this.rowStudentData.id,
						amount: this.registerPay.amount,
						noteNo: this.registerPay.noteNo,
						receiptNo: this.registerPay.receiptNo,
						note: this.registerPay.notes,
					}).then(() => {
						if (actionName === 'create' && !isNokhbaStudent) {
							this.getRegisterCard(this.rowStudentData);
						}
						
						if (this.isRegister) {
							const message =
							actionName === 'updateRegistrationPayment'
								? 'تم تعديل بيانات الدفعة'
								: 'تم تسجيل الدفعة';
							this.$eventBus.$emit('show-snackbar', false, message);
						}
					});
				} else {
					// else the student has registration payment generated automatically so pay or update it
					const actionName = this.rowStudentData.hasPaid ? 'update' : 'pay';
					await this.$store.dispatch(`payments/${actionName}`, {
						id: this.registerPay.id,
						studentId: this.rowStudentData.id,
						amount: this.registerPay.amount,
						noteNo: this.registerPay.noteNo,
						receiptNo: this.registerPay.receiptNo,
						note: this.registerPay.notes,
					}).then(() => {
						if (this.isRegister) {
							const message =
							actionName === 'update'
								? 'تم تعديل بيانات الدفعة'
								: 'تم تسجيل الدفعة';
							this.$eventBus.$emit('show-snackbar', false, message);
						}
					});
				}

				// FIXME sprit isStudentHasPlacementTest condition to new way
				if (!this.isRegister || !this.isStudentHasPlacementTest) {
					const payPayment = (paymentData, useUpdate) => {
						const actionName = useUpdate ? 'update' : 'pay'
						promises.push(
							this.$store.dispatch(`payments/${actionName}`, {
								id: paymentData.id,
								studentId: this.rowStudentData.id,
								amount: paymentData.amount,
								noteNo: paymentData.noteNo,
								receiptNo: paymentData.receiptNo,
								note: null,
								hasReceiptReceived: paymentData.hasReceiptReceived || null
							})
						)
					}
					// FIXME sprit isStudentHasPlacementTest condition to new way
					if (this.isComplete || (!this.isStudentHasPlacementTest && !this.isRefund)) {
						if (this.completePay.isChanged) {
							payPayment({
								id: this.completePay.id,
								amount: this.completePay.amount,
								noteNo: this.completePay.noteNo,
								receiptNo: this.completePay.receiptNo
							}, this.completePay.isPaid)
						}
						
						if (this.transportPay.show && this.transportPay.isChanged) {
							payPayment({
								id: this.transportPay.id,
								amount: this.transportPay.amount,
								noteNo: this.transportPay.noteNo,
								receiptNo: this.transportPay.receiptNo
							}, this.transportPay.isPaid)
						}
						this.morePayments.forEach((payment) => {
							if (payment.isChanged) {
								payPayment({
									id: payment.id,
									amount: payment.amount,
									noteNo: payment.noteNo,
									receiptNo: payment.receiptNo
								}, payment.isPaid)
							}
						})
					} else if (this.isRefund) {
						this.refundPayments.forEach(payment => {
							if (payment.isChanged) {
								payPayment({
									id: payment.id,
									amount: payment.amount,
									noteNo: payment.noteNo,
									receiptNo: payment.receiptNo,
									hasReceiptReceived: payment.hasReceiptBeenReceived,
								}, payment.isPaid)
							}
						});
					}
				}

				if (promises.length) this.$eventBus.$emit('show-snackbar', false, 'يتم معالجة الدفعات...');

				Promise.all(promises)
					.then(() => {
						this.payDialog = false;
						this.$eventBus.$emit('show-snackbar', false, 'تم حفظ الدفعات');
						this.filterData(this.isFiltered);
					})
					.finally(() => {
						this.submitLoading = false;
					})
			}
		},

		getRegisterCard(studentData) {
			this.registerCardLoading = true;
			this.$store.dispatch('placementTests/fetchById', {
				id: studentData.placementTestId
			}).then((data) => {
				const center = this.getCenterById(this.currentCenterId);
				const routeData = this.$router.resolve({
					name: 'register-card',
					query: {
						centerName: center.name,
						centerLogo: BASE_API_URL + '/' + center.logo,
						name: `${studentData.firstName} ${studentData.lastName ? studentData.lastName : ''}`,
						no: studentData.studentNo,
						grade: this.getGradeById(studentData.gradeId).name,
						date: data.testDate,
						address: center.address,
						exams: data.exams.map(c => c.subject).join(' - '),
					}
				});
				window.open(routeData.href, 'popup', 'width=1000px, height=620px, top=0, left=0');		
			}).finally(() => {
				this.registerCardLoading = false;
			})
		},
		
		// helpers
		isNotAllowToPay(studentData) {
			const grade = this.getGradeById(studentData.gradeId);
			return (
				grade?.ninthGradeTotalRequired &&
				studentData.ninthGradeTotal < grade.minimumAverage &&
				!(studentData.exemption ? studentData.exemption.unStrictGradeTotal : false) &&
				!studentData.isNokhbaStudent &&
				!this.isRefund
			)
		},
		canPay(studentData) {
			const hasPlacementTest = this.getGradeById(studentData.gradeId)?.hasPlacementTest;
			if (!hasPlacementTest) {
				return true;
			}
			
			// const placementTestId = studentData.placementTestId;
			// return !this.isRegister || (this.getPlacementTestById(placementTestId)?.isActive ?? false)
			return !this.isRegister || hasPlacementTest
		},
		isAllowToEditCompletePayment() {
			if (this.rowStudentData.exemption) {
				return this.rowStudentData.exemption.unStrictCompletionPayment;
			} else {
				return false;
			}
		},

		resetPaymentsFields() {
			this.registerPay = {
				amount: 500000,
				receiptNo: null,
				noteNo: null,
				notes: null,
			};
			this.completePay = {
				id: null,
				amount: null,
				receiptNo: null,
				noteNo: null,
				isPaid: false,
			};
			this.transportPay = {
				id: null,
				amount: null,
				receiptNo: null,
				noteNo: null,
				isPaid: false,
				show: false
			}
			this.refundPay = {
				id: null,
				amount: null,
				receiptNo: null,
				noteNo: null,
				isPaid: false,
				hasReceiptBeenReceived: false
			};
			this.refundPayments = [];
			this.newPay = {
				amount: null,
				receiptNo: null,
				noteNo: null,
				isPaid: false,
			};
			this.morePayments = [];
		},

		getStudentPlacementTestFull(item) {
			const date = getFullDate(item.testDate);
			const grade = this.getGradeById(item.gradeId)?.name;
			const gender = genders.find(c => c.id === item.studentsGender).plural;

			return `${grade} - ${gender} - ${date}`
		},
		formatNumber(number) {
			if (number) {
				return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
			} else {
				return 0
			}
		},
		moment
	},

	created() {
		this.filter.fromDate = !this.isAdmin ? moment().format('YYYY-MM-DD') : null;
		this.filter.toDate = !this.isAdmin ? moment().format('YYYY-MM-DD') : null;

		this.loading = true;
		Promise.all([
			this.grades.length === 0 ? this.$store.dispatch('grades/fetchAll') : null,
			this.regions.length === 0 ? this.$store.dispatch('regions/fetchAll') : null,
			this.previousSchools.length === 0 ? this.$store.dispatch('students/fetchPreviousSchools') : null,
			this.filterData(!this.isAdmin, true),
		]).finally(() => {
			this.loading = false;
		})

		this.$eventBus.$on('refresh', () => { this.filterData(this.isFiltered) });
	},

	metaInfo: {
		title: 'المحاسبة',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}

</script>

<style lang="scss">
.payment-card {
	.v-text-field__prefix {
		font-size: 13px;
	}
}
</style>