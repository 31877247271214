<template>
	<div>
		<!-- dialog -->
		<v-dialog v-model="dialog" max-width="300" content-class="rounded-xl">
			<v-form ref="form" @submit.prevent="submit" :disabled="submitLoading">
				<v-card rounded="xl">
					<v-card-title class="lightBlue--text">
						تعديل الدفعات
						<span class="ms-1 text-body-1 lightGreen--text text--darken-2">
							({{dialogData.className}})
						</span>
					</v-card-title>
					<v-card-text class="pb-2 grey--text text--darken-3">
						<v-row>
							<!-- register pay -->
							<v-col cols="12">
								<v-text-field
									v-model.number="dialogData.registerPay"
									label="دفعة التسجيل"
									dense
									outlined
									hide-details
									class="rounded-medium"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>
							
							<!-- complete pay -->
							<v-col cols="12">
								<v-text-field
									v-model.number="dialogData.completePay"
									label="دفعة الاستكمال"
									dense
									outlined
									hide-details
									class="rounded-medium"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>
							
							<!-- transport pay -->
							<v-col cols="12">
								<v-text-field
									v-model.number="dialogData.transportPay"
									label="دفعة المواصلات"
									dense
									outlined
									hide-details
									class="rounded-medium"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							color="primary white--text"
							type="submit"
							class="rounded-xl px-5"
							:loading="submitLoading"
						>
							حفظ
						</v-btn>
						<v-btn
							class="rounded-xl px-5"
							@click="dialog = false"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- minimum average dialog -->
		<v-dialog
			v-model="minimumAverageDialog"
			max-width="340"
			content-class="rounded-xl"
			:persistent="submitMinimumAverageLoading"
		>
			<v-form ref="minimumAverageForm" @submit.prevent="submitMinimumGradeAverage">
				<v-card>
					<v-card-title class="lightBlue--text pb-4">
						تعديل الحد الأدنى
						<span class="ms-1 text-body-1 lightGreen--text text--darken-2">
							({{gradeId ? getGradeById(gradeId).name : null}})
						</span>
					</v-card-title>
					<v-card-text>
						<v-row dense align="center" class="ma-0">
							<!-- minimum average -->
							<v-col cols="7">
								<v-text-field
									v-model.number="minimumAverage"
									label="الحد الأدنى"
									type="number"
									hide-spin-buttons
									dense
									outlined
									hide-details
									class="rounded-medium"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>

							<!-- has minimum average -->
							<v-col cols="5">
								<v-switch
									v-model="hasMinimumAverage"
									:label="hasMinimumAverage ? 'فعال' : 'غير فعال'"
									inset
									dense
									hide-details
									class="ma-0"
								></v-switch>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							type="submit"
							class="rounded-medium"
							color="lightGreen white--text"
							:loading="submitMinimumAverageLoading"
						>حفظ</v-btn>
						<v-btn
							text
							class="rounded-medium"
							:disabled="submitMinimumAverageLoading"
							@click="minimumAverageDialog = false"
						>إلغاء</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- placement test dialog -->
		<v-dialog
			v-model="confirmPlacementTestDialog"
			max-width="360"
			content-class="rounded-xl"
			:persistent="updatePlacementTestStateLoading"
		>
			<v-form ref="minimumAverageForm" @submit.prevent="updateGradePlacementTest">
				<v-card>
					<v-card-title class="lightBlue--text pb-4">
						تعديل وجود سبر
						<span class="ms-1 text-body-1 lightGreen--text text--darken-2">
							({{gradeId ? getGradeById(gradeId).name : null}})
						</span>
					</v-card-title>
					<v-card-text>
						هل أنت متأكد من 
						{{gradeId && getGradeById(gradeId).hasPlacementTest ? 'إلغاء' : 'تفعيل'}} 
						وجود سبر للصف {{gradeId ? getGradeById(gradeId).name : null}}؟
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							type="submit"
							class="rounded-medium"
							color="lightGreen white--text"
							:loading="updatePlacementTestStateLoading"
						>تأكيد</v-btn>
						<v-btn
							text
							class="rounded-medium"
							:disabled="updatePlacementTestStateLoading"
							@click="confirmPlacementTestDialog = false"
						>إلغاء</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- data -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : grades"
			:loading="loading"
			:items-per-page="-1"
			dense
			disable-sort
			hide-default-footer
			class="elevation-2 rounded-medium pa-1"
		>
			<!-- register pay -->
			<template v-slot:item.no="{ index }">
				{{index + 1}}
			</template>

			<!-- register pay -->
			<template v-slot:item.registerPay="{ item }">
				{{item.payments.registrationPayment.toLocaleString()}}
			</template>
			
			<!-- complete pay -->
			<template v-slot:item.completePay="{ item }">
				{{item.payments.primaryPayment.toLocaleString()}}
			</template>
			
			<!-- transport pay -->
			<template v-slot:item.transportPay="{ item }">
				{{item.payments.transportationPayment.toLocaleString()}}
			</template>

			<!-- minimum average -->
			<template v-slot:item.minimumAverage="{ item }">
				{{(item.minimumAverage || 0)}}
				<span :class="[item.hasMinimumAverage ? 'green--text' : 'warning--text', 'text-caption ms-2']">
					({{item.hasMinimumAverage ? 'فعال' : 'غير فعال'}})
				</span>
			</template>
			
			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex align-center" style="padding: 2px 0">
					<!-- edit -->
					<v-tooltip top>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								color="lightGreen"
								class="rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
								@click="itemId = item.id; dialog = true"
							>
								<v-icon>mdi-cash-edit</v-icon>
							</v-btn>
						</template>
						تعديل الدفعات
					</v-tooltip>
					
					<!--  -->
					<v-tooltip top>
						<template v-slot:activator="{ on, attr }">
							<v-btn
								v-on="on"
								v-bind="attr"
								color="warning"
								class="ms-1 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								@click="
									gradeId = item.id;
									minimumAverage = item.minimumAverage;
									hasMinimumAverage = item.hasMinimumAverage;
									minimumAverageDialog = true;
								"
							>
								<v-icon>mdi-file-document-edit-outline</v-icon>
							</v-btn>
						</template>
						تعديل الحد الأدنى
					</v-tooltip>

					<!-- placement state -->
					<v-checkbox
						color="primary"
						class="ma-0 pa-0 ms-2 text-body-2"
						hide-details
						readonly
						v-model="item.hasPlacementTest"
						@click="gradeId = item.id; confirmPlacementTestDialog = true"
					>
						<template v-slot:label>
							<span class="text-caption font-weight-bold">
								{{item.hasPlacementTest ? 'له سبر' : 'بدون سبر'}}
							</span>
						</template>
					</v-checkbox>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { paymentTypes } from '@/helpers/enums';
import { mapGetters, mapState } from 'vuex';
import rules from '@/helpers/validation rules';

export default {
	name: 'ClassesPayments',

	data() {
		return {
			dialog: false,
			loading: false,
			submitLoading: false,
			
			confirmPlacementTestDialog: false,
			updatePlacementTestStateLoading: false,
			
			itemId: null,

			rules,

			headers: [
				{
					text: '#',
					value: 'no',
					class: 'ps-3 pe-0',
					cellClass: 'text-body-2 ps-3 pe-0',
					width: 40
				},
				{
					text: 'الصف',
					value: 'name',
					cellClass: 'text-body-2'
				},
				{
					text: 'دفعة التسجيل',
					value: 'registerPay',
					cellClass: 'text-subtitle-2'
				},
				{
					text: 'دفعة الاستكمال',
					value: 'completePay',
					cellClass: 'text-subtitle-2'
				},
				{
					text: 'دفعة المواصلات',
					value: 'transportPay',
					cellClass: 'text-subtitle-2'
				},
				{
					text: 'الحد الأدنى',
					value: 'minimumAverage',
					cellClass: 'text-subtitle-2'
				},
				{
					text: '',
					value: 'actions',
					cellClass: 'text-body-2',
					sortable: false,
					width: 200
				},
			],

			// data
			dialogData: {
				className: null,
				registerPay: null,
				completePay: null,
				transportPay: null,
			},

			// minimum average
			gradeId: null,
			minimumAverageDialog: false,
			submitMinimumAverageLoading: false,

			minimumAverage: null,
			hasMinimumAverage: null,
		}
	},

	watch: {
		dialog(newVal) {
			if (newVal) {
				if (this.itemId) {
					const grade = this.getGradeById(this.itemId);
					this.dialogData.className = grade.name;
					this.dialogData.registerPay = grade.payments.registrationPayment;
					this.dialogData.completePay = grade.payments.primaryPayment;
					this.dialogData.transportPay = grade.payments.transportationPayment;
				}
			}
		}
	},

	computed: {
		...mapState({
			grades: state => state.grades.grades
		}),
		...mapGetters({
			getGradeById: 'grades/getGradeById'
		})
	},

	methods: {
		fetchGrades() {
			this.loading = true;
			this.$store.dispatch('grades/fetchAll').finally(() => {
				this.loading = false;
			});
		},
		
		updateGradePlacementTest() {
			this.updatePlacementTestStateLoading = true;
			this.$store.dispatch('grades/updatePlacementTestState', {
				id: this.gradeId,
				active: !this.getGradeById(this.gradeId).hasPlacementTest,
			}).then(() => {
				this.confirmPlacementTestDialog = false;
				this.fetchGrades();
			}).finally(() => {
				this.updatePlacementTestStateLoading = false;
			})
		},

		submitMinimumGradeAverage() {
			this.submitMinimumAverageLoading = true;
			this.$store.dispatch('grades/updateMinimumAverage', {
				id: this.gradeId,
				hasMinimumAverage: this.hasMinimumAverage,
				minimumAverage: this.minimumAverage
			}).then(() => {
				this.fetchGrades();
			}).finally(() => {
				this.submitMinimumAverageLoading = false;
			})
		},

		submit() {
			if (this.$refs.form.validate()) {
				const grade = this.getGradeById(this.itemId);
				const registerPay = grade.payments.registrationPayment;
				const completePay = grade.payments.transportationPayment;
				const transportPay = grade.payments.primaryPayment;

				this.submitLoading = true;
				const promises = [
					// register payment
					this.dialogData.registerPay !== registerPay
						? this.$store.dispatch('grades/updatePayment', {
							id: this.itemId,
							paymentTypes: paymentTypes.registration,
							amount: this.dialogData.registerPay
						})
						: null,

					// complete payment
					this.dialogData.completePay !== completePay
						? this.$store.dispatch('grades/updatePayment', {
							id: this.itemId,
							paymentTypes: paymentTypes.primary,
							amount: this.dialogData.completePay
						})
						: null,

					// transport payment
					this.dialogData.transportPay !== transportPay
						? this.$store.dispatch('grades/updatePayment', {
							id: this.itemId,
							paymentTypes: paymentTypes.transportation,
							amount: this.dialogData.transportPay
						})
						: null,
				];
				
				Promise.all(promises).then(() => {
					this.fetchGrades();
					this.dialog = false;
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		}
	},

	created() {
		this.fetchGrades();
	},

	metaInfo: {
		title: 'إدارة الصفوف',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style>

</style>