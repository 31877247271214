<template>
    <v-dialog v-model="localValue" width="450" content-class="rounded-xl" persistent>
		<v-form ref="exemptionForm" @submit.prevent="saveExemption()" :disabled="disabled">
			<v-card class="py-4 px-4">
				<v-card-title>
					<v-icon class="me-2" size="32" color="lightBlue">
						mdi-alert-octagon-outline
					</v-icon>
					<span class="lightBlue--text">{{studentHasExemption ? 'مستثنى' : 'استثناء'}}</span>
				</v-card-title>
				<v-card-text>
					<!-- reason -->
					<p class="black--text mb-3 ps-3">السبب</p>
					<v-textarea
						v-model="exemptionData.reason"
						name="input-7-1"
						placeholder="السبب الذي أدى للاستثناء"
						class="rounded-xl"
						filled
						outlined
						rows="3"
						auto-grow
						no-resize
						:readonly="loading"
						hide-details
					></v-textarea>

					<!-- state -->
					<p class="black--text mt-5 mb-3 ps-3">الإجراء المطلوب</p>

					<div class="d-flex flex-wrap align-center mt-3 ms-3">
						<!-- test pass -->
						<!-- TODO -->
						<!-- :disabled="disableChangeStateExciton" -->
						<v-checkbox
							v-model="exemptionData.testPass"
							label="تجاوز السبر"
							class="mt-0"
							:readonly="loading"
							hide-details
						></v-checkbox>
						
						<!-- test retake -->
						<!-- TODO -->
						<!-- :disabled="disableChangeStateExciton" -->
						<v-checkbox
							v-if="isStudentHasPlacementTest"
							v-model="exemptionData.testRetake"
							label="إعادة السبر"
							class="mt-0 ms-3"
							:disabled="studentData.state === studentsStatus.passed"
							:readonly="loading"
							hide-details
						></v-checkbox>
					</div>

					<!-- placement test -->
					<div
						v-if="exemptionData.testRetake"
						class="d-flex flex-wrap align-center mt-3 ms-3"
					>
						<!-- TODO -->
						<!-- :disabled="disableChangeStateExciton" -->
						<v-autocomplete
							v-model="exemptionData.newPlacementTestId"
							:items="getAvailablePlacementTests()"
							:item-text="item => getPlacementTestFull(item)"
							item-value="id"
							hide-details
							class="grey--text rounded-medium grey--text me-sm-13"
							label="اختر الامتحان"
							no-data-text="لا يوجد امتحانات قادمة"
							dense
							outlined
							:readonly="loading"
							:rules="rules.required"
						></v-autocomplete>
					</div>

					<div class="d-flex flex-wrap align-center mt-3 ms-3">
						<!-- unstrict payment -->
						<v-checkbox
							v-model="exemptionData.unStrictPayment"
							label="تعديل دفعة التسجيل"
							class="mt-0"
							:readonly="loading"
							hide-details
						></v-checkbox>
						
						<!-- unstrict grade total -->
						<v-checkbox
							v-model="exemptionData.unStrictGradeTotal"
							label="تجاوز المجموع"
							class="mt-0 ms-3"
							:readonly="loading"
							hide-details
						></v-checkbox>

						<!-- unstrict completion payment -->
						<v-checkbox
							v-model="exemptionData.unStrictCompletionPayment"
							label="تعديل دفعة الاستكمال"
							class="mt-3"
							:readonly="loading"
							hide-details
						></v-checkbox>
					</div>
					
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="!disabled"
						color="lightGreen white--text"
						type="submit"
						class="rounded-xl px-5"
						:loading="loading"
						:disabled="loading || deleteLoading || noExemptionSelected"
					>
						موافق
					</v-btn>
					<v-btn
						v-if="studentHasExemption && !disabled"
						color="error lighten-1 white--text"
						class="rounded-xl px-5"
						:loading="deleteLoading"
						:disabled="loading || deleteLoading"
						@click="saveExemption(true)"
					>
						إلغاء الاستثناء
					</v-btn>
					<v-btn
						text
						class="rounded-xl px-5"
						@click="localValue = false"
						:disabled="loading || deleteLoading"
					>
						إلغاء
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { genders, studentsStatus, studentStatusFilters } from '@/helpers/enums';
import { getFullDate as getFullPlacementTestDate } from '@/helpers/helpers';
import rules from '@/helpers/validation rules';
export default {
	name: 'ExemptionDialog',
	
	props: {
		studentHasExemption: { type: Boolean },
		exemptionStudentId: { type: String },
		disabled: { type: Boolean, default: false },
		value: { type: Boolean, default: false }	
	},

	data: () => ({
		rules,
		studentsStatus,
		
		states: [{ label: 'تجاوز السبر', value: 0 }, { label: 'إعادة السبر', value: 1 }],
		loading: false,
		deleteLoading: false,

		studentData: {},

		exemptionData: {
			reason: null,
			testPass: null,
			testRetake: null,
			unStrictPayment: null,
			unStrictGradeTotal: null,
			unStrictCompletionPayment: null,
		},
		isStudentHasPlacementTest: false,
	}),

	watch: {
		value(val) {
			if (val) {
				this.studentData = this.getStudentById(this.exemptionStudentId);
				this.isStudentHasPlacementTest = this.getGradeById(this.studentData.gradeId)?.hasPlacementTest;
				
				if (this.studentHasExemption) {
					const student = this.studentData;
					this.exemptionData.reason = student.exemption.description;
					this.exemptionData.testPass = student.exemption.testPass;
					this.exemptionData.testRetake = student.exemption.testRetake;
					this.exemptionData.unStrictPayment = student.exemption.unStrictPayment;
					this.exemptionData.unStrictGradeTotal = student.exemption.unStrictGradeTotal;
					this.exemptionData.unStrictCompletionPayment = student.exemption.unStrictCompletionPayment;
					
					if (student.exemption.testRetake) {
						this.exemptionData.newPlacementTestId = student.placementTestId;
					}
				}
			} else {
				this.$refs.exemptionForm.reset();
				this.isStudentHasPlacementTest = false;
				this.studentData = {};
			}
		}
	},

	computed: {
		...mapState({
			placementTests: state => state.placementTests.placementTests
		}),

		...mapGetters({
			getStudentById: 'students/getStudentById',
			getGradeById: 'grades/getGradeById',
		}),
		
		localValue: {
			get() {
				return this.value
			},
			set(newValue) {
				this.$emit('input', newValue)
			}
		},

		noExemptionSelected() {
			return (
				!this.exemptionData.reason &&
				!this.exemptionData.testPass &&
				!this.exemptionData.testRetake &&
				!this.exemptionData.unStrictPayment &&
				!this.exemptionData.unStrictGradeTotal &&
				!this.exemptionData.unStrictCompletionPayment
			)
		},

		// TODO use this condition
		disableChangeStateExciton() {
			return (
				this.studentData.isCompleted ||
				this.studentData.state === studentsStatus.passed ||
				this.studentData.studentStatusFilters === studentStatusFilters.withdrawnAndRecovered ||
				this.studentData.isNokhbaStudent
			)
		}
	},

	methods: {
		saveExemption(isDelete) {
			if (this.$refs.exemptionForm.validate()) {
				this.loading = !isDelete;
				this.deleteLoading = isDelete;
				const actionName = isDelete ? 'deleteExemption' : 'createExemption';
				this.$store.dispatch(`students/${actionName}`, { 
					studentId: this.exemptionStudentId, 
					description: this.exemptionData.reason, 
					testPass: Boolean(this.exemptionData.testPass),
					testRetake: Boolean(this.exemptionData.testRetake), 
					unStrictPayment: this.exemptionData.unStrictPayment || false,
					unStrictGradeTotal: this.exemptionData.unStrictGradeTotal || false,
					unStrictCompletionPayment: this.exemptionData.unStrictCompletionPayment || false
				}).then(() => {
					const message = isDelete ? 'تم حذف الاستثناء' : 'تم تحديث استثناء الطالب'
					this.$eventBus.$emit('show-snackbar', isDelete, message);

					// if student can retake exam update exam
					if (
						this.exemptionData.testRetake &&
						this.exemptionData.newPlacementTestId !== this.studentData.placementTestId &&
						actionName !== 'deleteExemption'
					) {
						this.$store.dispatch('students/retakeExam', {
							id: this.exemptionStudentId,
							newPlacementTestId: this.exemptionData.newPlacementTestId
						}).then(() => {
							this.localValue = false;
							this.$eventBus.$emit(
								'show-snackbar',
								false,
								'تم تحديد امتحان جديد .. علماً أن إلغاء الاستثناء لا يقوم بعكس هذه العملية'
							);
						}).finally(() => {
							this.loading = false;
							this.deleteLoading = false;
							this.$emit('succeeded');
						})
					} else {
						this.localValue = false;
						this.loading = false;
						this.deleteLoading = false;
						this.$emit('succeeded');
					}
				}).finally(() => {
					if (
						this.$route.name === 'placement-tests-results' &&
						!this.exemptionData.testRetake &&
						actionName !== 'deleteExemption'
					) {
						this.loading = false;
						this.deleteLoading = false;
					}
				})
			}
		},

		getAvailablePlacementTests() {
			const student = this.getStudentById(this.exemptionStudentId);
			return this.placementTests.filter(c => c.studentsGender === student.gender && c.gradeId === student.gradeId && moment(c.testDate).isAfter(moment().format('YYYY-MM-DD')))
		},

		getPlacementTestFull(item) {
			return (
				this.getGradeById(item.gradeId).name + ' - ' +
				genders.find(c => c.id === item.studentsGender).plural + ' - ' +
				getFullPlacementTestDate(item.testDate)
			)
		},
	}
}
</script>