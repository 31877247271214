<template>
    <div>
        <v-dialog
            v-model="localValue"
            transition="dialog-transition"
            width="300"
            content-class="rounded-xl"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-card class="pa-2">
                    <v-card-title class="error--text text--lighten-1">
						تأكيد الحذف
					</v-card-title>
                    <v-card-text class="pb-2">
                        <!-- dialog content -->
                        <slot></slot>
                    </v-card-text>
                    <v-card-actions class="px-2 justify-end">
                        <v-btn
                            :loading="loading"
                            @click="deleteData"
                            color="red lighten-1"
                            dark
                            class="rounded-xl"
                            elevation="1"
                        >
                            حذف
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            @click="dialog.value = false"
                            elevation="1"
                            class="rounded-xl"
                        >
                            إلغاء
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
export default {
	name: 'BeeDeleteDialog',
	props: {
		value: { type: Boolean, default: false },
		moduleType: { type: String, required: true },
		ids: { type: [Number, String, Object] },
	},

	data() {
		return {
			loading: false,
		}
	},

	methods: {
		deleteData() {
			this.loading = true;

			let actionData = {};
			
			if (typeof this.ids === 'number' || typeof this.ids === 'string') {
				actionData = { id: this.ids };
			} else if (this.ids && Object.keys(this.ids).length > 0) {
				actionData = this.ids;
			}

			this.$store.dispatch(`${this.moduleType}/delete`, actionData)
				.then(() => {
					this.$eventBus.$emit('show-snackbar', true, 'تمت عملية الحذف بنجاح');
					this.$emit('on-delete');
					this.$refs.dialog.isActive = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	computed: {
		localValue: {
			get: function () {
				return this.value;
			},
			set: function (newValue) {
				this.$emit('input', newValue);
			},
		}
	}
}
</script>

<style>

</style>