import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import { BASE_API_URL } from './constants'

Vue.use(VueMeta)

Vue.config.productionTip = false;

const $eventBus = new Vue();
Vue.prototype.$eventBus = $eventBus;

Vue.filter('isAvailable', (val) => {
	return val || '-';
})

Vue.filter('ellipsis', (val, length = 50) => {
	return val.length < length ? val : val.slice(0, length) + '...';
})
window.axios = axios.create({
	baseURL: BASE_API_URL + '/api'
})
window.axios.interceptors.response.use(
	function (response) {
		return response;
	},
	
	function (error) {
		if (!window.navigator.onLine) {
			$eventBus.$emit('show-snackbar', true, 'أنت غير متصل بالإنترنت');
			return Promise.reject(error);
		}
		if (error.response.status === 401) {
			store.dispatch('auth/logout');
			router.replace({ name: 'login' })
		}
		if (router.currentRoute.name !== 'login') {
			$eventBus.$emit('show-snackbar', true, error.response.data.message || error.response.data.error || 'حدث خطأ ما')
		}
		return Promise.reject(error);
	}
);

if ('auth' in localStorage) {
	const auth = JSON.parse(localStorage.getItem('auth'));
	window.axios.defaults.headers.common.Authorization = auth.token;
	window.axios.defaults.headers.common['x-center'] = auth.centerId;
	
	store.state.auth.authenticated = true;
	store.state.auth.self = {
		name: auth.name,
		username: auth.username,
		userType: auth.userType
	}
	store.state.auth.isAdmin = auth.userType === 2;
	
	store.dispatch('centers/changeCenterId', { id: auth.centerId });
	
	store.dispatch('users/fetchById', { id: auth.id })
		.catch(() => {
			store.dispatch('auth/logout');
			router.replace({ name: 'login' })
		})
}

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
