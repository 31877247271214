import * as types from '../mutation-types'

export default {
	namespaced: true,
	
	state: {
		statics: {}
	},

	actions: {
		fetchAll({ commit }, { gradeId, gender, testId, withoutNukhbaStudents }) {
			return axios.get('/statics', {
				params: { gradeId, gender, testId, withoutNukhbaStudents }
			}).then(({ data }) => {
				commit(types.STORE_STATICS, { data, withoutNukhbaStudents });
				return data;
			})
		},

		reset({ commit }) {
			commit(types.RESET_STATICS);
		}
	},

	mutations: {
		[types.STORE_STATICS](state, { data: statics, withoutNukhbaStudents }) {
			state.statics = {
				...statics,
				nokhbaStudentCount: !withoutNukhbaStudents ? statics.nokhbaStudentCount : 0,
				edunixCodeStudentCount: !withoutNukhbaStudents ? statics.edunixCodeStudentCount : 0,
			};
		},
		[types.RESET_STATICS](state) {
			state.statics = {};
		},
	}
}