export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const STORE_CENTERS = 'STORE_CENTERS';
export const STORE_CURRENT_CENTER_ID = 'STORE_CURRENT_CENTER_ID';

export const STORE_STUDENTS = 'STORE_STUDENTS';
export const STORE_PREVIOUS_SCHOOLS = 'STORE_PREVIOUS_SCHOOLS';

export const STORE_STUDENTS_STATICS = 'STORE_STUDENTS_STATICS';
export const STORE_PAYMENTS_STATICS = 'STORE_PAYMENTS_STATICS';

export const STORE_PLACEMENT_TESTS = 'STORE_PLACEMENT_TESTS';
export const STORE_ACTIVE_PLACEMENT_TESTS = 'STORE_ACTIVE_PLACEMENT_TESTS';

export const STORE_REGIONS = 'STORE_REGIONS';

export const STORE_SCHOOLS = 'STORE_SCHOOLS';

export const STORE_SUBJECTS = 'STORE_SUBJECTS';

export const STORE_GRADES = 'STORE_GRADES';

export const STORE_STATICS = 'STORE_STATICS';
export const RESET_STATICS = 'RESET_STATICS';