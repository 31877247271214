import StudentsData from '../views/StudentsData.vue'
import Accounting from '../views/Accounting.vue'
import PlacementTests from '../views/PlacementTests.vue'
import PlacementTestsLists from '../views/PlacementTestsLists.vue'
import PlacementTestResults from '../views/PlacementTestResults.vue'
import Statistics from '../views/Statistics.vue'
import Subjects from '../views/Subjects.vue'
import GradeManagement from '../views/GradeManagement.vue'
import Schools from '../views/Schools.vue'
import Regions from '../views/Regions.vue'
import RegisterCard from '../views/RegisterCard.vue'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
export default [
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/students-data',
		name: 'students-data',
		component: StudentsData
	},
	{
		path: '/accounting',
		name: 'accounting',
		component: Accounting
	},
	{
		path: '/register-card',
		name: 'register-card',
		component: RegisterCard
	},
	{
		path: '/placement-tests',
		name: 'placement-tests',
		component: PlacementTests
	},
	{
		path: '/placement-tests-lists',
		name: 'placement-tests-lists',
		component: PlacementTestsLists
	},
	{
		path: '/placement-tests-results',
		name: 'placement-tests-results',
		component: PlacementTestResults
	},
	{
		path: '/statistics',
		name: 'statistics',
		component: Statistics
	},
	{
		path: '/subjects',
		name: 'subjects',
		component: Subjects
	},
	{
		path: '/grade-management',
		name: 'grade-management',
		component: GradeManagement
	},
	{
		path: '/schools',
		name: 'schools',
		component: Schools
	},
	{
		path: '/regions',
		name: 'regions',
		component: Regions
	},
	{
		path: '*',
		redirect: '/'
	},
]
