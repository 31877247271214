import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import centers from './modules/centers'
import users from './modules/users'
import students from './modules/students'
import studentsReports from './modules/students-reports'
import regions from './modules/regions'
import schools from './modules/schools'
import grades from './modules/grades'
import subjects from './modules/subjects'
import payments from './modules/payments'
import placementTests from './modules/placement-tests'
import studentsMarks from './modules/students-marks'
import statics from './modules/statics'

Vue.use(Vuex)
export default new Vuex.Store({
	modules: {
		auth,
		centers,
		users,
		students,
		studentsReports,
		studentsMarks,
		regions,
		schools,
		grades,
		subjects,
		payments,
		placementTests,
		statics,
	}
})
