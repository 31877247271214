<template>
	<v-card
		elevation="3"
		:loading="loading"
		class="rounded-medium"
	>
		<v-card-text class="pa-0">
			<v-tabs grow class="rounded-medium" style="overflow: hidden">
				<v-tab>إحصائيات</v-tab>
				<v-tab>تقارير</v-tab>

				<!-- statistics -->
				<v-tab-item class="pa-6 bgColor">
					<!-- filters -->
					<v-form ref="form" @submit.prevent="filterData" :disabled="loading" class="mb-2">
						<v-row class="align-center">
							<!-- grade -->
							<v-col lg="1" md="3" sm="4" cols="12" class="px-2">
								<v-autocomplete
									v-model="filter.gradeId"
									:items="grades"
									item-text="name"
									item-value="id"
									label="الصف"
									outlined
									hide-details
									dense
									auto-select-first
									append-icon
									class="rounded-medium grey--text"
									@change="filter.placementTestId = null"
								></v-autocomplete>
							</v-col>
						
							<!-- gender -->
							<v-col lg="1" md="3" sm="4" cols="12" class="px-2">
								<v-autocomplete
									v-model="filter.genderId"
									:items="genders"
									item-text="plural"
									item-value="id"
									label="الجنس"
									auto-select-first
									outlined
									hide-details
									dense
									append-icon
									class="rounded-medium grey--text"
									@change="filter.placementTestId = null"
								></v-autocomplete>
							</v-col>

							<!-- placement test -->
							<v-col lg="2" md="3" sm="4" cols="12" class="px-2">
								<v-autocomplete
									v-model="filter.placementTestId"
									:items="
										placementTests
										.filter(c => !filter.gradeId || c.gradeId === filter.gradeId)
										.filter(c => filter.genderId === null || c.studentsGender === filter.genderId)
									"
									:item-text="item => getFullDate(item.testDate)"
									item-value="id"
									label="الامتحان"
									auto-select-first
									outlined
									hide-details
									dense
									append-icon
									class="rounded-medium grey--text"
								></v-autocomplete>
							</v-col>
							
							<!-- without nukhba students -->
							<v-col lg="auto" md="3" sm="4" cols="12" class="px-2">
								<v-checkbox
									v-model="filter.withoutNukhbaStudents"
									label="من دون طلاب النخبة وإديونكس"
									hide-details
									dense
									class="ma-0"
								></v-checkbox>
							</v-col>
						
							<!-- search button -->
							<v-col cols="auto" class="px-2">
								<v-btn
									type="submit"
									color="primary"
									class="rounded-xl px-5"
									key="search-btn"
									:loading="loading"
									:disabled="loading"
								>بحث</v-btn>
							</v-col>
						</v-row>
					</v-form>

					<v-row class="my-0 text-body-2">
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							العدد الكلي: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.totalStudentCount || 0}}
							</span>
						</v-col>
					
						<!-- registered students -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							عدد المسجلين (بدون دفع): 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.registeredStudentCount || 0}}
							</span>
						</v-col>

						<!-- registered students with payment -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							عدد المسجلين (مع دفع): 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.paidStudentCount || 0}}
							</span>
						</v-col>

						<!-- Not completed students -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							عدد طلاب الاستكمال: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.notCompletedStudentCount || 0}}
							</span>
						</v-col>
						
						<!-- completed students -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							عدد المستكملين: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.completedStudentCount || 0}}
							</span>
						</v-col>
						
						<!-- with drawn students -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							عدد طلاب الاسترداد: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.withdrawnStudentCount || 0}}
							</span>
						</v-col>

						<!-- with drawn and recovered students -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							عدد المنسحبين: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.withdrawnAndRecoveredStudentCount || 0}}
							</span>
						</v-col>
					</v-row>

					<p class="mt-4 mb-2 font-weight-medium grey--text text--darken-2">
						إحصائيات خاصة
					</p>

					<v-row dense class="my-0 text-body-2">
						<!-- Succeeded students -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-3">
							عدد الناجحين: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.successfulStudentsCount || 0}}
							</span>
						</v-col>
						
						<!-- failed students -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-3">
							عدد الراسبين: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.failStudentCount || 0}}
							</span>
						</v-col>

						<!-- nokbah students -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-3">
							عدد طلاب النخبة: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.nokhbaStudentCount || 0}}
							</span>
						</v-col>
					
						<!-- Edunix students -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-3">
							عدد طلاب إديونكس: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.edunixCodeStudentCount || 0}}
							</span>
						</v-col>

						<!-- with transports -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-3">
							عدد المسجلين على المواصلات: 
							<span class="primary--text text-body-1 font-weight-bold">
								{{statistics.withTransport || 0}}
							</span>
						</v-col>
					</v-row>
				</v-tab-item>

				<!-- reports -->
				<v-tab-item class="pa-6 bgColor">
					<v-row>
						<!-- transportation report -->
						<v-col md="7" cols="12" class="pa-2">
							<v-card elevation="1" class="rounded-medium">
								<v-card-title class="text-body-1 primary--text">
									تقرير المواصلات
								</v-card-title>
								<v-card-text>
									<v-row>
										<!-- gradeLevel -->
										<v-col lg="2" md="4" sm="6" cols="6" class="px-2">
											<v-autocomplete
												v-model="transportationReport.gradeLevelId"
												:items="gradeLevel"
												item-text="name"
												item-value="id"
												label="المرحلة"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!transportationReport.gradeId && !transportationReport.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? transportationReport.gradeId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>

										<!-- grade -->
										<v-col lg="2" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="transportationReport.gradeId"
												:items="grades"
												item-text="name"
												item-value="id"
												label="الصف"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!transportationReport.gradeId && !transportationReport.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? transportationReport.gradeLevelId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- gender -->
										<v-col lg="2" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="transportationReport.genderId"
												:items="genders"
												item-text="plural"
												item-value="id"
												label="الجنس"
												auto-select-first
												outlined
												hide-details
												dense
												append-icon
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- region -->
										<v-col lg="2" md="6" class="px-2">
											<v-autocomplete
												v-model="transportationReport.regionIds"
												:items="regions"
												item-text="name"
												item-value="id"
												label="المنطقة"
												auto-select-first
												outlined
												multiple
												hide-details
												dense
												append-icon
												:rules="rules.requiredAsArray"
												class="rounded-medium grey--text px"
											>
												<template v-slot:prepend-item>
													<v-list-item
														ripple
														@mousedown.prevent
														@click="toggle"
													>
														<v-list-item-action>
															<v-icon
																:color="transportationReport.regionIds.length > 0 ? 'primary' : ''"
															>
															{{ icon }}
															</v-icon>
														</v-list-item-action>
														<v-list-item-content>
															<v-list-item-title>
																اختر الكل
															</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
													<v-divider/>
												</template>

												<template v-slot:selection="{ item, index }">
													<span v-if="index === 0" class="me-1">{{ item.name }}</span>
													<span
														v-else-if="index === 1"
														class="grey--text text-caption"
													>
														(+{{ transportationReport.regionIds.length - 1 }} أخرى)
													</span>
												</template>
											</v-autocomplete>
										</v-col>

										<!-- with / without -->
										<v-col lg="2" md="6" class="px-2">
											<v-autocomplete
												v-model="transportationReport.state"
												:items="[
													{ name: 'الكل', value: null },
													{ name: 'مع مواصلات', value: true },
													{ name: 'بدون مواصلات', value: false },
												]"
												item-text="name"
												item-value="value"
												label="الحالة"
												auto-select-first
												outlined
												hide-details
												dense
												append-icon
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- report button -->
										<v-col cols="auto" class="px-2">
											<v-btn
												color="primary"
												class="rounded-xl px-5"
												:disabled="
													loading || (
														!transportationReport.gradeId &&
														!transportationReport.gradeLevelId
													) ||
													!transportationReport.regionIds.length
												"
												:loading="loadingReport === 0"
												@click="report(0)"
											>توليد</v-btn>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>

						<!-- data and marks report -->
						<v-col md="5" cols="12" class="pa-2">
							<v-card elevation="1" class="rounded-medium">
								<v-card-title class="text-body-1 primary--text">
									تقرير بيانات وعلامات الطلاب
								</v-card-title>
								<v-card-text>
									<v-row>
										<!-- gradeLevel -->
										<v-col lg="3" md="4" sm="6" cols="6" class="px-2">
											<v-autocomplete
												v-model="dataAndMarks.gradeLevelId"
												:items="gradeLevel"
												item-text="name"
												item-value="id"
												label="المرحلة"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!dataAndMarks.gradeId && !dataAndMarks.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? dataAndMarks.gradeId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>

										<!-- grade -->
										<v-col lg="3" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="dataAndMarks.gradeId"
												:items="grades"
												item-text="name"
												item-value="id"
												label="الصف"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!dataAndMarks.gradeId && !dataAndMarks.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? dataAndMarks.gradeLevelId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- gender -->
										<v-col lg="3" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="dataAndMarks.genderId"
												:items="genders"
												item-text="plural"
												item-value="id"
												label="الجنس"
												auto-select-first
												outlined
												hide-details
												dense
												append-icon
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- report button -->
										<v-col cols="auto" class="px-2">
											<v-btn
												color="primary"
												class="rounded-xl px-5"
												:disabled="
													loading || (
														!dataAndMarks.gradeId &&
														!dataAndMarks.gradeLevelId
													)
												"
												:loading="loadingReport === 1"
												@click="report(1)"
											>توليد</v-btn>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
						
						<!-- cloths reports -->
						<v-col md="7" cols="12" class="pa-2">
							<v-card elevation="1" class="rounded-medium">
								<v-card-title class="text-body-1 primary--text">
									تقرير اللباس
								</v-card-title>
								<v-card-text>
									<v-row>
										<!-- gradeLevel -->
										<v-col lg="2" md="4" sm="6" cols="6" class="px-2">
											<v-autocomplete
												v-model="clothReport.gradeLevelId"
												:items="gradeLevel"
												item-text="name"
												item-value="id"
												label="المرحلة"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!clothReport.gradeId && !clothReport.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? clothReport.gradeId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>

										<!-- grade -->
										<v-col lg="2" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="clothReport.gradeId"
												:items="grades"
												item-text="name"
												item-value="id"
												label="الصف"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!clothReport.gradeId && !clothReport.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? clothReport.gradeLevelId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- gender -->
										<v-col lg="3" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="clothReport.genderId"
												:items="genders"
												item-text="plural"
												item-value="id"
												label="الجنس"
												auto-select-first
												outlined
												hide-details
												dense
												append-icon
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- report button -->
										<v-col cols="auto" class="px-2">
											<v-btn
												color="primary"
												class="rounded-xl px-5"
												:disabled="
													loading || (
														!clothReport.gradeId &&
														!clothReport.gradeLevelId
													)
												"
												:loading="loadingReport === 2"
												@click="report(2)"
											>توليد</v-btn>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
						
						<!-- tailor report -->
						<v-col md="5" cols="12" class="pa-2">
							<v-card elevation="1" class="rounded-medium">
								<v-card-title class="text-body-1 primary--text">
									تقرير طلبية الخياط
								</v-card-title>
								<v-card-text>
									<v-row>
										<!-- gradeLevel -->
										<v-col lg="3" md="4" sm="6" cols="6" class="px-2">
											<v-autocomplete
												v-model="tailorReport.gradeLevelId"
												:items="gradeLevel"
												item-text="name"
												item-value="id"
												label="المرحلة"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!tailorReport.gradeId && !tailorReport.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? tailorReport.gradeId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>

										<!-- grade -->
										<v-col lg="3" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="tailorReport.gradeId"
												:items="grades"
												item-text="name"
												item-value="id"
												label="الصف"
												outlined
												hide-details
												dense
												auto-select-first
												append-icon
												:rules="
													!tailorReport.gradeId && !tailorReport.gradeLevelId
														? rules.required
														: []
												"
												@change="$event ? tailorReport.gradeLevelId = null : null"
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>

										<!-- gender -->
										<v-col lg="3" md="6" sm cols="12" class="px-2">
											<v-autocomplete
												v-model="tailorReport.genderId"
												:items="genders"
												item-text="plural"
												item-value="id"
												label="الجنس"
												auto-select-first
												outlined
												hide-details
												dense
												append-icon
												class="rounded-medium grey--text px"
											></v-autocomplete>
										</v-col>
									
										<!-- report button -->
										<v-col cols="auto" class="px-2">
											<v-btn
												color="primary"
												class="rounded-xl px-5"
												:disabled="
													loading || (
														!tailorReport.gradeId &&
														!tailorReport.gradeLevelId
													)
												"
												:loading="loadingReport === 3"
												@click="report(3)"
											>توليد</v-btn>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs>
		</v-card-text>
	</v-card>
</template>

<script>
import { genders, gradeLevel } from '@/helpers/enums'
import { mapState } from 'vuex'
import rules from '@/helpers/validation rules'
import { getFullDate } from '@/helpers/helpers'
export default {
	name: 'Statistics',

	data() {
		return {
			// helpers
			loading: false,
			loadingReport: null,
			genders,
			gradeLevel,
			rules,

			// filter
			filter: {
				gradeId: null,
				genderId: null,
				placementTestId: null,
				withoutNukhbaStudents: null,
			},

			// data
			dataAndMarks: {
				gradeLevelId: null,
				gradeId: null,
				genderId: null
			},

			transportationReport: {
				gradeLevelId: null,
				gradeId: null,
				genderId: null,
				regionIds: [],
				state: null
			},

			clothReport: {
				gradeLevelId: null,
				gradeId: null,
				genderId: null,
			},

			tailorReport: {
				gradeLevelId: null,
				gradeId: null,
				genderId: null,
			}
		}
	},

	computed: {
		...mapState({
			statistics: state => state.statics.statics,
			grades: state => state.grades.grades,
			regions: state => state.regions.regions,
			placementTests: state => state.placementTests.placementTests,
		}),

		icon () {
			if (this.transportationReport.regionIds.length === this.regions.length) return 'mdi-close-box'
			if (this.transportationReport.regionIds.length > 0) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},
	},

	methods: {
		filterData() {
			this.loading = true;
			this.$store.dispatch('statics/fetchAll', {
				gradeId: this.filter.gradeId,
				gender: this.filter.genderId,
				testId: this.filter.placementTestId,
				withoutNukhbaStudents: this.filter.withoutNukhbaStudents
			}).finally(() => {
				this.loading = false;
			})
		},

		report(type) {
			let actionName;
			const requestData = {};
			switch (type) {
			case 0: {
				actionName = 'transports';
				requestData.gradeLevel = this.transportationReport.gradeLevelId
				requestData.gradeId = this.transportationReport.gradeId
				requestData.gender = this.transportationReport.genderId
				requestData.regionIds = this.transportationReport.regionIds
				requestData.withTransportation = this.transportationReport.state
				break; 
			}
			case 1: {
				actionName = 'studentDataAndMarks';
				requestData.gradeLevel = this.dataAndMarks.gradeLevelId
				requestData.gradeId = this.dataAndMarks.gradeId
				requestData.gender = this.dataAndMarks.genderId
				break; 
			}
			case 2: {
				actionName = 'cloths';
				requestData.gradeLevel = this.clothReport.gradeLevelId
				requestData.gradeId = this.clothReport.gradeId
				requestData.gender = this.clothReport.genderId
				break; 
			}
			case 3: {
				actionName = 'clothsOrder';
				requestData.gradeLevel = this.tailorReport.gradeLevelId;
				requestData.gradeId = this.tailorReport.gradeId;
				requestData.gender = this.tailorReport.genderId;
				break;
			}
			}
			this.loadingReport = type;
			this.$store.dispatch(`studentsReports/${actionName}`, {
				centerId: JSON.parse(localStorage.getItem('auth')).centerId,
				...requestData
			})
				.then(() => {
					this.$eventBus.$emit('show-snackbar', false, 'سيبدأ التنزيل خلال ثوانٍ ...');
				})
				.finally(() => {
					this.loadingReport = null;
				})
		},

		toggle () {
			this.$nextTick(() => {
				if (this.transportationReport.regionIds.length === this.regions.length) {
					this.transportationReport.regionIds = []
				} else {
					this.transportationReport.regionIds = this.regions.map(c => c.id);
				}
			})
		},

		getFullDate
	},

	created() {
		this.loading = true;
		Promise.all([
			!this.grades.length ? this.$store.dispatch('grades/fetchAll') : null,
			!this.regions.length ? this.$store.dispatch('regions/fetchAll') : null,
			!this.placementTests.length ? this.$store.dispatch('placementTests/fetchAll') : null
		]).finally(() => {
			this.loading = false;
		})
	},

	beforeDestroy() {
		this.$store.dispatch('statics/reset');
	},

	metaInfo: {
		title: 'التقارير والإحصائيات',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style>

</style>