<template>
    <div id="registerCard" class="bgPurple px-2 pb-16 px-sm-16 py-sm-5">
		<h1 class="text-h5 text-center purple--text darken-1--text">بطاقة تسجيل</h1>
		<v-row align="start" class="pt-4 px-10"> 
			<v-col>
				<p class="text-h6 purple--text darken-1--text mb-3">
					{{$route.query.centerName || 'مدارس نخبة حلب الخاصة'}}
				</p>
				<div class="d-flex">
					<div class="text-subtitle-2 me-8">
						<p class="purple--text darken-1--text mb-2">الاسم</p>
						<p class="purple--text darken-1--text mb-2">رقم الطالب </p>
						<p class="purple--text darken-1--text mb-2">الصف</p>
						<p class="purple--text darken-1--text mb-2">موعد السبر</p>
						<p class="purple--text darken-1--text mb-2">مدة السبر</p>
						<p class="purple--text darken-1--text mb-2">مكان السبر</p>
					</div>
					<div class="text-subtitle-2 flex-grow-1">
						<p class="mb-2">{{$route.query.name}}</p>
						<p class="mb-2">{{$route.query.no}}</p>
						<p class="mb-2">{{$route.query.grade}}</p>
						<p class="mb-2">{{moment($route.query.date)}}</p>
						<p class="mb-2">ساعتان</p>
						<p class="mb-2">{{$route.query.address}}</p>
					</div>
				</div>
			</v-col>
			<img class="logo-image" :src="$route.query.centerLogo || '/assets/elite-logo.png'" />
		</v-row>
        <hr class="my-5">
        <v-row class="px-10">
            <v-col class="text-subtitle-2">
                <p class="text-h6 purple--text darken-1--text mb-3">تعليمات</p>
				<div
					v-for="(rule, index) in rules"
					:key="index"
					class="d-flex mb-2"
				>
					<v-icon size="22" color="purple darken-1" class="me-5">mdi-check-bold</v-icon>
					{{rule}}
				</div>
				<div class="d-flex justify-end">
					الإدارة
				</div>
            </v-col>
        </v-row>

		<div class="d-flex justify-center text-subtitle-2 mt-6">
			نتمنى لكم دوام التفوق والنجاح.
		</div>

		<v-btn 
			color="lightGreen"
			dark
			fixed
			right
			bottom
			large
			@click="print"
			class="title rounded-medium font-weight-sm-bold print"
		>
			<v-icon large class="me-1">
				mdi-printer
			</v-icon>
			طباعة
		</v-btn>
    </div>
</template>

<script>
import moment from 'moment'
export default {
	name: 'RegisterCard',

	data() {
		return {
			rules: [
				'يعتبر الطالب منسحباً في حال التخلف عن تقديم السبر.',
				'لا يسمح بالدخول إلى السبر دون هذه البطاقة.',
				`السبر سيكون بالمواد التالية (${this.$route.query.exams}).`,
				'يرجى إحضار قلم أزرق ناشف (لا يسمح بالقلم الأزرق الحبري).',
				'يمكن الحصول على النوطة الخاصة بالسبر من أحد المكتبات المعتمدة.',
			]
		}
	},

	methods: {
		print() {
			window.print();
		},

		moment(date) {
			return moment(date).locale('ar').format('dddd DD-MM-YYYY الساعة hh:mm a')
		}
	},

	metaInfo() {
		return {
			title: this.$route.query.name
		}
	}
}
</script>

<style scoped lang="scss">
.fs {
	@media only screen and (max-width:600px) {
		font-size: 1.3rem;
	}
}
.d-none-xs {
	@media only screen and (max-width:400px) {
		display: none;
	}
}
.ii {
    text-align: -webkit-left;
}
.rounded-xxl {
	border-radius: 15px;
}
.bgPurple {
	background: radial-gradient(rgba(255, 255, 255, 0.737), #82258165);
	height: 100%;
}
.logo-image {
	max-width: 120px;
	position: absolute;
    left: 56px;
    top: 49px;
}

@media print {
	.print {
		display: none;
	}
	#registerCard {
		height: calc((29.7cm/2) - 10mm);
		
		* {
			line-height: 20px;
		}
	}
	.logo-image {
		max-width: 130px;
		left: 15pt;
		top: 15pt;
	}
	.bgPurple {
		background: none;
	} 
}
</style>