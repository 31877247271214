import * as types from '../mutation-types'
import store from '../index'

export default {
	namespaced: true,

	state: {
		authenticated: false,
		self: {
			name: null,
			username: null,
			userType: null,
		},
		isAdmin: false,
	},

	actions: {
		login({ commit }, { username, password }) {
			return axios.post('/users/authenticate', {
				username, password
			}).then(({ data }) => {
				const auth = {
					id: data.id,
					name: data.name,
					username: data.username,
					userType: data.userType,
					centerId: data.userType === 2 ? null : data.centerId,
					token: data.token,
				}

				localStorage.setItem('auth', JSON.stringify(auth));
				axios.defaults.headers.common.Authorization = `Bearer ${auth.token}`;
				commit(types.LOGIN, auth);
				store.dispatch('centers/changeCenterId', { id: auth.centerId });

				return data;
			})
		},

		logout({ commit }) {
			localStorage.removeItem('auth');
			axios.defaults.headers.common.Authorization = null;
			commit(types.LOGOUT)
		}
	},

	mutations: {
		[types.LOGIN](state, auth) {
			state.authenticated = true;
			state.self.name = auth.name;
			state.self.username = auth.username;
			state.self.userType = auth.userType;
			state.isAdmin = auth.userType === 2;
		},
		[types.LOGOUT](state, auth) {
			state.authenticated = false;
			state.self = {};
			state.isAdmin = false;
		},
	}
}