export const gendersIds = {
	male: 0,
	female: 1
};

export const gradeLevel = [
	{ id: 1, name: 'ابتدائي' },
	{ id: 2, name: 'إعدادي' },
	{ id: 3, name: 'ثانوي' }
];

export const genders = [
	{ id: gendersIds.male, name: 'ذكر', plural: 'ذكور' },
	{ id: gendersIds.female, name: 'أنثى', plural: 'إناث' },
];

export const userTypes = {
	dataManager: 0,
	accountant: 1,
	admin: 2
};

export const studentsStatus = {
	registered: 1,
	paid: 2,
	passed: 3,
	failed: 4,
	repeating: 5,
	withDraw: 6,
	retaken: 7,
}

export const studentStatusFilters = {
	all: 0,
	registered: 1,
	notCompleted: 2,
	completed: 3,
	withdrawn: 4,
	withdrawnAndRecovered: 5,
	failed: 6,
	passed: 7,
}

export const studentsStatusNames = [
	{ id: 1, name: 'مسجل' },
	{ id: 2, name: 'دفع فقط' },
	{ id: 3, name: 'ناجح' },
	{ id: 4, name: 'راسب' },
	{ id: 5, name: 'يعيد' },
	{ id: 6, name: 'منسحب' },
	{ id: 7, name: 'يعيد السبر' },
]

export const studentsDataStatus = {
	all: 0,
	registered: 1, 
	notCompleted: 2, 
	completed: 3, 
	withdrawn: 4, 
	withdrawnAndRecovered: 5, 
}

export const paymentTypes = {
	registration: 1,
	withDraw: 2,
	transportation: 3,
	primary: 4,
	other: 5,
}

export const paymentTypesNames = [
	{ id: 1, name: 'تسجيل' },
	{ id: 2, name: 'انسحاب' },
	{ id: 3, name: 'مواصلات' },
	{ id: 4, name: 'استكمال' },
	{ id: 5, name: 'إضافية' },
]

export const responsiblePersons = [
	{ id: 1, name: 'الأب' },
	{ id: 2, name: 'الأم' },
	{ id: 3, name: 'الأخ / الأخت' },
	{ id: 4, name: 'أحد الأقرباء' },
]

export const clothesSizes = [
	{ id: 1, name: 'S' },
	{ id: 2, name: 'M' },
	{ id: 3, name: 'L' },
	{ id: 4, name: 'XL' },
	{ id: 5, name: 'XXL' },
	{ id: 6, name: '3XL' },
	{ id: 7, name: '4XL' },
	{ id: 8, name: 'غير ذلك' },
]

export const winterClothTypes = [
	{ id: 1, name: 'طويل' },
	{ id: 2, name: 'قصير' },
]

export const summerClothTypes = [
	{ id: 1, name: 'كنزة نصف كم' },
	{ id: 2, name: 'كنزة كم طويل' },
	{ id: 3, name: 'قميص طويل' },
]

export const heardByItems = [
	{ id: 1, name: 'أخوة' },
	{ id: 2, name: 'أقرباء' },
	{ id: 3, name: 'زملاء' },
	{ id: 4, name: 'إعلان طرقي' },
	{ id: 5, name: 'إعلان إلكتروني' },
];

export const sort = {
	ascending: 1,
	descending: 2
}

export const cites = [
	'حلب',
	'حماة',
	'اللاذقية',
	'طرطوس',
	'ادلب',
	'دمشق',
	'ريف دمشق',
	'درعا',
	'دير الزور',
	'الحسكة',
	'حمص',
	'القنيطرة',
	'الرقة',
	'السويداء',
]