// this is validations rules for vuetify component validation
export default {
	required: [val => (val || '').length > 0 || 'الحقل مطلوب'],
	name: [val => (val || '').length > 0 || 'الحقل مطلوب'],
	number: [val => (val >= 0 && val !== null) || 'يجب إدخال رقم غير سالب'],
	requiredAsNumber: [val => (val >= 0 && val !== null && val !== '') || 'الحقل مطلوب'],
	requiredAsArray: [val => (Array.isArray(val) && val.length > 0) || 'الحقل مطلوب'],
	url: [
		val => {
			const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
			return regexp.test(val) || 'الرابط غير صالح'
		}
	],
	date: [
		val => {
			const date = /^(19|20)[0-9]{2}[-](1[0-2]|0[1-9])[-](0[1-9]|(1|2)[0-9]|3(0|1))$/;
			return date.test(val) || 'تاريخ غير صالح'
		}
	],
	password: [val => (val || '').length >= 4 || 'كلمة المرور على الأقل 4 رموز'],
	email: [
		val => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return pattern.test(val) || 'بريد ألكتروني غير صالح'
		}
	],
	
	file: [val => Boolean(val) || 'الحقل مطلوب']
}