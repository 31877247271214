<template>
    <div>
        <div id="edunix-logo">
			<v-img
				max-width="150"
				src="/assets/edunix-logo-text.png"
				eager
			/>
        </div>

		<div id="login-form" class="d-flex flex-column justify-center align-center">
			<div class="d-flex align-center justify-center">
				<v-img
					src="/assets/elite-logo.png"    
					max-width="200px"    
					eager
				/>
			</div>

			<v-container>
				<v-form ref="form" @submit.prevent="submit">
					<v-layout justify-center>
						<v-flex lg4 md6 sm8  xs12>
							<h3 class="text-center purple--text darken-1--text mt-3 mb-5">تسجيل الدخول</h3>
							
							<!-- username -->
							<v-text-field
								v-model="username"
								type="text"
								outlined
								dense
								class="rounded-lg mt-3"
								label="اسم المستخدم"
								:rules="rules.required"
							/>
							
							<!-- password -->
							<v-text-field
								v-model="password"
								:type="showPassword ? 'text' : 'password'"
								required
								outlined
								dense
								class="rounded-lg mb-1"
								label="كلمة المرور"
								:rules="rules.required"
								:append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
								@click:append="showPassword = !showPassword"
							/>
							<div class="d-flex justify-center">
								<v-btn
									type="submit"
									color="purple"
									class="white--text rounded-lg"
									width="50%"
									large
									:loading="loading"
									:disabled="loading"
								>
									دخول
								</v-btn>
							</div>

							<v-alert
								:value="error != null"
								v-if="!loading && error"
								type="error"
								dense
								class="rounded-lg mt-3"
								transition="scale-transition"
							>{{ error }}</v-alert>
						</v-flex>
					</v-layout>
				</v-form>
			</v-container>
		</div>
    </div>
</template>
<script>
import rules from '@/helpers/validation rules'
import { mapState } from 'vuex';
export default {
	name: 'Login',
	data () {
		return {
			loading: false,
			showPassword: false,
			error: null,
			rules,

			username: null,
			password: null,
		}
	},

	computed: {
		...mapState({
			isAdmin: state => state.auth.isAdmin
		})
	},

	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$store.dispatch('auth/login', {
					username: this.username,
					password: this.password
				}).then((data) => {
					if (!this.isAdmin) {
						axios.defaults.headers.common['x-center'] = data.centerId;
					}
					this.$router.replace({ name: 'home' });
				}).catch((e) => {
					this.error = e.response.data.message;
				}).finally(() => {
					this.loading = false;
				})
			}
		}
	},

	metaInfo: {
		title: 'تسجيل الدخول',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style scoped>
#edunix-logo {
	position: absolute;
	top: 1rem;
	left: 1.4rem;
}
#login-form {
	height: 95vh;
}
</style>
