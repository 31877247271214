import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import { userTypes } from '@/helpers/enums'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savePosition) {
		return savePosition || { x: 0, y: 0 }
	},
	routes
})

router.beforeEach((to, from, next) => {
	if (to.name !== 'login' && !store.state.auth.authenticated) {
		next({ name: 'login', replace: true })
	} else if (to.name === 'login' && store.state.auth.authenticated) {
		next({ name: 'home', replace: true })
	} else {
		if (store.state.auth.isAdmin) {
			if (to.name !== 'home' && !store.state.centers.currentCenterId) {
				next({ name: 'home', replace: true })
			} else {
				next();
			}
		} else {
			const auth = JSON.parse(localStorage.getItem('auth'));
			if (
				(['accounting', 'register-card'].includes(to.name) && auth.userType === userTypes.dataManager) ||
				(['students-data', 'placement-tests-lists'].includes(to.name) && auth.userType === userTypes.accountant) ||
				(['placement-test', 'placement-tests-results'].includes(to.name) && !store.state.auth.isAdmin)
			) {
				next({ name: 'home', replace: true })
			} else {
				next();
			}
		}
	}
})

export default router