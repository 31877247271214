<template>
	<v-navigation-drawer
		app
		permanent
		dark
		right
		color="primary"
		:mini-variant="value === true"
		:temporary="value === false"
		floating
		hide-overlay
	>
		<v-list nav dense>
			<v-list-item class="px-2">
				<v-list-item-icon>
					<v-icon @click="value = !value" >mdi-menu</v-icon>
				</v-list-item-icon>
			</v-list-item>
		</v-list>
		<v-list nav dense>
			<v-list-item
				v-for="(page, index) in pages.filter(c => c.show && !c.group)"
				:key="index"
				:to="{name: page.target}"
				exact-path
				class="my-2"
				@click="value = true"
			>
				<v-list-item-icon>
					<v-icon>{{page.icon}}</v-icon>
				</v-list-item-icon>
				<v-list-item-title>{{page.text}}</v-list-item-title>
			</v-list-item>

			<v-list-group active-class="white--text" v-if="isAdmin && currentCenterId">
				<template v-slot:activator>
					<v-list-item class="ma-0 px-0">
						<v-list-item-icon>
							<v-icon>mdi-cogs</v-icon>
						</v-list-item-icon>
						<v-list-item-title>الإعدادات</v-list-item-title>
					</v-list-item>
				</template>

				<v-list-item
					v-for="(page, index) in pages.filter(c => c.show && c.group === 'settings')"
					:key="index"
					:to="{name: page.target}"
					color="white"
					exact-path
					@click="value = true"
				>
					<v-list-item-icon>
						<v-icon>{{page.icon}}</v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{page.text}}</v-list-item-title>
				</v-list-item>
			</v-list-group>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
import { userTypes } from '@/helpers/enums'
import { mapState } from 'vuex'
export default {
	name: 'NavigationDrawer',

	data () {
		return {
			value: true,
		}
	},

	computed: {
		...mapState({
			isAdmin: state => state.auth.isAdmin,
			currentCenterId: state => state.centers.currentCenterId
		}),

		pages() {
			const auth = JSON.parse(localStorage.getItem('auth'))
			return [
				{
					icon: 'mdi-home',
					text: 'الصفحة الرئيسية',
					target: 'home',
					show: true
				},
				{
					icon: 'mdi-database-edit-outline',
					text: 'بيانات الطلاب',
					target: 'students-data',
					show: (this.isAdmin || auth.userType === userTypes.dataManager) && this.currentCenterId
				},
				{
					icon: 'mdi-cash-multiple',
					text: 'المحاسبة',
					target: 'accounting',
					show: (this.isAdmin || auth.userType === userTypes.accountant) && this.currentCenterId
				},
				{
					icon: 'mdi-clipboard-text-clock-outline',
					text: 'الامتحانات',
					target: 'placement-tests',
					show: this.isAdmin && this.currentCenterId
				},
				{
					icon: 'mdi-clipboard-text-multiple-outline',
					text: 'تحضير السبر',
					target: 'placement-tests-lists',
					show: (this.isAdmin || auth.userType === userTypes.dataManager) && this.currentCenterId
				},
				{
					icon: 'mdi-clipboard-check-multiple-outline',
					text: 'نتائج السبر',
					target: 'placement-tests-results',
					show: this.isAdmin && this.currentCenterId
				},
				{
					icon: 'mdi-finance',
					text: 'التقارير والإحصائيات',
					target: 'statistics',
					show: this.isAdmin && this.currentCenterId
				},
				/* {
					icon: 'mdi-notebook-edit',
					text: 'إدارة المواد',
					target: 'subjects',
					show: this.isAdmin && this.currentCenterId,
					group: 'settings'
				}, */
				{
					icon: 'mdi-book-open-page-variant',
					text: 'إدارة الصفوف',
					target: 'grade-management',
					show: this.isAdmin && this.currentCenterId,
					group: 'settings'
				},
				{
					icon: 'mdi-school',
					text: 'إدارة المدارس',
					target: 'schools',
					show: this.isAdmin && this.currentCenterId,
					group: 'settings'
				},
				{
					icon: 'mdi-map-marker-radius',
					text: 'إدارة المناطق',
					target: 'regions',
					show: this.isAdmin && this.currentCenterId,
					group: 'settings'
				},
			]
		}
	}
}
</script>
