import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ar from 'vuetify/lib/locale/ar'

Vue.use(Vuetify)

export default new Vuetify({
	rtl: true,
	theme: {
		themes: {
			light: {
				primary: '#822580', // #015931 #083866
				// bgColor: '#f3f7f8',
				secondary: '#a7a7a7', // #FFCDD2
				success: '#13a689',
				error: '#ff3334',
				errorLight: '#eeb4b3',
				warning: '#da8d09',
				lightBlue: '#0d4e9e',
				deepOrange: '#fc5d33',
				lightGreen: '#abc913',
				darkRed: '#cb1514',
				purple: '#822580',
				bgColor: '#f8f8f8'
			},
		},
	},

	lang: {
		locales: { ar },
		current: 'ar',
	},
})
