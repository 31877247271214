<template>
	<v-app>
		<navbar
			@change-drawer="drawer = !drawer"
			v-if="!['register-card', 'login'].includes($route.name)"
			class="rounded-tr-large ms-1"
		></navbar>
		<navigation-drawer
			v-model="drawer"
			v-if="!['register-card', 'login'].includes($route.name)"
		></navigation-drawer>
		
		<div class="primary" v-if="!['login', 'register-card'].includes($route.name)">
			<v-main class="bgColor ms-15 ps-0 rounded-r-large" style="min-height: 100vh"> 
				<v-container fluid class="py-2 px-sm-5">
					<router-view/>
				</v-container>
				<p class="text-center bgColor pe-2 fs-sm pb-2 mb-0 mx-8">
					v{{version}} - Powered by 
					<a href="http://www.beetronix.com" target="_blank" class="font-weight-bold black--text">
						Beetronix
					</a>
				</p>
			</v-main>
		</div>

		<!-- login & register card main -->
		<v-main v-else id="main" class="bgColor"> 
			<router-view/>
			<p v-if="$route.name === 'login'" class="text-center bgColor pe-2 fs-sm pb-2 mb-0 mx-8">
				v{{version}} - Powered by 
				<a href="http://www.beetronix.com" target="_blank" class="font-weight-bold black--text">
					Beetronix
				</a>
			</p>
		</v-main>

		<v-snackbar
			id="app-snackbar"
            v-model="snackbar"
            :color="isError ? 'red lighten-1' : 'primary lighten-1'"
			content-class="rounded-xl"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="snackbar = false"
				>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
	</v-app>
</template>

<script>
import NavigationDrawer from './components/NavigationDrawer.vue'
import Navbar from './components/Navbar.vue'
import { mapState } from 'vuex';
import { VERSION as version } from './constants';
export default {
	name: 'App',
	components: {
		NavigationDrawer,
		Navbar
	},

	data: () => ({
		drawer: true,

		snackbar: false,
		isError: false,
		message: null,

		version
	}),

	watch: {
		'$route.name'() {
			this.fetchCenters();
			this.$eventBus.$off('refresh');
			this.$store.dispatch('students/reset');
		}
	},

	computed: {
		...mapState({
			centers: state => state.centers.centers,
			currentCenterId: state => state.centers.currentCenterId,
		})
	},

	methods: {
		fetchCenters() {
			if (!['login', 'register-card'].includes(this.$route.name) && this.centers.length === 0) {
				this.$store.dispatch('centers/fetchAll');
			}
			if (this.currentCenterId) {
				this.$store.dispatch('placementTests/fetchAll');
				this.$store.dispatch('placementTests/fetchAllActive');
			}
		}
	},

	created() {
		this.$eventBus.$on('show-snackbar', (isError, message) => {
			this.isError = isError;
			this.message = message;
			this.snackbar = true;
		});

		this.fetchCenters();
	}
};
</script>
<style lang="scss">
@import './scss/main.scss';

a {
	text-decoration: none;
}
.fs-sm { 
	font-size: 0.75rem
}

.rounded {
	&-medium {
		border-radius: 13px !important;
	}
	&-tr-large {
		border-top-right-radius: 30px !important;
	}
	&-r-large {
		border-top-right-radius: 30px !important;
		border-bottom-right-radius: 30px !important;
	}
}

*::-webkit-scrollbar{
    height: 5px;
    width: 5px;
	background-color: #f8f8f8;
}
*::-webkit-scrollbar-thumb{
    background-color: #82258185;
    border-radius: 10px;
}

#app {
	.v-main__wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.v-menu__content {
		border-radius: 13px;
	}

	.v-data-footer__select .v-select {
		margin-top: 0;
		margin-bottom: 0;
	}

	.v-data-table {
		th {
			font-size: 11px !important;
		}
		td {
			font-size: 13px !important;
		}
	}

	.filter {
		.v-input {
			font-size: 13px;
			.v-label {
				font-size: 14px;
			}
		}
	}

	.v-picker--time .v-picker__body {
		height: 200px;
	}

	.text-small {
		font-size: 11px;
	}
}

#app-snackbar {
	.v-snack__wrapper {
		border-radius: 13px !important;
	}
}

@media print {
	#main {
		background-color: transparent !important;
	}
}
@page {
	size: A4;
	margin: 6mm;
}
</style>