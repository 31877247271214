<template>
	<div>
		<v-dialog v-model="dialog" max-width="400" content-class="rounded-xl">
			<v-form ref="form" @submit.prevent="submit" :disabled="submitLoading">
				<v-card rounded="xl">
					<v-card-title class="lightBlue--text">
						{{!itemId ? 'إضافة' : 'تعديل'}} مادة
					</v-card-title>
					<v-card-text class="pb-2 grey--text text--darken-3">
						<v-row dense>
							<!-- name -->
							<v-col sm="6" cols="12">
								<v-text-field
									v-model="subject.name"
									label="اسم المادة"
									dense
									outlined
									hide-details
									class="rounded-medium"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							
							<!-- mark -->
							<v-col sm="6" cols="12">
								<v-text-field
									v-model.number="subject.mark"
									label="العلامة العظمى"
									dense
									outlined
									type="number"
									hide-spin-buttons
									hide-details
									class="rounded-medium"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							color="primary white--text"
							type="submit"
							class="rounded-xl px-5"
							:loading="submitLoading"
						>
							حفظ
						</v-btn>
						<v-btn
							class="rounded-xl px-5"
							@click="dialog = false"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- delete -->
		<bee-delete-dialog
			v-model="deleteDialog"
			:ids="itemId"
			moduleType="subjects"
			@on-delete="fetchSubjects()"
		>
			هل أنت متأكد من <span class="red--text">حذف</span> المادة؟
		</bee-delete-dialog>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : subjects"
			:loading="loading"
			class="elevation-2 rounded-medium pt-2 px-2"
		>
			<!-- placement test -->
			<template v-slot:item.placementTestId="{ item }">
				{{getStudentPlacementTestFull(item.placementTestId) || '-'}}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<!-- edit -->
				<v-tooltip top>
					<template v-slot:activator="{ attrs, on }">
						<v-btn
							color="warning"
							class="ms-2 rounded-medium text-body-2 white--text"
							width="40"
							min-width="40"
							v-bind="attrs"
							v-on="on"
							@click="itemId = item.id; dialog = true"
						>
							<v-icon>mdi-square-edit-outline</v-icon>
						</v-btn>
					</template>
					تعديل
				</v-tooltip>

				<!-- delete -->
				<v-tooltip v-if="false" top>
					<template v-slot:activator="{ attrs, on }">
						<v-btn
							color="error lighten-1"
							class="ms-2 rounded-medium text-body-2 white--text"
							width="40"
							min-width="40"
							v-bind="attrs"
							v-on="on"
							@click="itemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</template>
					حذف
				</v-tooltip>
			</template>
		</v-data-table>

		<!-- add button -->
		<v-fab-transition v-if="false">
			<v-btn
				fab
				dark
				fixed
				left
				bottom
				color="primary"
				@click="dialog = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-fab-transition>
	</div>
</template>

<script>
import BeeDeleteDialog from '@/components/BeeDeleteDialog.vue'
import { mapGetters, mapState } from 'vuex';
import rules from '@/helpers/validation rules';
import moment from 'moment';
import { getFullDate } from '@/helpers/helpers';
import { genders } from '@/helpers/enums';

export default {
	name: 'Subjects',

	components: {
		BeeDeleteDialog
	},

	data() {
		return {
			loading: false,
			submitLoading: false,
			dialog: false,
			deleteDialog: false,
			
			itemId: null,

			rules,

			headers: [
				{
					text: 'اسم المادة',
					value: 'subject',
					width: 200,
					class: 'px-1',
					cellClass: 'text-body-2 px-1'
				},
				{
					text: 'العلامة',
					value: 'maxMark',
					width: 200,
					class: 'px-1',
					cellClass: 'text-body-2 px-1'
				},
				{
					text: 'الامتحان التابعة له',
					value: 'placementTestId',
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sortable: false,
				},
				{
					text: '',
					value: 'actions',
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sortable: false,
					width: 120
				},
			],

			// data
			subject: {
				name: null,
				mark: null,
				placementTestId: null,
			}
		}
	},

	watch: {
		dialog(newVal) {
			if (newVal) {
				if (this.itemId) {
					const subject = this.getSubjectById(this.itemId);
					this.subject.name = subject.subject;
					this.subject.mark = subject.maxMark;
					this.subject.placementTestId = subject.placementTestId;
				}
			} else {
				this.itemId = null;
				this.$refs.form.reset();
				this.subject.placementTestId = null;
			}
		}
	},

	computed: {
		...mapState({
			subjects: state => state.subjects.subjects,
			placementTests: state => state.placementTests.placementTests,
			grades: state => state.grades.grades,
		}),
		...mapGetters({
			getSubjectById: 'subjects/getSubjectById',
			getPlacementTestById: 'placementTests/getPlacementTestById',
			getGradeById: 'grades/getGradeById',
		})
	},

	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				this.$store.dispatch('subjects/update', {
					id: this.itemId,
					name: this.subject.name,
					maxMark: this.subject.mark,
					placementTestId: this.subject.placementTestId,
				}).then(() => {
					this.fetchSubjects();
					this.dialog = false;
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		fetchSubjects(isFirstLoad) {
			this.loading = true;
			this.$store.dispatch('subjects/fetchAll').then(() => {
				if (!isFirstLoad) this.loading = false;
			})
		},

		getStudentPlacementTestFull(id) {
			const placementTest = this.getPlacementTestById(id);
			
			if (!placementTest) { return null }

			const date = getFullDate(placementTest.testDate);

			const grade = this.getGradeById(placementTest.gradeId)?.name;
			const gender = genders.find(c => c.id === placementTest.studentsGender).plural;

			return `${grade} - ${gender} - ${date}`
		},

		// helpers
		moment
	},

	created() {
		this.loading = true;
		Promise.all([
			this.$store.dispatch('grades/fetchAll'),
			this.$store.dispatch('placementTests/fetchAll'),
			this.fetchSubjects()
		]).finally(() => {
			this.loading = false;
		})
	},

	metaInfo: {
		title: 'إدارة المواد',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style>

</style>