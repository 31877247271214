import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		grades: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/grades')
				.then(({ data }) => {
					commit(types.STORE_GRADES, data);
					return data;
				})
		},

		updateMinimumAverage({ commit }, { id, hasMinimumAverage, minimumAverage }) {
			return axios.put(`/grade/${id}`, { hasMinimumAverage, minimumAverage })
		},

		updatePlacementTestState({ commit }, { id, active }) {
			return axios.put(`/grade/${id}/hasPlacementTest`, null, { params: { hasPlacementTest: active } })
		},

		updatePayment({ commit }, { id, paymentTypes, amount }) {
			return axios.put(`/grade/${id}/payment`, { paymentTypes, amount })
		}
	},

	mutations: {
		[types.STORE_GRADES](state, grades) {
			state.grades = grades;
		}
	},

	getters: {
		getGradeById: state => id => state.grades.find(c => c.id === id)
	}
}