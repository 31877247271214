<template>
	<div>
		<!-- filter -->
		<v-form @submit.prevent="filter">
			<v-row dense align="center" class="mx-0 mb-0">
				<v-col lg="2" md="3" sm="4" cols="6">
					<v-text-field
						v-model="schoolNameFilter"
						label="اسم المدرسة"
						dense
						outlined
						hide-details
						class="rounded-medium"
					></v-text-field>
				</v-col>

				<!-- search button -->
				<v-col cols="auto" class="pa-1">
					<v-btn
						type="submit"
						color="primary"
						class="rounded-xl px-5"
						:disabled="loading"
					>بحث</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- dialog -->
		<v-dialog v-model="dialog" max-width="300" content-class="rounded-xl">
			<v-form ref="form" @submit.prevent="submit" :disabled="submitLoading">
				<v-card rounded="xl">
					<v-card-title class="lightBlue--text">
						{{!itemId ? 'إضافة' : 'تعديل'}} مدرسة
					</v-card-title>
					<v-card-text class="pb-2 grey--text text--darken-3">
						<v-row dense>
							<!-- name -->
							<v-col cols="12">
								<v-text-field
									v-model="schoolName"
									label="اسم المدرسة"
									dense
									outlined
									hide-details
									class="rounded-medium"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- notes -->
							<v-col cols="12">
								<v-text-field
									v-model="notes"
									label="ملاحظات"
									dense
									outlined
									hide-details
									class="rounded-medium"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							color="primary white--text"
							type="submit"
							class="rounded-xl px-5"
							:loading="submitLoading"
						>
							حفظ
						</v-btn>
						<v-btn
							class="rounded-xl px-5"
							@click="dialog = false"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- delete -->
		<bee-delete-dialog
			v-model="deleteDialog"
			:ids="itemId"
			moduleType="schools"
			@on-delete="filter(); itemId = null;"
		>
			هل أنت متأكد من <span class="red--text">حذف</span> المدرسة؟
		</bee-delete-dialog>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : schools"
			fixed-header
			dense
			:height="$vuetify.breakpoint.height - 220"
			:loading="loading"
			class="elevation-2 rounded-medium pt-2 px-2 mb-5"
		>
			<!-- notes -->
			<template v-slot:item.notes="{ item }">
				{{ item.notes | isAvailable | ellipsis}}	
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div style="padding: 2px 0">
					<!-- edit -->
					<v-tooltip top>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								color="warning"
								class="ms-2 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
								@click="itemId = item.id; dialog = true"
							>
								<v-icon>mdi-square-edit-outline</v-icon>
							</v-btn>
						</template>
						تعديل
					</v-tooltip>
					
					<!-- delete -->
					<v-tooltip top>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								color="error lighten-1"
								class="ms-2 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
								@click="itemId = item.id; deleteDialog = true"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</template>
						حذف
					</v-tooltip>
				</div>
			</template>
		</v-data-table>

		<!-- add button -->
		<v-fab-transition>
			<v-btn
				fab
				dark
				fixed
				left
				bottom
				color="primary"
				@click="dialog = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-fab-transition>
	</div>
</template>

<script>
import BeeDeleteDialog from '@/components/BeeDeleteDialog.vue'
import { mapGetters } from 'vuex';
import rules from '@/helpers/validation rules';

export default {
	name: 'Schools',

	components: {
		BeeDeleteDialog
	},

	data() {
		return {
			loading: false,
			submitLoading: false,
			dialog: false,
			deleteDialog: false,
			
			itemId: null,

			rules,

			headers: [
				{
					text: 'اسم المدرسة',
					value: 'name',
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					width: 200,
				},
				{
					text: 'ملاحظات',
					value: 'notes',
					class: 'px-1',
					cellClass: 'text-body-2 px-1'
				},
				{
					text: '',
					value: 'actions',
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sortable: false,
					width: 120
				},
			],

			// filter
			schoolNameFilter: null,
			schools: [],

			// data
			schoolName: null,
			notes: null,
		}
	},

	watch: {
		dialog(newVal) {
			if (newVal) {
				if (this.itemId) {
					const school = this.getSchoolById(this.itemId);
					this.schoolName = school.name;
					this.notes = school.notes;
				}
			} else {
				this.$refs.form.reset();
				this.itemId = null;
			}
		}
	},

	computed: {
		...mapGetters({
			getSchoolById: 'schools/getSchoolById'
		})
	},

	methods: {
		filter() {
			this.loading = true;
			this.$store.dispatch('schools/fetchAllByFilter', {
				schoolName: this.schoolNameFilter
			}).then((data) => {
				this.schools = data;
			}).finally(() => {
				this.loading = false;
			})
		},

		submit() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				const actionName = this.itemId ? 'update' : 'create';
				this.$store.dispatch(`schools/${actionName}`, {
					id: this.itemId,
					name: this.schoolName,
					notes: this.notes,
				}).then(() => {
					this.dialog = false;
					this.filter();
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		}
	},

	created() {
		this.filter();
	},

	metaInfo: {
		title: 'إدارة المدارس',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style>

</style>