<template>
	<v-container
		class="d-flex align-center justify-center"
		:style="{ height: ($vuetify.breakpoint.height - 160) + 'px'}"
	>
		<v-row>
			<v-col cols="12" >
				<v-img 
					:src="centerLogo ? BASE_API_URL + '/' + centerLogo : '/assets/elite-logo.png'"
					:width="$vuetify.breakpoint.mdAndDown ? '200' : '290'"
					max-height="230"
					contain
					transition="scale-transition"
					lazy-src="/assets/placeholder-image.png"
					eager
					class="mx-auto"
				/>

				<v-progress-linear
					v-if="!isAdmin && centers.length === 0"
					indeterminate
					height="6"
					class="rounded-xl mx-auto"
					style="width: 100px"
				></v-progress-linear>
				<h2 v-else class="text-center my-4 purple--text">
					{{centerName}}
				</h2>
			</v-col>
			<div class="text-center w-100">
				<v-row v-if="false && isAdmin" class="justify-center ma-0">
					<v-col sm="3" cols="12">
						<v-select
							v-model="centerId"
							:items="centers"
							item-text="name"
							item-value="id"
							label="المركز"
							outlined
							hide-details
							dense
							:loading="centers.length === 0"
							no-data-text="لا يوجد بيانات"
							class="rounded-medium grey--text"
						></v-select>
					</v-col>
				</v-row>
				<v-container v-if="!isAdmin || (isAdmin && centerId)">
					<v-btn
						v-if="auth.userType !== userTypes.accountant"
						elevation="0" 
						dark 
						color="purple"
						:to="{name:'students-data'}"
						height="70px"
						width="130px"
						:class="{ 'rounded-l-0 rounded-r-lg': isAdmin }"
					>
						بيانات الطلاب
					</v-btn>
					<v-btn
						v-if="auth.userType !== userTypes.dataManager"
						elevation="0" 
						dark 
						color="purple"
						:to="{name:'accounting'}"
						height="70px"
						width="130px"
						:class="{ 'rounded-r-0 rounded-l-lg': isAdmin }"
					>
						المدفوعات
					</v-btn>
				</v-container>
			</div>
		</v-row>
	</v-container>
</template>
<script>
import { userTypes } from '@/helpers/enums'
import { mapGetters, mapState } from 'vuex'
import { BASE_API_URL } from '@/constants'
export default {
	name: 'Home',

	data() {
		return {
			loading: false,
			centerId: null,

			userTypes,
			auth: JSON.parse(localStorage.getItem('auth')),
			BASE_API_URL
		}
	},

	watch: {
		centerId(val) {
			this.$store.dispatch('centers/changeCenterId', { id: val })
			axios.defaults.headers.common['x-center'] = val;
		},

		'centers.length'(val) {
			if (val) this.centerId = this.centers[this.centers.length - 1].id;
		}
	},

	computed: {
		...mapState({
			isAdmin: state => state.auth.isAdmin,
			currentCenterId: state => state.centers.currentCenterId,
			centers: state => state.centers.centers,
		}),

		...mapGetters({
			getCenterById: 'centers/getCenterById'
		}),

		centerName() {
			const defaultName = 'أسرة النخبة التعليمية';
			if (this.isAdmin && this.$route.name === 'home') {
				return defaultName;
			} else {
				return this.getCenterById(this.currentCenterId)?.name ?? defaultName;
			}
		},

		centerLogo () {
			return this.getCenterById(this.currentCenterId)?.logo ?? null
		}
	},

	created() {
		if (this.isAdmin) {
			this.$store.dispatch('centers/changeCenterId', { id: null })
			axios.defaults.headers.common['x-center'] = null;
			
			if (this.centers.length) this.centerId = this.centers[this.centers.length - 1].id;
		}
	},

	metaInfo: {
		title: 'أسرة النخبة التعليمية'
	}
}
</script>
<style scoped>
.w-100 {
	width: 100%;
}
</style>
