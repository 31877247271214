import { EDUNIX_API } from '@/constants';
import md5 from 'md5';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		students: [],
		previousSchools: [],
		statistics: {
			studentsCount: 0,
			totalPaid: 0,
			studentsSucceededExemptions: 0,
			studentsFailed: 0,
			studentsSucceeded: 0,
			studentsWithdrawn: 0,
			studentsCompleted: 0,
			totalStudentsWithdrawn: 0,
			totalNukhbaStudents: 0,
			totalWithTransportation: 0,
		},
		studentsStatics: {
			totalCount: 0,
			registeredCount: 0,
			nukbaStudentsCount: 0,
			completedStudentsCount: 0,
			nukhbaCompletedStudentsCount: 0,
			studentsWithTransportCount: 0,
			recoveredStudentsCount: 0,
			withdrawingStudentsCount: 0
		}
	},

	actions: {
		fetchAll({ commit }, { name, gradeId, gradeLevel, studentNo, gender, startDate, endDate, placementTestId, studentStatus, previousSchool, page, perPage, orderByName, orderByNinthGradeTotal, orderByMarks, orderByGrade }) {
			return axios.get('/students', {
				params: { name, gradeId, gradeLevel, studentNo, gender, startDate, endDate, placementTestId, studentStatus, previousSchool, page, perPage, orderByName, orderByNinthGradeTotal, orderByMarks, orderByGrade }
			})
				.then(({ data }) => {
					commit(types.STORE_STUDENTS, data)
					return data;
				})
		},

		fetchAllRegistered({ commit }, { name, gradeId, gradeLevel, studentNo, gender, startDate, endDate, placementTestId, studentPlacementState, onlyWithPassExemption, onlyCompletedStudents, studentStatus, previousSchool, withMarks, withoutPassExemptionStudents, withoutNukhbaStudents, page, perPage, orderByName, orderByNinthGradeTotal, orderByMarks, orderByGrade }) {
			return axios.get('/studentsPlacementTest', {
				params: { name, gradeId, gradeLevel, studentNo, gender, startDate, endDate, placementTestId, studentPlacementState, onlyWithPassExemption, onlyCompletedStudents, studentStatus, previousSchool, withMarks, withoutPassExemptionStudents, withoutNukhbaStudents, page, perPage, orderByName, orderByNinthGradeTotal, orderByMarks, orderByGrade }
			})
				.then(({ data }) => {
					commit(types.STORE_STUDENTS, data)
					return data;
				})
		},

		fetchAllForTestList({ commit }, { placementTestId, withoutPassExemptionStudents, withoutNukhbaStudents }) {
			return axios.get(`/studentsPlacementTest/${placementTestId}/getStudentsForMarks`, { params: { withoutPassExemptionStudents, withoutNukhbaStudents } })
				.then(({ data }) => {
					commit(types.STORE_STUDENTS, data)
					return data;
				})
		},

		fetchById({ commit }, { id }) {
			return axios.get(`/students/${id}`).then(({ data }) => data)
		},

		fetchByEdunixCode({ commit }, { code }) {
			return axios.get(`${EDUNIX_API.baseURL}/students/${EDUNIX_API.username}/get-student-details`, {
				params: { EdunixCode: code },
				headers: { 'x-password': md5(EDUNIX_API.password) }
			}).then(({ data }) => data)
		},

		fetchStatics({ commit }, { studentNo, name, gradeLevel, gradeId, gender, previousSchool, startDate, endDate, placementTestId }) {
			return axios.get('/students/statics', {
				params: { number: studentNo, name, gradeEducationalLevel: gradeLevel, gradeId, gender, school: previousSchool, from: startDate, to: endDate, placementTestId }
			}).then(({ data }) => {
				commit(types.STORE_STUDENTS_STATICS, data);
				return data;
			})
		},
		
		create({ commit }, { firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothType, winterClothSize, summerClothType, summerClothSize, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber, notes, edunixCode }) {
			return axios.post('/students', {
				firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothType, winterClothSize, summerClothType, summerClothSize, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber, notes, edunixCode
			}).then(({ data }) => data);
		},

		createAndRegister({ commit }, { firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothSize, summerClothSize, winterClothType, summerClothType, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber, notes, isNokhbaStudent, edunixCode }) {
			return axios.post('/studentsPlacementTest/student/register', {
				firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothSize, summerClothSize, winterClothType, summerClothType, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber, notes, isNokhbaStudent, edunixCode
			})
		},

		update({ commit }, { isUpdateComplete, id, firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothType, winterClothSize, summerClothType, summerClothSize, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber, notes }) {
			const payload = { id, firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, notes }

			if (isUpdateComplete) {
				Object.assign(payload, { fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothType, winterClothSize, summerClothType, summerClothSize, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber })
			}

			return axios.put(`/students/${id}`, payload);
		},

		delete({ commit }, { id }) {
			return axios.delete(`/students/${id}`);
		},

		// exemption
		fetchExemption({ commit }, { studentId }) {
			return axios.get(`/Students/${studentId}/Exemption`).then(({ data }) => data)
		},

		createExemption({ commit }, { studentId, description, testPass, testRetake, unStrictPayment, unStrictCompletionPayment, unStrictGradeTotal }) {
			return axios.post(`/students/${studentId}/exemption`, {
				studentId, description, testPass, testRetake, unStrictPayment, unStrictCompletionPayment, unStrictGradeTotal
			})
		},

		deleteExemption({ commit }, { studentId }) {
			return axios.delete(`/Students/${studentId}/Exemption`)
		},

		// PreviousSchools
		fetchPreviousSchools({ commit }) {
			return axios.get('/students/previousSchools').then(({ data }) => {
				commit(types.STORE_PREVIOUS_SCHOOLS, data);
				return data;
			});
		},

		// actions
		retakeExam({ commit }, { id, newPlacementTestId }) {
			return axios.post(`/studentsPlacementTest/student/${id}/retake`, { newPlacementTestId })
		},

		complete({ commit }, { id, firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, isNokhbaStudent, fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothType, winterClothSize, summerClothType, summerClothSize, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber, notes }) {
			return axios.post(`/studentsPlacementTest/student/${id}/complete`, { firstName, lastName, fatherName, motherName, gender, phoneNumber, mobileNumber, parentsMobileNumber, gradeId, previousSchool, ninthGradeTotal, regionId, street, createdAt, isNokhbaStudent, fathersJob, mothersJob, fathersPhoneNumber, mothersPhoneNumber, responsableForEducation, responsableForEducationPhone, withTransportation, hasPreviousRelations, previousRelationName, previousRelationGrade, previousRelationCount, winterClothType, winterClothSize, summerClothType, summerClothSize, birthDate, birthLocation, city, whatsAppPhoneNumber, telegramPhoneNumber, notes })
		},

		withDraw({ commit }, { id }) {
			return axios.post(`/studentsPlacementTest/student/${id}/withDraw`)
		},
		
		register({ commit }, { id }) {
			return axios.post(`/studentsPlacementTest/student/${id}/register`)
		},
		
		brief({ commit }, { id }) {
			return axios.get(`/students/${id}/view`).then(({ data }) => data)
		},

		// Nokhba
		setAsNokba({ commit }, { id }) {
			return axios.post(`students/${id}/setNokhba`)
		},
		removeAsNokba({ commit }, { id }) {
			return axios.post(`students/${id}/unsetNokhba`)
		},

		// reset
		reset({ commit }) { commit('resetStates') }
	},

	mutations: {
		[types.STORE_STUDENTS](state, students) {
			state.students = students.students;
			/*
			state.statistics.studentsCount = students.totalCount;
			state.statistics.totalPaid = students.totalPaid;
			state.statistics.studentsSucceededExemptions = students.successExemptionsCount;
			state.statistics.studentsFailed = students.totalFailed;
			state.statistics.studentsSucceeded = students.totalSuccess;
			
			state.statistics.studentsWithdrawn = students.totalWithdrawn;
			state.statistics.studentsCompleted = students.totalCompletedRegistration;
			state.statistics.totalStudentsWithdrawn = students.totalFailedAndWithdrawn;
			state.statistics.totalNukhbaStudents = students.totalNukhbaStudents;
			state.statistics.totalWithTransportation = students.totalWithTransportation;
			*/
		},

		[types.STORE_STUDENTS_STATICS](state, statics) {
			state.studentsStatics.totalCount = statics.totalCount;
			state.studentsStatics.registeredCount = statics.registeredCount;
			state.studentsStatics.nukbaStudentsCount = statics.nukbaStudentsCount;
			state.studentsStatics.completedStudentsCount = statics.completedStudentsCount;
			state.studentsStatics.nukhbaCompletedStudentsCount = statics.nukhbaCompletedStudentsCount;
			state.studentsStatics.studentsWithTransportCount = statics.studentsWithTransportCount;
			state.studentsStatics.recoveredStudentsCount = statics.recoveredStudentsCount;
			state.studentsStatics.withdrawingStudentsCount = statics.withdrawingStudentsCount;
		},

		[types.STORE_PREVIOUS_SCHOOLS](state, previousSchools) {
			state.previousSchools = previousSchools;
		},

		resetStates(state) {
			state.students = [];
			state.statistics.studentsCount = 0;
			state.statistics.totalPaid = 0;
			state.statistics.studentsSucceededExemptions = 0;
			state.statistics.studentsFailed = 0;
			state.statistics.studentsSucceeded = 0;
			state.statistics.studentsWithdrawn = 0;
			state.statistics.studentsCompleted = 0;
			state.statistics.totalStudentsWithdrawn = 0;
			state.statistics.totalNukhbaStudents = 0;
			state.statistics.totalWithTransportation = 0;
		}
	},

	getters: {
		getStudentById: state => id => state.students.find(c => c.id === id)
	}
}