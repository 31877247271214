import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		currentCenterId: null,
		centers: []
	},

	actions: {
		fetchAll ({ commit }) {
			axios.get('/centers').then(({ data }) => {
				commit(types.STORE_CENTERS, data);
				return data;
			})
		},

		changeCenterId({ commit }, { id }) {
			const auth = JSON.parse(localStorage.auth);
			auth.centerId = id;
			localStorage.setItem('auth', JSON.stringify(auth));

			commit(types.STORE_CURRENT_CENTER_ID, id);
		}
	},

	mutations: {
		[types.STORE_CENTERS](state, centers) {
			state.centers = centers;
		},
		[types.STORE_CURRENT_CENTER_ID](state, centerId) {
			state.currentCenterId = centerId;
		}
	},

	getters: {
		getCenterById: state => id => state.centers.find(c => c.id === id)
	}
}