import moment from 'moment';
import { sort } from './enums';

export function getQueryUrl(baseUrl, params, isDownLoad) {
	let url = baseUrl;
	const paramsItems = params;
	Object.keys(paramsItems).forEach((key, index) => {
		if (paramsItems[key] || paramsItems[key] === 0 || paramsItems[key] === false) {
			if (index === 0) {
				url += `?${key}=` + paramsItems[key];
			} else {
				if (Array.isArray(paramsItems[key])) {
					paramsItems[key].forEach(item => {
						url += `&${key}=` + item;
					})
				} else {
					url += `&${key}=` + paramsItems[key];
				}
			}
		}
	});
	console.log(url);
	if (isDownLoad) {
		const a = document.createElement('a');
		a.href = url;
		a.download = true;
		a.click();
		a.remove();
	} else {
		return url
	}
}

export function getFullDate(date) {
	return moment(date).format('YYYY-MM-DD الساعة hh:mm a')
		.replace(/pm|am/g, (matched) => ({ pm: 'م', am: 'ص' })[matched])
}

export function getSortData({ sortBy, sortDesc }, headerValues = {
	name: 'name',
	grade: 'grade',
	ninthGrade: 'ninthGrade',
	marks: 'marks',
	state: 'state'
}) {
	if (!sortBy || !sortBy.length) {
		return {
			name: null,
			grade: null,
			ninthGrade: null,
			marks: null
		}
	} else {
		const keys = Object.keys(headerValues).map(key => headerValues[key]);
		if (keys.includes(sortBy[0])) {
			return {
				name:
					headerValues.name === sortBy[0]
						? (!sortDesc[0] ? sort.ascending : sort.descending)
						: null,
				grade:
					headerValues.grade === sortBy[0]
						? (!sortDesc[0] ? sort.ascending : sort.descending)
						: null,
				ninthGrade:
					headerValues.ninthGrade === sortBy[0]
						? (!sortDesc[0] ? sort.ascending : sort.descending)
						: null,
				marks:
					headerValues.marks === sortBy[0]
						? (!sortDesc[0] ? sort.ascending : sort.descending)
						: null,
				state:
					headerValues.state === sortBy[0]
						? (!sortDesc[0] ? sort.ascending : sort.descending)
						: null,
			}
		}
	}
}