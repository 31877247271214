<template>
	<div>
		<!-- filter -->
		<v-form class="filter" @submit.prevent="options.page = 1; filterData(true); fetchStatics();">
			<v-row class="align-center ma-0 mt-n2">
				<!-- student no -->
				<v-col cols="12" sm="6" md="3" lg="1" class="pa-1">
					<v-text-field
						v-model="filter.studentNo"
						label="الرقم"
						outlined
						hide-details
						dense
						class="rounded-medium grey--text"
					></v-text-field>
				</v-col>

				<!-- student name -->
				<v-col cols="12" sm="6" md="3" lg="2" class="pa-1">
					<v-text-field 
						v-model="filter.studentName"
						hide-details
						class="grey--text rounded-medium grey--text"
						label="الاسم"
						dense
						outlined
					></v-text-field>
				</v-col>

				<!-- grade level -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.gradeLevelId"
						:items="gradeLevel"
						item-text="name"
						item-value="id"
						label="المرحلة"
						outlined
						hide-details
						dense
						auto-select-first
						append-icon
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- grade -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.gradeId"
						:items="grades"
						item-text="name"
						item-value="id"
						label="الصف"
						outlined
						hide-details
						dense
						auto-select-first
						append-icon
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- gender -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.genderId"
						:items="genders"
						item-text="name"
						item-value="id"
						label="الجنس"
						auto-select-first
						outlined
						hide-details
						dense
						append-icon
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- previous school -->
				<v-col cols="6" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.previousSchool"
						:items="schools"
						item-text="name"
						item-value="name"
						label="المدرسة"
						auto-select-first
						outlined
						hide-details
						dense
						append-icon
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- dates -->
				<v-col cols="12" sm="6" md="3" class="d-flex align-center pa-1">
					<!-- from date -->
					<v-menu
						ref="fromDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="filter.fromDate"
								outlined
								hide-details
								dense
								class="rounded-medium me-2"
								prepend-inner-icon="mdi-calendar"
								readonly
								clearable
								v-bind="attrs"
								v-on="on"
								label="من تاريخ"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="filter.fromDate"
							prev-month-aria-label='$vuetify.datePicker.prevMonthAriaLabel'
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1950-01-01"
							@change="$refs.fromDateMenu.save($event)"
						></v-date-picker>
					</v-menu>

					<!-- to date -->
					<v-menu
						ref="toDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template  v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="filter.toDate"
								hide-details
								outlined
								dense
								class="rounded-medium"
								prepend-inner-icon="mdi-calendar"
								readonly
								clearable
								v-bind="attrs"
								v-on="on"
								label="إلى تاريخ"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="filter.toDate"
							prev-month-aria-label='$vuetify.datePicker.prevMonthAriaLabel'
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1950-01-01"
							@change="$refs.toDateMenu.save($event)"
						></v-date-picker>
					</v-menu>
				</v-col>

				<!-- placement test -->
				<v-col cols="12" sm="6" md="3" lg="1" class="pa-1">
					<v-autocomplete
						v-model="filter.placementTestId"
						:items="placementTests"
						item-value="id"
						:item-text="(item) => getStudentPlacementTestFull(item)"
						hide-details
						outlined
						dense
						class="rounded-medium"
						append-icon
						label="الامتحان"
					></v-autocomplete>
				</v-col>

				<v-spacer/>

				<!-- search button -->
				<v-col cols="auto" class="pa-1">
					<v-btn
						type="submit"
						color="primary"
						class="rounded-xl px-5"
						key="search-btn"
						:disabled="loading"
					>بحث</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- select type dialog -->
		<v-dialog
			v-model="selectAddTypeDialog"
			max-width="380"
			content-class="rounded-xl"
			:persistent="edunixStudent.loading"
		>
			<v-card class="py-2 px-4">
				<v-card-title class="lightBlue--text pb-4">
					نوع الإضافة
				</v-card-title>
				<v-card-text>
					<div class="text-center">
						<v-btn
							depressed
							:height="!edunixStudent.show && $vuetify.breakpoint.smAndUp ? 60 : 30"
							color="primary"
							class="rounded-xl mb-sm-0 mb-1"
							:class="{ 'rounded-l-0': $vuetify.breakpoint.smAndUp }"
							@click="
								edunixStudent.show = false;
								selectAddTypeDialog = false;
								addEditCompleteDialog = true;
							"
						>طالب جديد</v-btn>
						<v-btn
							depressed
							:height="!edunixStudent.show && $vuetify.breakpoint.smAndUp ? 60 : 30"
							color="primary"
							class="rounded-xl mb-sm-0 mb-1"
							:class="{ 'rounded-r-0': $vuetify.breakpoint.smAndUp }"
							
							@click="edunixStudent.show = true; $nextTick(() => $refs.codeInput.focus())"
						>طالب من Edunix</v-btn>
					</div>

					<!-- student edunix code -->
					<v-expand-transition>
						<v-form
							v-if="edunixStudent.show"
							class="d-flex align-center mt-4"
							@submit.prevent="fetchEdunixStudentData"
						>
							<v-text-field
								v-model.trim="edunixStudent.code"
								ref="codeInput"
								label="كود الطالب في Edunix"
								outlined
								hide-spin-buttons
								dense
								hide-details
								class="rounded-xl"
								:readonly="edunixStudent.loading"
								:rules="rules.required"
							></v-text-field>
							<v-btn
								type="submit"
								color="lightGreen white--text"
								class="rounded-xl ms-3"
								:disabled="!edunixStudent.code"
								:loading="edunixStudent.loading"
							>بحث</v-btn>
						</v-form>
					</v-expand-transition>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- student brief dialog -->
		<v-dialog
			v-model="studentBriefDialog"
			:max-width="1000"
			content-class="rounded-xl"
		>
			<v-card class="pa-4">
				<v-card-title class="lightBlue--text">
					بيانات الطالب

					<v-tooltip v-if="studentBriefData.isNukbahStudent" top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								color="primary"
								size="22"
								class="ms-2"
								v-bind="attrs"
								v-on="on"
							>mdi-star-circle</v-icon>
						</template>
						طالب نخبة
					</v-tooltip>

					<v-progress-circular
						v-if="studentBriefLoading"
						size="18"
						width="3"
						indeterminate
						class="ms-3"
					/>
				</v-card-title>
				<v-card-text class="pb-0 grey--text text--darken-3">
					<!-- basic data -->
					<p class="text-body-2 mb-4 primary--text">المعلومات الأساسية</p>
					<v-row class="mb-3">
						<!-- fullname -->
						<v-col md="4" sm="6" cols="12" class="pa-2">
							<span class="text-caption lightGreen--text text--darken-3 me-1">
								الاسم الثلاثي:
							</span>
							{{
								studentBriefData.firstName
								? `${studentBriefData.firstName} ${studentBriefData.fatherName} ${studentBriefData.lastName}`
								: '-'
							}}
						</v-col>

						<!-- gender -->
						<v-col md="2" sm="6" cols="12" class="pa-2">
							<span class="text-caption lightGreen--text text--darken-3 me-1">
								الجنس:
							</span>
							{{
								studentBriefData.gender >= 0
								? genders.find(c => c.id === studentBriefData.gender).name
								: '-'
							}}
						</v-col>

						<!-- grade -->
						<v-col md="2" sm="6" cols="12" class="pa-2">
							<span class="text-caption lightGreen--text text--darken-3 me-1">
								الصف:
							</span>
							{{
								studentBriefData.gradeId
									? grades.find(c => c.id === studentBriefData.gradeId).name
									: '-'
							}}
						</v-col>

						<!-- student state -->
						<v-col v-if="studentBriefData.state > 0" md="4" sm="6" cols="12" class="pa-2">
							<span class="text-caption lightGreen--text text--darken-3 me-1">
								حالة الطالب:
							</span>
							{{
								studentsStatusNames.find(c => c.id === studentBriefData.state).name
								+ ' ' + getStudentStateBrief()
							}}
						</v-col>
						
						<!-- registration date -->
						<v-col md="4" sm="6" cols="12" class="pa-2">
							<span class="text-caption lightGreen--text text--darken-3 me-1">
								تاريخ التسجيل:
							</span>
							{{studentBriefData.registrationDate ? getFullDate(studentBriefData.registrationDate): '-'}}
						</v-col>

						<!-- test date -->
						<template v-if="!isGradeHasNoPlacementTest(studentBriefData.gradeId)">
							<v-col
								v-if="!studentBriefData.isNukbahStudent"
								md="4"
								sm="6"
								cols="12"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									تاريخ الامتحان:
								</span>
								{{studentBriefData.testDate ? getFullDate(studentBriefData.testDate) : '-'}}
							</v-col>
							
							<!-- ninth grade total -->
							<v-col  md="3" sm="6" cols="12" class="pa-2">
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									مجموع التاسع:
								</span>
								{{studentBriefData.ninthGradeTotal || '-'}}
							</v-col>
						</template>
					</v-row>

					<!-- exception data -->
					<template v-if="studentBriefData.exemptions">
						<p class="text-body-2 mb-4 primary--text">الاستثناءات</p>
						<v-row class="mb-3">
							<!-- testPass -->
							<v-col
								v-if="studentBriefData.exemptions.testPass"
								md="2"
								sm="6"
								cols="12"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									تجاوز السبر
								</span>
								<v-icon size="20">mdi-checkbox-marked-circle-outline</v-icon>
							</v-col>
						
							<!-- testRetake -->
							<v-col
								v-if="studentBriefData.exemptions.testRetake"
								md="2"
								sm="6"
								cols="12"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									إعادة السبر
								</span>
								<v-icon size="20">mdi-checkbox-marked-circle-outline</v-icon>
							</v-col>
						
							<!-- unStrictPayment -->
							<v-col
								v-if="studentBriefData.exemptions.unStrictPayment"
								md="3"
								sm="6"
								cols="12"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									تعديل دفعة التسجيل
								</span>
								<v-icon size="20">mdi-checkbox-marked-circle-outline</v-icon>
							</v-col>
						
							<!-- unStrictCompletionPayment -->
							<v-col
								v-if="studentBriefData.exemptions.unStrictCompletionPayment"
								md="4"
								sm="6"
								cols="12"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									تعديل دفعة الاستكمال
								</span>
								<v-icon size="20">mdi-checkbox-marked-circle-outline</v-icon>
							</v-col>
						
							<!-- unStrictGradeTotal -->
							<v-col
								v-if="studentBriefData.exemptions.unStrictGradeTotal"
								md="4"
								sm="6"
								cols="12"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									تجاوز مجموع التاسع
								</span>
								<v-icon size="20">mdi-checkbox-marked-circle-outline</v-icon>
							</v-col>

							<!-- notes -->
							<v-col
								sm="auto"
								cols="12"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									الشرح:
								</span>
								{{studentBriefData.exemptions.description || '-'}}
							</v-col>
						</v-row>
					</template>

					<!-- marks -->
					<template v-if="studentBriefData.studentMarks">
						<p class="text-body-2 mb-4 primary--text">العلامات</p>
						<v-row class="mb-3">
							<!-- mark -->
							<v-col
								v-for="(item, index) in studentBriefData.studentMarks"
								:key="index"
								md="2"
								sm="3"
								cols="4"
								class="pa-2"
							>
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									{{item.subject}}:
								</span>
								{{ item.mark }}
							</v-col>

							<v-col md="2" sm="3" cols="4" class="pa-2">
								<span class="text-caption lightGreen--text text--darken-3 me-1">
									المجموع:
								</span>
								{{ studentBriefData.studentMarks.map(c => c.mark).reduce((a, b) => a + b, 0) }}
							</v-col>
						</v-row>
					</template>

					<!-- payments -->
					<p class="text-body-2 mb-4 primary--text">الدفعات</p>
					<v-data-table
						:headers="paymentsHeaders"
						:items="studentBriefLoading ? [] : studentBriefData.payments"
						:items-per-page="-1"
						dense
						hide-default-footer
						disable-pagination
						disable-sort
						:loading="studentBriefLoading"
						no-data-text="لا يوجد دفعات"
						class="mb-3"
					>
						<!-- payment type -->
						<template v-slot:item.paymentType="{ item }">
							{{paymentTypesNames.find(c => c.id === item.paymentType).name}}
						</template>
						
						<!-- amount -->
						<template v-slot:item.amount="{ item }">
							{{item.amount ? item.amount : '-'}}
						</template>

						<!-- isDue -->
						<template v-slot:item.isDue="{ item }">
							<v-icon v-if="!item.isDue" size="22">mdi-check-circle-outline</v-icon>
							<span v-else>-</span>
						</template>
						
						<!-- updatedAt -->
						<template v-slot:item.date="{ item }">
							{{
								item.updatedAt
									? 'دفع في ' + moment(item.updatedAt).format('YYYY-MM-DD')
									: item.createdAt
										? 'مستحقة من ' + moment(item.createdAt).format('YYYY-MM-DD')
										: '-'
							}}
						</template>
						
						<!-- receiptNo -->
						<template v-slot:item.receiptNo="{ item }">
							{{item.receiptNo ? item.receiptNo : '-'}}
						</template>

						<!-- noteNo -->
						<template v-slot:item.noteNo="{ item }">
							{{item.noteNo ? item.noteNo : '-'}}
						</template>

						<!-- receipt received -->
						<template v-slot:item.hasReceiptBeenReceived="{ item }">
							{{item.hasReceiptBeenReceived ? 'تم الاستلام' : '-'}}
						</template>

						<!-- note -->
						<template v-slot:item.note="{ item }">
							{{item.note ? item.note : '-'}}
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="lightGreen white--text"
						type="submit"
						class="rounded-xl px-5"
						@click="studentBriefDialog = false"
					>
						موافق
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- add/edit/complete dialog -->
		<v-dialog
			v-model="addEditCompleteDialog"
			:width="isComplete || isFailed || skipRegistration ? 850 : 600"
			content-class="rounded-xl"
			persistent
		>
			<v-form
				ref="addEditForm"
				@submit.prevent="submit"
			>
				<v-card class="py-2 px-4">
					<v-card-title class="lightBlue--text pb-4">
						<template v-if="isComplete">
							استكمال تسجيل
							<span class="text-body-1 grey--text text--darken-3 ms-2">
								({{`${studentData.firstName || ''} ${studentData.fatherName || ''} ${studentData.lastName || ''}`}})
							</span>
						</template>
						<template v-else>
							<span v-if="editStudentId">تعديل البيانات</span>
							<span v-else>إضافة طالب</span>
						</template>
					</v-card-title>
					<v-card-text>
						<!-- tabs (in complete state) -->
						<v-tabs
							v-if="isComplete || isFailed || skipRegistration"
							key="tabs"
							v-model="activeDialogTab"
							centered
							grow
							class="rounded-medium elevation-1 mb-1"
							style="overflow: hidden"
							height="35"
							show-arrows
							center-active
						>
							<v-tab
								v-for="(item, index) in ['معلومات الطالب', 'بيانات الاستكمال']"
								:key="index"
								:disabled="index === 1 && skipRegistration && !isFilledMainData"
							>{{item}}</v-tab>
						</v-tabs>

						<v-tabs-items
							v-model="activeDialogTab"
							:class="{'py-1': isComplete || isFailed || skipRegistration}"
						>
							<!-- main data for ADD and EDIT -->
							<v-tab-item eager>
								<v-row class="align-center mt-0">
									<!-- first name -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field 
											v-model="studentData.firstName"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="الاسم"
											dense
											outlined
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- last name -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field 
											v-model="studentData.lastName"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="الكنية"
											dense
											outlined
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- gender -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-autocomplete
											v-model.number="studentData.genderId"
											:items="genders"
											item-text="name"
											item-value="id"
											label="الجنس"
											outlined
											hide-details
											auto-select-first
											dense
											class="rounded-medium grey--text"
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="rules.requiredAsNumber"
										></v-autocomplete>
									</v-col>

									<!-- father name -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field 
											v-model="studentData.fatherName"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="اسم الأب"
											dense
											outlined
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="rules.required"
										>
										</v-text-field>
									</v-col>

									<!-- mother name -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field 
											v-model="studentData.motherName"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="اسم الأم"
											dense
											outlined
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="rules.required"
										>
										</v-text-field>
									</v-col>

									<!-- grade -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-autocomplete 
											v-model="studentData.gradeId"
											:items="grades"
											item-text="name"
											item-value="id"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="الصف"
											auto-select-first
											dense
											outlined
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="rules.required"
											@change="
												isGradeHasNoPlacementTest(studentData.gradeId) &&
												!studentData.edunixCode
													? studentData.isNokhbaStudent = false
													: null
											"
										></v-autocomplete>
									</v-col>

									<!-- student phone -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field 
											v-model="studentData.studentPhone"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="هاتف الطالب"
											dense
											outlined
											:readonly="submitLoading"
										></v-text-field>
									</v-col>

									<!-- phone number -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field 
											v-model="studentData.phoneNumber"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="الهاتف الثابت"
											dense
											outlined
											:readonly="submitLoading"
										></v-text-field>
									</v-col>

									<!-- parents mobile number -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field 
											v-model="studentData.parentsMobileNumber"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="هاتف ولي الأمر"
											dense
											outlined
											:readonly="submitLoading"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- regionId -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
										class="d-flex align-center"
									>
										<v-autocomplete
											v-model="studentData.regionId"
											:items="regions"
											item-text="name"
											item-value="id"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="العنوان"
											dense
											outlined
											auto-select-first
											:loading="regionLoading"
											:readonly="regionLoading || submitLoading"
											:rules="rules.required"
											@click:append-outer="editRegionId = studentData.regionId; regionDialog = true;"
										></v-autocomplete>
										<v-btn v-if="false" icon @click="regionDialog = true" tabindex="-1">
											<v-icon>mdi-plus-circle-outline</v-icon>
										</v-btn>
									</v-col>
									
									<!-- street -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-text-field
											v-model="studentData.street"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="الشارع"
											dense
											outlined
											:readonly="submitLoading"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- previous school -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-autocomplete
											v-model="studentData.previousSchool"
											:items="schools"
											item-text="name"
											item-value="name"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="المدرسة السابقة"
											dense
											outlined
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="rules.required"
										></v-autocomplete>
									</v-col>

									<!-- ninth grade total -->
									<v-col
										v-if="getGradeById(studentData.gradeId)?.ninthGradeTotalRequired"
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6" cols="12"
										class="d-flex align-center"
									>
										<v-text-field
											v-model.number="studentData.ninthGradeTotal"
											type="number"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="مجموع التاسع"
											dense
											outlined
											hide-spin-buttons
											:readonly="submitLoading"
											:disabled="isComplete || isFailed"
											:rules="[val => val && val > 0 && val <= 3100 || 'الحقل مطلوب']"
										></v-text-field>
									</v-col>

									<!-- create date -->
									<v-col
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-menu
											ref="createDateMenu"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto"
											:disabled="submitLoading"
										>
											<template  v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="studentData.createDate"
													hide-details
													outlined
													dense
													class="rounded-medium"
													prepend-inner-icon="mdi-calendar"
													:readonly="submitLoading"
													:disabled="isComplete || isFailed"
													v-bind="attrs"
													v-on="on"
													label="تاريخ الإضافة"
												></v-text-field>
											</template>
											<v-date-picker
												v-model="studentData.createDate"
												prev-month-aria-label='$vuetify.datePicker.prevMonthAriaLabel'
												:max="(new Date(Date.now() * 1.0015 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
												min="1950-01-01"
												@change="$refs.createDateMenu.save($event)"
											></v-date-picker>
										</v-menu>
									</v-col>

									<!-- is nokhba student -->
									<v-col
										v-if="!isGradeHasNoPlacementTest(studentData.gradeId) && isAdmin"
										:lg="isComplete || isFailed || skipRegistration ? 3 : null"
										:md="isComplete || isFailed || skipRegistration ? 4 : null"
										sm="6"
										cols="12"
									>
										<v-checkbox
											v-model="studentData.isNokhbaStudent"
											label="طالب نخبة"
											hide-details
											class="ma-0"
											:disabled="isComplete || isFailed"
										></v-checkbox>
									</v-col>

									<!-- notes -->
									<v-col v-if="!isComplete && !isFailed && !skipRegistration" cols="12">
										<v-textarea 
											v-model="studentData.notes"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="ملاحظات"
											rows="2"
											auto-grow
											no-resize
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-textarea>
									</v-col>
									
									<!-- placement test -->
									<v-col
										v-if="studentData.placementTestId"
										cols="12"
										class="text-caption pt-0 pb-3"
									>
										هذا الطالب يتبع امتحان 
										<span class="grey--text text--darken-3">
											{{getStudentPlacementTestFull()}}
										</span>
									</v-col>
								</v-row>
							</v-tab-item>
							
							<!-- complete data -->
							<v-tab-item
								v-if="isComplete || isFailed || skipRegistration"
								eager
							>
								<v-row class="align-center mt-0">
									<!-- father job -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-text-field 
											v-model="studentData.fatherJob"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="عمل الأب"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-text-field>
									</v-col>

									<!-- father phone number -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-text-field 
											v-model="studentData.fatherPhoneNumber"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="رقم هاتفه"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-text-field>
									</v-col>

									<!-- mother job -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-text-field 
											v-model="studentData.motherJob"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="عمل الأم"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-text-field>
									</v-col>

									<!-- mother phone number -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-text-field 
											v-model="studentData.motherPhoneNumber"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="رقم هاتفها"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-text-field>
									</v-col>

									<!-- responsible person -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-autocomplete 
											v-model="studentData.responsibleForEducation"
											:items="responsiblePersons"
											item-text="name"
											item-value="id"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="المسؤول عن المتابعة دراسياً"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
											:rules="rules.requiredAsNumber"
										></v-autocomplete>
									</v-col>
									
									<!-- responsible For education -->
									<v-col
										v-if="studentData.responsibleForEducation >= 3"
										lg="3" md="4" sm="6" cols="12"
									>
										<v-text-field
											v-model="studentData.responsibleForEducationNumber"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="رقمه"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- city -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-autocomplete 
											v-model="studentData.city"
											:items="cites"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="المحافظة"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
											:rules="rules.required"
										></v-autocomplete>
									</v-col>

									<!-- birth date -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-menu
											ref="birthDate"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto"
											:disabled="submitLoading"
										>
											<template  v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="studentData.birthDate"
													hide-details
													outlined
													dense
													class="rounded-medium"
													prepend-inner-icon="mdi-calendar"
													:loading="completeDataLoading"
													readonly
													:rules="rules.required"
													v-bind="attrs"
													v-on="on"
													label="تاريخ الميلاد"
												></v-text-field>
											</template>
											<v-date-picker
												v-model="studentData.birthDate"
												prev-month-aria-label='$vuetify.datePicker.prevMonthAriaLabel'
												:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
												min="1950-01-01"
												@change="$refs.birthDate.save($event)"
											></v-date-picker>
										</v-menu>
									</v-col>

									<!-- birth location -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-text-field 
											v-model="studentData.birthLocation"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="مكان الولادة"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- telegram number -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-text-field 
											v-model="studentData.telegramNumber"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="هاتف مجموعة الأهل"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- whatsapp number -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-text-field 
											v-model="studentData.whatsappNumber"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="هاتف مجموعة الشعبة"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-text-field>
									</v-col>

									<!-- winter clothes type -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-autocomplete 
											v-model="studentData.winterClothType"
											:items="winterClothTypes"
											item-text="name"
											item-value="id"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="اللباس الشتوي"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-autocomplete>
									</v-col>

									<!-- winter cloth size -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-autocomplete 
											v-model="studentData.winterClothSize"
											:items="clothesSizes"
											item-text="name"
											item-value="id"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="مقاس اللباس الشتوي"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-autocomplete>
									</v-col>

									<!-- summer clothes type -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-autocomplete 
											v-model="studentData.summerClothType"
											:items="summerClothTypes"
											item-text="name"
											item-value="id"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="اللباس الصيفي"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-autocomplete>
									</v-col>

									<!-- summer cloth size -->
									<v-col lg="3" md="4" sm="6" cols="12">
										<v-autocomplete 
											v-model="studentData.summerClothSize"
											:items="clothesSizes"
											item-text="name"
											item-value="id"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="مقاس اللباس الصيفي"
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-autocomplete>
									</v-col>

									<!-- with transportation -->
									<v-col lg="4" md="4" sm="6" cols="12">
										<v-radio-group
											v-model.number="studentData.withTransportation"
											label="مع مواصلات:"
											dense
											hide-details
											row
											class="grey--text rounded-medium grey--text ma-0"
											:loading="completeDataLoading"
											:readonly="submitLoading"
											:rules="rules.requiredAsNumber"
										>
											<v-radio label="نعم" :value="true"></v-radio>
											<v-radio label="لا" :value="false"></v-radio>
										</v-radio-group>
									</v-col>

									<!-- has brothers -->
									<v-col lg="5" md="4" sm="6" cols="12">
										<v-radio-group
											v-model.number="studentData.hasBrothers"
											label="له أخوة في النخبة سابقاً:"
											dense
											hide-details
											row
											class="grey--text rounded-medium grey--text ma-0"
											:loading="completeDataLoading"
											:readonly="submitLoading"
											:rules="[val => val !== null || 'الحقل مطلوب']"
										>
											<v-radio label="نعم" :value="true"></v-radio>
											<v-radio label="لا" :value="false"></v-radio>
										</v-radio-group>
									</v-col>

									<!-- brothers data -->
									<v-col v-if="studentData.hasBrothers" cols="12">
										<v-row>
											<!-- brother name -->
											<v-col lg="3" md="4" sm="6" cols="12">
												<v-text-field 
													v-model="studentData.brotherName"
													hide-details
													class="grey--text rounded-medium grey--text"
													label="اسم الأخ/الأخت"
													dense
													outlined
													:loading="completeDataLoading"
													:readonly="submitLoading"
													:rules="rules.required"
												></v-text-field>
											</v-col>
											
											<!-- grade name -->
											<v-col lg="3" md="4" sm="6" cols="12">
												<v-text-field 
													v-model="studentData.gradeName"
													hide-details
													class="grey--text rounded-medium grey--text"
													label="اسم الصف"
													dense
													outlined
													:loading="completeDataLoading"
													:readonly="submitLoading"
													:rules="rules.required"
												></v-text-field>
											</v-col>

											<!-- brother number -->
											<v-col lg="3" md="4" sm="6" cols="12">
												<v-text-field 
													v-model.number="studentData.brotherNumber"
													hide-details
													class="grey--text rounded-medium grey--text"
													label="عدد الأخوة"
													type="number"
													min="1"
													max="20"
													hide-spin-buttons
													dense
													outlined
													:loading="completeDataLoading"
													:readonly="submitLoading"
													:rules="rules.requiredAsNumber"
												></v-text-field>
											</v-col>
										</v-row>
									</v-col>

									<!-- notes -->
									<v-col cols="12">
										<v-textarea 
											v-model="studentData.notes"
											hide-details
											class="grey--text rounded-medium grey--text"
											label="ملاحظات"
											rows="2"
											auto-grow
											no-resize
											dense
											outlined
											:loading="completeDataLoading"
											:readonly="submitLoading"
										></v-textarea>
									</v-col>
								</v-row>
							</v-tab-item>
						</v-tabs-items>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							type="submit"
							color="lightGreen white--text"
							class="rounded-xl px-5"
							:loading="submitLoading"
							:disabled="submitLoading || (skipRegistration && !isFilledMainData)"
						>
							{{skipRegistration && activeDialogTab === 0 ? 'التالي' : 'حفظ'}}
						</v-btn>
						<v-btn
							text
							@click="addEditCompleteDialog = false"
							:disabled="submitLoading"
							class="rounded-xl px-5"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- exemption dialog -->
		<exemption-dialog
			v-model="exemptionDialog"
			:studentHasExemption="studentHasExemption"
			:exemptionStudentId="exemptionStudentId"
			:disabled="!isAdmin"
			@succeeded="filterData(isFiltered);"
		/>

		<!-- delete -->
		<bee-delete-dialog
			v-model="deleteDialog"
			:ids="deleteStudentId"
			moduleType="students"
			@on-delete="filterData(isFiltered)"
		>
			هل أنت متأكد من <span class="red--text">حذف</span> الطالب؟
		</bee-delete-dialog>
		
		<!-- region dialog -->
		<v-dialog v-model="regionDialog" width="300" content-class="rounded-xl" persistent>
			<v-form ref="regionForm" @submit.prevent="saveRegion">
				<v-card class="py-4 px-4">
					<v-card-title class="lightBlue--text">
						{{ editRegionId ? 'تعديل' : 'إضافة' }} منطقة
					</v-card-title>
					<v-card-text class="px-0">
						<!-- name -->
						<v-col cols="12">
							<v-text-field 
								v-model="regionName"
								hide-details
								class="grey--text rounded-medium grey--text"
								label="اسم المنطقة"
								dense
								outlined
								:readonly="submitLoading"
								:rules="rules.required"
							></v-text-field>
						</v-col>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							type="submit"
							color="lightGreen white--text"
							class="rounded-xl px-5"
							:loading="regionLoading"
							:disabled="regionLoading"
						>
							موافق
						</v-btn>
						<v-btn
							text
							:disabled="regionLoading"
							@click="regionDialog = false"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		
		<!-- withDraw dialog -->
		<v-dialog v-model="withDrawDialog" width="300" content-class="rounded-xl" persistent>
			<v-form ref="regionForm" @submit.prevent="withDraw">
				<v-card class="py-4 px-4">
					<v-card-title class="error--text text--lighten-1">
						تأكيد الانسحاب
					</v-card-title>
					<v-card-text>
						هل أنت متأكد من انسحاب الطالب ؟
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							type="submit"
							color="error lighten-1 white--text"
							class="rounded-xl px-5"
							:disabled="withDrawLoading"
							:loading="withDrawLoading"
						>
							نعم
						</v-btn>
						<v-btn
							text
							:disabled="withDrawLoading"
							class="rounded-xl px-5"
							@click="withDrawDialog = false"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		
		<!-- tabs -->
		<v-tabs
			v-model="activeTab"
			centered
			grow
			height="35"
			class="rounded-medium elevation-2 mb-1"
			style="overflow: hidden"
			show-arrows
			center-active
			@change="options.page = 1; totalItems = null; filterData(isFiltered)"
		>
			<v-tab
				v-for="tab in tabs"
				:key="tab.id"
				:disabled="loading"
			>
				{{tab.name}}
			</v-tab>
		</v-tabs>
		
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : students"
			:loading="loading"
			fixed-header
			dense
			:disable-sort="loading || !students.length"
			:height="$vuetify.breakpoint.height - 262"
			:items-per-page="25"
			:footer-props="{ 'items-per-page-options': [25, 50, 100] }"
			:options.sync="options"
			:server-items-length="totalItems"
			class="elevation-2 rounded-medium pa-1"
		>
			<!-- no -->
			<template v-slot:item.no="{ index }">
				{{index + 1}}
			</template>

			<!-- name -->
			<template v-slot:item.firstName="{ item }">
				{{`${item.firstName} ${item.lastName ? item.lastName : '' }`}}
			</template>
			
			<!-- grade -->
			<template v-slot:item.gradeId="{ item }">
				{{getGradeById(item.gradeId) ? getGradeById(item.gradeId).name : '-'}}
			</template>

			<!-- region -->
			<template v-slot:item.regionId="{ item }">
				{{getRegionById(item.regionId) ? getRegionById(item.regionId).name : '-'}}
			</template>

			<!-- region -->
			<template v-slot:item.mobileNumber="{ item }">
				{{ item.mobileNumber | isAvailable }}
			</template>

			<!-- ninth grade total -->
			<template v-slot:item.ninthGradeTotal="{ item }">
				{{ item.ninthGradeTotal | isAvailable }}
			</template>

			<!-- created at -->
			<template v-slot:item.createdAt="{ item }">
				{{ moment(item.createdAt).format('YYYY-MM-DD') }}
			</template>

			<!-- test date -->
			<template v-slot:item.placementTestId="{ item }">
				{{ moment(getPlacementTestById(item.placementTestId)?.testDate).format('YYYY-MM-DD') }}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex" style="padding: 2px 0">
					<!-- student data -->
					<v-tooltip v-if="isAdmin" top>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								color="lightGreen darken-1"
								class="rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
								@click="briefStudentId = item.id; studentBriefDialog = true;"
							>
								<v-icon dense>
									mdi-file-document
								</v-icon>
							</v-btn>
						</template>
						بيانات الطالب
					</v-tooltip>
					
					<!-- edit -->
					<v-tooltip top>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								@click="editStudentId = item.id; addEditCompleteDialog = true;"
								:color="
									item.isNokhbaStudent
									? 'primary'
									: (item.isCompleted && isComplete) || !isComplete
										? 'warning'
										: 'lightGreen'"
								class="ms-1 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon dense>
									{{
										isComplete
										? (
											item.isCompleted
											? 'mdi-account-edit'
											: (!item.isNokhbaStudent
												? 'mdi-account-badge'
												: 'mdi-account-star')
										)
										: 'mdi-square-edit-outline'
									}}
								</v-icon>
							</v-btn>
						</template>
						{{
							isComplete
								? (
									item.isCompleted
										? 'تعديل الاستكمال'
										: 'استكمال'
								)
								: 'تعديل'
						}}
					</v-tooltip>

					<!-- exempted -->
					<v-tooltip top v-if="item.hasExemption">
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								@click="exemptionStudentId = item.id; exemptionDialog = true"
								color="deepOrange"
								class="ms-1 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-alert-circle-outline</v-icon>
							</v-btn>
						</template>
						مستثنى
					</v-tooltip>
					
					<!-- exemption -->
					<v-tooltip top v-else-if="isAdmin">
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								@click="exemptionStudentId = item.id; exemptionDialog = true"
								color="lightBlue"
								class="ms-1 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-alert-circle-outline</v-icon>
							</v-btn>
						</template>
						استثناء
					</v-tooltip>

					<!-- with draw -->
					<v-tooltip
						v-if="item.hasPaid && ![studentStatusFilters.withdrawnAndRecovered, studentStatusFilters.withdrawn].includes(item.studentStatusFilters)"
						top
					>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								color="error lighten-1"
								class="ms-1 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
								@click="withDrawStudentId = item.id; withDrawDialog = true"
							>
								<v-icon>mdi-account-arrow-right</v-icon>
							</v-btn>
						</template>
						انسحاب
					</v-tooltip>

					<!-- delete -->
					<v-tooltip v-if="isAdmin && (!item.hasPaid || item.hasPaidWithdrawPayment)" top>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								color="error lighten-1"
								class="ms-1 rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								v-bind="attrs"
								v-on="on"
								@click="deleteStudentId = item.id; deleteDialog = true"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</template>
						حذف
					</v-tooltip>

					<v-icon v-if="0 && item.hasExemption" color="error" size="32" dense>
						mdi-alert-octagon-outline
					</v-icon>
				</div>
			</template>

			<!-- page text -->
			<template v-slot:footer.page-text="items"> 
				<span>{{ items.pageStart }} - {{ items.pageStop }} من {{ items.itemsLength }}</span>
			</template>
		</v-data-table>

		<!-- total -->
		<v-card
			:loading="staticsLoading"
			loader-height="2"
			elevation="1"
			class="mt-1 rounded-medium"
		>
			<v-card-text class="black--text d-flex flex-wrap justify-md-start justify-center px-2 py-1">
				<div v-if="activeTab === 0">
					العدد الكلي: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.studentsCount}} طالب -->
						{{studentsStatics.totalCount}} طالب
					</span>
				</div>
				<div v-if="activeTab === 0" class="ms-md-6">
					العدد الصافي <span class="text-small grey--text text--darken-1">(بدون المنسحبين)</span>: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.studentsCount}} طالب -->
						{{studentsStatics.totalCount - studentsStatics.recoveredStudentsCount}} طالب
					</span>
				</div>
				
				<div v-if="activeTab === 1">
					عدد المسجلين: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.studentsCount}} طالب -->
						{{studentsStatics.registeredCount}} طالب
					</span>
				</div>

				<div v-if="activeTab === 3">
					عدد المستكملين: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.studentsSucceeded}} / {{statistics.studentsCompleted}} طالب -->
						{{studentsStatics.completedStudentsCount}} طالب
					</span>
				</div>

				<div v-if="isComplete" class="ms-md-6">
					عدد طلاب النخبة
					<span class="text-small grey--text text--darken-1">(استكمال ومسكتملين)</span>: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.totalNukhbaStudents}} طالب -->
						{{studentsStatics.nukbaStudentsCount}} طالب
					</span>
				</div>
				
				<div v-if="isComplete" class="ms-md-6">
					عدد الطلاب مع مواصلات
					<span class="text-small grey--text text--darken-1">(استكمال ومسكتملين)</span>: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.totalWithTransportation}} طالب -->
						{{studentsStatics.studentsWithTransportCount}} طالب
					</span>
				</div>

				<div v-if="isFailed">
					عدد طلاب الاسترداد: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.totalStudentsWithdrawn}} / {{statistics.studentsWithdrawn}} طالب -->
						{{studentsStatics.withdrawingStudentsCount}} طالب
					</span>
				</div>

				<div v-if="isWithDraw" class="ms-md-6">
					عدد الطلاب المنسحبين: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						<!-- {{statistics.studentsCount}} طالب -->
						{{studentsStatics.recoveredStudentsCount}} طالب
					</span>
				</div>
			</v-card-text>
		</v-card>

		<!-- add button -->
		<v-fab-transition>
			<v-btn
				v-if="isRegister"
				fab
				dark
				fixed
				left
				bottom
				color="primary"
				@click="selectAddTypeDialog = true;"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-fab-transition>
	</div>
</template>

<script>
import ExemptionDialog from '@/components/ExemptionDialog.vue'
import BeeDeleteDialog from '@/components/BeeDeleteDialog.vue'
import { mapGetters, mapState } from 'vuex'
import { genders, responsiblePersons, clothesSizes, winterClothTypes, summerClothTypes, heardByItems, cites, studentsStatus, studentsDataStatus, studentsStatusNames, paymentTypes, paymentTypesNames, gradeLevel, studentStatusFilters, gendersIds } from '@/helpers/enums'

import rules from '@/helpers/validation rules'
import moment from 'moment'
import { getFullDate, getSortData } from '@/helpers/helpers'

export default {
	name: 'StudentsData',

	components: {
		ExemptionDialog,
		BeeDeleteDialog
	},
	
	data: () => ({
		// helpers
		genders,
		studentsStatus,
		studentsDataStatus,
		studentStatusFilters,
		studentsStatusNames,
		responsiblePersons,
		clothesSizes,
		winterClothTypes,
		summerClothTypes,
		heardByItems,
		cites,
		paymentTypesNames,
		gradeLevel,

		activeDialogTab: 0,
		completeDataLoading: false,

		selectAddTypeDialog: false,
		edunixStudent: {
			show: false,
			loading: false,
			code: null,
		},

		addEditCompleteDialog: false,
		editStudentId: null,

		exemptionDialog: null,
		exemptionStudentId: null,
		studentHasExemption: false,

		withDrawStudentId: null,
		withDrawDialog: false,
		withDrawLoading: false,

		deleteStudentId: null,
		deleteDialog: null,

		submitLoading: false,
		loading: false,
		staticsLoading: false,

		regionDialog: false,
		regionLoading: false,
		editRegionId: false,
		rules,
		
		// pagination
		options: {},
		totalItems: null,

		// student Brief
		studentBriefDialog: false,
		studentBriefLoading: false,
		briefStudentId: null,
		paymentsHeaders: [
			{ text: 'نوع الدفعة', value: 'paymentType', class: 'px-1', cellClass: 'text-body-2 lightGreen--text text--darken-3 px-1' },
			{ text: 'المبلغ', value: 'amount', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'مدفوعة', value: 'isDue', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'التاريخ', value: 'date', class: 'px-1', cellClass: 'text-body-2 px-1', width: 180 },
			{ text: 'رقم الإيصال', value: 'receiptNo', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'رقم الدفتر', value: 'noteNo', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'الإيصال', value: 'hasReceiptBeenReceived', class: 'px-1', cellClass: 'text-body-2 px-1' },
			{ text: 'الملاحظات', value: 'note', class: 'px-1', cellClass: 'text-body-2 px-1' },
		],
		studentBriefData: {
			exemptions: null,
			payments: []
		},

		// filter
		filter: {
			studentNo: null,
			studentName: null,
			gradeLevelId: null,
			gradeId: null,
			genderId: null,
			previousSchool: null,
			fromDate: null,
			toDate: null,
			placementTestId: null,
		},
		isFiltered: false,

		// tabs
		activeTab: 0,
		tabs: [
			{ id: 1, name: 'الكل' },
			{ id: 2, name: 'مسجلين' },
			{ id: 3, name: 'استكمال' },
			{ id: 4, name: 'مستكملين' },
			{ id: 5, name: 'استرداد' },
			{ id: 6, name: 'منسحبين' },
		],

		/*  DATA */
		// region dialog
		regionName: null,

		// add/edit/complete dialog
		studentData: {
			// main data
			firstName: '',
			lastName: '',
			fatherName: '',
			motherName: '',
			genderId: '',
			gradeId: '',
			studentPhone: '',
			phoneNumber: '',
			parentsMobileNumber: '',
			regionId: '',
			street: '',
			previousSchool: '',
			ninthGradeTotal: '',
			createDate: moment().format('YYYY-MM-DD'),
			createTime: moment().format('HH:mm:ss'),
			isNokhbaStudent: false,
			placementTestId: null,

			// complete data
			fatherJob: null,
			fatherPhoneNumber: null,
			motherJob: null,
			motherPhoneNumber: null,
			responsibleForEducation: null,
			responsibleForEducationNumber: null,
			withTransportation: false,

			hasBrothers: false,
			brotherName: null,
			gradeName: null,
			brotherNumber: null,
			
			winterClothType: null,
			winterClothSize: null,
			summerClothType: null,
			summerClothSize: null,
			telegramNumber: null,
			whatsappNumber: null,
			city: null,
			birthDate: null,
			birthLocation: null,
			notes: null,

			edunixCode: null
		},
	}),

	watch: {
		studentBriefDialog(val) {
			if (val) {
				this.studentBriefLoading = true;
				this.$store.dispatch('students/brief', { id: this.briefStudentId })
					.then(data => {
						this.studentBriefData = {
							...data,
							payments: data.payments.reverse()
						};
					})
					.finally(() => {
						this.studentBriefLoading = false;
					})
			} else {
				this.briefStudentId = null;
				this.studentBriefData = {
					exemptions: null,
					payments: []
				};
			}
		},

		addEditCompleteDialog(val) {
			if (val) {
				if (this.isComplete) {
					this.activeDialogTab = 1;
				} 
				if (this.editStudentId) {
					const student = this.getStudentById(this.editStudentId);
					this.studentData.firstName = student.firstName;
					this.studentData.lastName = student.lastName;
					this.studentData.fatherName = student.fatherName;
					this.studentData.motherName = student.motherName;
					this.studentData.genderId = student.gender;
					this.studentData.gradeId = student.gradeId;
					this.studentData.studentPhone = student.mobileNumber;
					this.studentData.phoneNumber = student.phoneNumber;
					this.studentData.parentsMobileNumber = student.parentsMobileNumber;
					this.studentData.regionId = student.regionId;
					this.studentData.street = student.street;
					this.studentData.previousSchool = student.previousSchool;
					this.studentData.ninthGradeTotal = student.ninthGradeTotal;
					this.studentData.createDate = moment(student.createdAt).format('YYYY-MM-DD');
					this.studentData.createTime = moment(student.createdAt).format('HH:mm:ss');
					this.studentData.isNokhbaStudent = student.isNokhbaStudent;
					this.studentData.placementTestId = student.placementTestId;
					this.studentData.notes = student.notes;
					
					if (student.isCompleted) {
						this.completeDataLoading = true;
						this.$store.dispatch('students/fetchById', { id: this.editStudentId })
							.then((data) => {
								this.studentData.fatherJob = data.fathersJob;
								this.studentData.fatherPhoneNumber = data.fathersPhoneNumber;
								this.studentData.motherJob = data.mothersJob;
								this.studentData.motherPhoneNumber = data.mothersPhoneNumber;
								this.studentData.responsibleForEducation = data.responsableForEducation;
								this.studentData.responsibleForEducationNumber = data.responsableForEducationPhone;
								this.studentData.withTransportation = data.withTransportation;

								this.studentData.hasBrothers = data.hasPreviousRelations;
								this.studentData.brotherName = data.previousRelationName;
								this.studentData.gradeName = data.previousRelationGrade;
								this.studentData.brotherNumber = data.previousRelationCount;
								
								this.studentData.winterClothType = data.winterClothType;
								this.studentData.winterClothSize = data.winterClothSize;
								this.studentData.summerClothType = data.summerClothType;
								this.studentData.summerClothSize = data.summerClothSize;
								this.studentData.telegramNumber = data.telegramPhoneNumber;
								this.studentData.whatsappNumber = data.whatsAppPhoneNumber;
								this.studentData.city = data.city;
								this.studentData.birthDate =
									data.birthDate
										? moment(data.birthDate).format('YYYY-MM-DD')
										: null;
								this.studentData.birthLocation = data.birthLocation;
							})
							.finally(() => {
								this.completeDataLoading = false;
							})
					}
				} else {
					this.studentData.createDate = moment().format('YYYY-MM-DD');
					this.studentData.createTime = moment().format('HH:mm:ss');
				}
			} else {
				this.activeDialogTab = 0;
				this.editStudentId = null;
				this.studentData.placementTestId = null;

				// reset all student data
				this.studentData = {
					firstName: '',
					lastName: '',
					fatherName: '',
					motherName: '',
					genderId: '',
					gradeId: '',
					studentPhone: '',
					phoneNumber: '',
					parentsMobileNumber: '',
					regionId: '',
					street: '',
					previousSchool: '',
					ninthGradeTotal: '',
					createDate: moment().format('YYYY-MM-DD'),
					createTime: moment().format('HH:mm:ss'),
					placementTestId: null,

					// complete data
					fatherJob: null,
					fatherPhoneNumber: null,
					motherJob: null,
					motherPhoneNumber: null,
					responsibleForEducation: null,
					responsibleForEducationNumber: null,
					withTransportation: false,

					hasBrothers: false,
					brotherName: null,
					gradeName: null,
					brotherNumber: null,
					
					winterClothType: null,
					winterClothSize: null,
					summerClothType: null,
					summerClothSize: null,
					telegramNumber: null,
					whatsappNumber: null,
					city: null,
					birthDate: null,
					birthLocation: null,
					notes: null,

					edunixCode: null
				};

				this.$refs.addEditForm.resetValidation();
			}
		},

		exemptionDialog(val) {
			if (val) {
				const student = this.getStudentById(this.exemptionStudentId);
				this.studentHasExemption = student.hasExemption;
			} else {
				this.exemptionStudentId = null;
				this.studentHasExemption = false;
			}
		},

		regionDialog(val) {
			if (val) {
				if (this.editRegionId) {
					this.regionName = this.getRegionById(this.editRegionId).name;
				}
			} else {
				this.editRegionId = null;
				this.$refs.regionForm.reset();
			}
		},

		selectAddTypeDialog(val) {
			if (!val) {
				this.edunixStudent.show = false;
				this.edunixStudent.code = null;
			}
		},

		options(val, oldVal) {
			if (oldVal.page) this.filterData(this.isFiltered);
		}
	},

	computed: {
		...mapState({
			grades: state => state.grades.grades,
			regions: state => state.regions.regions,
			students: state => state.students.students,
			schools: state => state.schools.schools,
			studentsStatics: state => state.students.studentsStatics,
			placementTests: state => state.placementTests.placementTests,
			isAdmin: state => state.auth.isAdmin
		}),

		...mapGetters({
			getGradeById: 'grades/getGradeById',
			getRegionById: 'regions/getRegionById',
			getPlacementTestById: 'placementTests/getPlacementTestById',
			getStudentById: 'students/getStudentById',
		}),

		headers() {
			return [
				{ text: '#', value: 'no', align: 'center', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, width: 30 },
				{ text: 'الرقم', value: 'studentNo', class: 'px-1', cellClass: 'text-body-2 px-1', divider: true, sortable: false },
				{ text: 'الاسم', value: 'firstName', class: 'px-1', cellClass: 'text-body-2 px-1' },
				{ text: 'الأب', value: 'fatherName', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ text: 'الأم', value: 'motherName', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ 
					text: 'الصف',
					value: 'gradeId',
					width: 80,
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sort: (a, b) => {
						const name1 = this.getGradeById(a).name;
						const name2 = this.getGradeById(b).name;
						return name1.localeCompare(name2);
					}
				},
				{ text: 'هـ. الطالب', value: 'mobileNumber', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ text: 'هـ. ولي الأمر', value: 'parentsMobileNumber', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{
					text: 'المنطقة',
					value: 'regionId',
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sortable: false,
					sort: (a, b) => {
						const name1 = this.getRegionById(a).name;
						const name2 = this.getRegionById(b).name;
						return name1.localeCompare(name2);
					}
				},
				{ text: 'المدرسة السابقة', value: 'previousSchool', class: 'px-1', cellClass: 'text-body-2 px-1', width: 160, sortable: false, },
				{ text: 'م. التاسع', value: 'ninthGradeTotal', class: 'px-1', cellClass: 'text-body-2 px-1' },
				
				this.isComplete || this.isFailed
					? {
						text: 'تاريخ الامتحان',
						value: 'placementTestId',
						class: 'px-1',
						cellClass: 'text-body-2 px-1',
						sortable: false,
						sort: (a, b) => {
							const testDateA = this.getPlacementTestById(a).testDate;
							const testDateB = this.getPlacementTestById(b).testDate;
							return moment(testDateB).isAfter(testDateA) ? 1 : -1;
						}
					}
					: { text: 'تاريخ التسجيل', value: 'createdAt', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false },
				{ text: '', value: 'actions', sortable: false, class: 'px-1', cellClass: 'px-1', width: 150 },
			]
		},

		isRegister() { return [0, 1].includes(this.activeTab); },
		isComplete() { return [2, 3].includes(this.activeTab); },
		isFailed() { return this.activeTab === 4; },
		isWithDraw() { return this.activeTab === 5; },
		skipRegistration() {
			return this.studentData.isNokhbaStudent || this.isGradeHasNoPlacementTest(this.studentData.gradeId)
		},
		
		isFilledMainData() {
			const isNinthGradeTotalRequired =
				this.studentData.gradeId && this.getGradeById(this.studentData.gradeId).ninthGradeTotalRequired;
			return (
				this.studentData.firstName &&
				this.studentData.lastName &&
				this.studentData.fatherName &&
				this.studentData.motherName &&
				this.studentData.genderId !== null &&
				this.studentData.gradeId &&
				this.studentData.parentsMobileNumber &&
				this.studentData.regionId &&
				this.studentData.street &&
				this.studentData.previousSchool &&
				(isNinthGradeTotalRequired ? this.studentData.ninthGradeTotal : true) &&
				this.studentData.createDate
			)
		}
	},

	methods: {
		// filter
		fetchStatics() {
			this.staticsLoading = true;
			return this.$store.dispatch('students/fetchStatics', {
				name: this.filter.studentName || null,
				studentNo: this.filter.studentNo || null,
				gradeId: this.filter.gradeId,
				gradeLevel: this.filter.gradeLevelId,
				gender: this.filter.genderId,
				previousSchool: this.filter.previousSchool,
				startDate: this.filter.fromDate || null,
				endDate: this.filter.toDate || null,
				placementTestId: this.filter.placementTestId,
			}).finally(() => {
				this.staticsLoading = false;
			})
		},
		filterData(isFilter, isFirstLoad) {
			this.loading = true;
			this.isFiltered = isFilter;
			this.$eventBus.$emit('disable-refresh');

			const { page, itemsPerPage, sortBy, sortDesc } = this.options;

			const { name: byName, grade: ByGrade, ninthGrade: byNinthGrade } =
				getSortData({ sortBy, sortDesc }, {
					name: 'firstName',
					grade: 'gradeId',
					ninthGrade: 'ninthGradeTotal',
					marks: 'marks',
					state: 'state',
				});

			const actionName = this.isRegister ? 'fetchAll' : 'fetchAllRegistered';
			return this.$store.dispatch(`students/${actionName}`, {
				name: isFilter ? this.filter.studentName : null,
				studentNo: isFilter ? this.filter.studentNo : null,
				gradeId: isFilter ? this.filter.gradeId : null,
				gradeLevel: isFilter ? this.filter.gradeLevelId : null,
				gender: isFilter ? this.filter.genderId : null,
				previousSchool: isFilter ? this.filter.previousSchool : null,
				startDate: isFilter ? this.filter.fromDate : null,
				endDate: isFilter ? this.filter.toDate : null,
				placementTestId: isFilter ? this.filter.placementTestId : null,
				studentStatus: this.getStudentsStatus(),
				page: page || 1,
				perPage: itemsPerPage || 25,
				orderByName: byName,
				orderByGrade: ByGrade,
				orderByNinthGradeTotal: byNinthGrade,
				orderByMarks: null,
			}).then((data) => {
				this.totalItems = data.totalCount;
			}).finally(() => {
				if (!isFirstLoad) {
					this.loading = false;
				}
				this.$eventBus.$emit('enable-refresh');
			})
		},

		// fetching
		fetchEdunixStudentData() {
			this.edunixStudent.loading = true;
			this.$store.dispatch('students/fetchByEdunixCode', {
				code: this.edunixStudent.code
			}).then(data => {
				if (!data) {
					this.$eventBus.$emit('show-snackbar', true, 'لا يوجد طالب بهذا الكود');
					return;
				}
				this.studentData.firstName = data.FirstName;
				this.studentData.lastName = data.LastName;
				this.studentData.fatherName = data.FatherName;
				this.studentData.motherName = data.MotherName;
				this.studentData.genderId = data.Gender ? gendersIds.male : gendersIds.female;
				this.studentData.gradeId = this.grades.find(c => c.name === data.Class)?.id;
				this.studentData.studentPhone = data.Mobile;
				this.studentData.phoneNumber = data.FixedPhone;
				this.studentData.parentsMobileNumber = data.MainPhone;
				this.studentData.regionId = this.regions.find(c => c.name === data.Address)?.id;
				this.studentData.street = data.Street;
				this.studentData.previousSchool = data.PreviousSchool;
				this.studentData.ninthGradeTotal = +data.NinthSum || null;
				this.studentData.createDate = moment().format('YYYY-MM-DD');
				this.studentData.createTime = moment().format('HH:mm:ss');
				this.studentData.isNokhbaStudent = true;
				this.studentData.placementTestId = null;
				this.studentData.fatherJob = data.FatherWork;
				this.studentData.fatherPhoneNumber = data.FatherNumber;
				this.studentData.motherJob = data.MotherWork;
				this.studentData.motherPhoneNumber = data.MotherNumber;
				this.studentData.responsibleForEducation = data.ResponsibleOfCare;
				this.studentData.responsibleForEducationNumber = null;
				this.studentData.withTransportation = false;
				this.studentData.hasBrothers = false;
				
				this.studentData.winterClothType = data.WinterClothesType;
				this.studentData.winterClothSize = data.WinterClothesSize;
				this.studentData.summerClothType = data.SummerClothesType;
				this.studentData.summerClothSize = data.SummerClothesSize;
				this.studentData.telegramNumber = data.ParentsGroupNumber;
				this.studentData.whatsappNumber = data.ParentsGroupNumber;
				this.studentData.city = data.City;
				this.studentData.birthDate =
					data.BirthDate ? moment(data.BirthDate).format('YYYY-MM-DD') : null;
				this.studentData.birthLocation = data.BirthPlace;
				this.studentData.notes = data.Notes;

				this.studentData.edunixCode = this.edunixStudent.code;

				this.selectAddTypeDialog = false;
				this.addEditCompleteDialog = true;
			}).finally(() => {
				this.edunixStudent.loading = false;
			})
		},

		// dialogs
		submit() {
			if (this.skipRegistration && this.activeDialogTab === 0) {
				if (this.isFilledMainData) {
					this.activeDialogTab = 1;
				}
			} else {
				this.saveStudentData();
			}
		},

		saveStudentData() {
			if (this.$refs.addEditForm.validate()) {
				this.submitLoading = true;
				let actionName =
					this.editStudentId && !this.studentData.edunixCode ? 'update' : 'createAndRegister';
				// : this.skipRegistration ? 'create' : 'createAndRegister';
				
				let nokhbaAction = null;
				
				const student = this.getStudentById(this.editStudentId);
				if (this.isComplete) {
					actionName = !student.isCompleted ? 'complete' : 'update';
				}
				if (actionName === 'update' && student.isNokhbaStudent !== this.studentData.isNokhbaStudent) {
					nokhbaAction = this.studentData.isNokhbaStudent ? 'setAsNokba' : 'removeAsNokba';
				}

				Promise.all([
					nokhbaAction
						? this.$store.dispatch(`students/${nokhbaAction}`, { id: this.editStudentId })
						: null,
					this.$store.dispatch(`students/${actionName}`, {
						isUpdateComplete: student?.isCompleted,
						id: this.editStudentId,
						firstName: this.studentData.firstName,
						lastName: this.studentData.lastName,
						fatherName: this.studentData.fatherName,
						motherName: this.studentData.motherName,
						gender: this.studentData.genderId,
						phoneNumber: this.studentData.phoneNumber,
						mobileNumber: this.studentData.studentPhone,
						parentsMobileNumber: this.studentData.parentsMobileNumber,
						gradeId: this.studentData.gradeId,
						previousSchool: this.studentData.previousSchool,
						ninthGradeTotal: this.studentData.ninthGradeTotal || 0,
						regionId: this.studentData.regionId,
						street: this.studentData.street,
						createdAt: this.studentData.createDate + `T${this.studentData.createTime}`,
						isNokhbaStudent: this.studentData.isNokhbaStudent,

						fathersJob: this.studentData.fatherJob,
						mothersJob: this.studentData.motherJob,
						fathersPhoneNumber: this.studentData.fatherPhoneNumber,
						mothersPhoneNumber: this.studentData.motherPhoneNumber,
						responsableForEducation: this.studentData.responsibleForEducation || 0,
						responsableForEducationPhone: this.studentData.responsibleForEducationNumber,
						withTransportation: this.studentData.withTransportation,
						hasPreviousRelations: this.studentData.hasBrothers,
						previousRelationName: this.studentData.brotherName,
						previousRelationGrade: this.studentData.gradeName,
						previousRelationCount: this.studentData.brotherNumber,
						winterClothType: this.studentData.winterClothType || 0,
						winterClothSize: this.studentData.winterClothSize || 0,
						summerClothType: this.studentData.summerClothType || 0,
						summerClothSize: this.studentData.summerClothSize || 0,
						birthDate: this.studentData.birthDate,
						birthLocation: this.studentData.birthLocation,
						city: this.studentData.city,
						whatsAppPhoneNumber: this.studentData.whatsappNumber,
						telegramPhoneNumber: this.studentData.telegramNumber,
						notes: this.studentData.notes,

						edunixCode: this.studentData.edunixCode,
					})
				]).then(() => {
					let message =
						actionName === 'update' ? 'تم تعديل بيانات الطالب' : 'تمت إضافة الطالب وتسجيله';
					message = this.isComplete ? 'تم استكمال بيانات الطالب' : message;

					this.$eventBus.$emit('show-snackbar', false, message);
					this.addEditCompleteDialog = false;
					this.filterData(this.isFiltered);

					this.submitLoading = false;
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		withDraw() {
			this.withDrawLoading = true;
			this.$store.dispatch('students/withDraw', { id: this.withDrawStudentId })
				.then(() => {
					this.withDrawDialog = false;
					this.$eventBus.$emit('show-snackbar', false, 'تم الانسحاب بنجاح');
					this.filterData(this.isFiltered)
				})
				.finally(() => {
					this.withDrawLoading = false;
				})
		},

		saveRegion() {
			if (this.$refs.regionForm.validate()) {
				this.regionLoading = true;
				this.$store.dispatch('regions/create', {
					name: this.regionName
				}).then((data) => {
					this.studentData.regionId = data;
					this.regionDialog = false;
					this.$eventBus.$emit('show-snackbar', false, 'تمت إضافة المنطقة .. يتم تحديث البيانات');
					this.$store.dispatch('regions/fetchAll')
						.finally(() => {
							this.regionLoading = false;
						})
				}).catch(() => {
					this.regionLoading = false;
				})
			}
		},

		// helpers
		getStudentStateBrief() {
			// const payments = this.studentBriefData.payments.map(c => c.paymentType);
			const payedPayments = this.studentBriefData.payments.filter(c => !c.isDue).map(c => c.paymentType);
			const studentState = this.studentBriefData.state;
			const hasPlacementTest = !this.isGradeHasNoPlacementTest(this.studentBriefData.gradeId);

			let message;

			const getMessageDueStudent = (normalStr, specialStr) => {
				if (hasPlacementTest && !this.studentBriefData.isNukbahStudent) {
					return normalStr;
				} else {
					return specialStr;
				}
			}

			if (studentState === studentsStatus.registered && !payedPayments.includes(paymentTypes.registration)) {
				message = 'تم التسجيل ولم يتم دفع دفعة التسجيل';
			} else if (studentState === studentsStatus.paid && payedPayments.includes(paymentTypes.registration)) {
				message = getMessageDueStudent('بانتظار الامتحان', 'عليه دفعة استكمال');
			} else if (studentState === studentsStatus.passed && !payedPayments.includes(paymentTypes.primary)) {
				message = 'لم يستكمل';
			} else if (studentState === studentsStatus.passed && payedPayments.includes(paymentTypes.primary)) {
				message = 'مستكمل';
			} else if (studentState === studentsStatus.failed && !payedPayments.includes(paymentTypes.withDraw)) {
				message = 'بانتظار الانسحاب والاسترداد';
			} else if (studentState === studentsStatus.withDraw && !payedPayments.includes(paymentTypes.withDraw)) {
				message = 'بانتظار الاسترداد';
			} else if (studentState === studentsStatus.withDraw && payedPayments.includes(paymentTypes.withDraw)) {
				message = 'تم الاسترداد';
			} else if ([studentsStatus.retaken, studentsStatus.repeating].includes(studentState) && payedPayments.includes(paymentTypes.withDraw)) {
				message = '';
			}

			return message ? `(${message})` : '';
		},
		
		getStudentsStatus() {
			switch (this.activeTab) {
			case 1: return studentsDataStatus.registered;
			case 2: return studentsDataStatus.notCompleted;
			case 3: return studentsDataStatus.completed;
			case 4: return studentsDataStatus.withdrawn;
			case 5: return studentsDataStatus.withdrawnAndRecovered;
			default: return null
			}
		},
		getTotal() {

		},
		getStudentPlacementTestFull(item) {
			const placementTest = item || this.getPlacementTestById(this.studentData.placementTestId);
			
			if (!placementTest) { return null }

			const date = getFullDate(placementTest.testDate);

			const grade = this.getGradeById(placementTest.gradeId)?.name;
			const gender = genders.find(c => c.id === placementTest.studentsGender).plural;

			return `${grade} - ${gender} - ${date}`
		},

		isGradeHasNoPlacementTest(gradeId) {
			return this.grades.filter(c => !c.hasPlacementTest)
				.map(c => c.id).includes(gradeId)
		},

		moment,
		getFullDate,
	},

	created() {
		this.loading = true;
		Promise.all([
			this.grades.length === 0 ? this.$store.dispatch('grades/fetchAll') : null,
			this.regions.length === 0 ? this.$store.dispatch('regions/fetchAll') : null,
			this.schools.length === 0 ? this.$store.dispatch('schools/fetchAll') : null,
			this.fetchStatics(),
			this.filterData(false, true),
		]).finally(() => {
			this.loading = false;
		})

		this.$eventBus.$on('refresh', () => { this.filterData(this.isFiltered); this.fetchStatics(); });
	},

	metaInfo: {
		title: 'بيانات الطلاب',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style>
#create .v-speed-dial {
	position: absolute;
}

#create .v-btn--floating {
	position: relative;
}
</style>