export default {
	namespaced: true,

	actions: {
		fetchStudentPayments({ commit }, { studentId }) {
			return axios.get(`/payments/student/${studentId}`).then(({ data }) => data)
		},

		fetchStatistics({ commit }, { name, studentNo, gender, gradeId, gradeLevel, startDate, endDate, placementTestId }) {
			return axios.get('/payments/statistics', {
				params: { name, studentNo, gender, gradeId, gradeLevel, startDate, endDate, placementTestId }
			}).then(({ data }) => data)
		},

		create({ commit }, { studentId, amount, noteNo, receiptNo, note }) {
			return axios.post('/payments/placementTestRegistration', { 
				studentId, 
				amount, 
				noteNo, 
				receiptNo,
				note
			}).then(({ data }) => {
				return data;
			})
		},

		createNewPayment({ commit }, { studentId, amount, noteNo, receiptNo, note }) {
			return axios.post('/payments', {
				studentId,
				amount,
				noteNo,
				receiptNo,
				note
			})
		},

		updateRegistrationPayment({ commit }, { id, studentId, amount, noteNo, receiptNo, note }) {
			return axios.put(`/payments/placementTestRegistration/${id}`, { 
				studentId, 
				amount, 
				noteNo, 
				receiptNo,
				note
			}).then(({ data }) => {
				return data;
			})
		},

		update({ commit }, { id, studentId, amount, noteNo, receiptNo, note, hasReceiptReceived }) {
			return axios.put(`/payments/${id}`, { 
				studentId, 
				amount, 
				noteNo, 
				receiptNo,
				note,
				hasReceiptReceived
			}).then(({ data }) => {
				return data;
			})
		},

		pay({ commit }, { id, studentId, amount, noteNo, receiptNo, note, hasReceiptReceived }) {
			return axios.put(`/payments/${id}/pay`, { studentId, amount, noteNo, receiptNo, note, hasReceiptReceived })
		},

		delete({ commit }, { id }) {
			return axios.delete(`/payments/${id}`)
		}
	}
}