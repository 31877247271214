// export const BASE_API_URL = 'https://edunix-registration-api.beetronix.com';
export const BASE_API_URL = 'https://mirror-registration-api.beetronix.com';
// export const BASE_API_URL = 'http://localhost:26389';
// export const BASE_API_URL = 'https://registration-api.alnukhbeh-edu.com';
export const EDUNIX_API = {
	baseURL: 'https://edunix-api.beetronix.com',
	username: 'admin',
	password: 'a',
	/* baseURL: 'https://api.alnukhbeh-edu.com',
	username: 'dr.fadi',
	password: 'helloAdmin', */
}
export const VERSION = '3.8.0';