import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		schools: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/school').then(({ data }) => {
				commit(types.STORE_SCHOOLS, data);
				return data;
			})
		},

		fetchAllByFilter({ commit }, { schoolName }) {
			return axios.get('/school', { params: { schoolName } }).then(({ data }) => data)
		},

		create({ commit }, { name, notes }) {
			return axios.post('/school', { name, notes });
		},

		update({ commit }, { id, name, notes }) {
			return axios.put(`/school/${id}`, { name, notes });
		},

		delete({ comit }, { id }) {
			return axios.delete(`/school/${id}`)
		},
	},

	mutations: {
		[types.STORE_SCHOOLS](state, schools) {
			state.schools = schools;
		}
	},

	getters: {
		getSchoolById: state => id => state.schools.find(c => c.id === id)
	}
}