// import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		authenticated: false,
		user: {
			name: null,
			username: null,
			userType: null,
		},
		isAdmin: false,
	},

	actions: {
		fetchById({ commit }, { id }) {
			return axios.get(`/users/${id}`).then(({ data }) => data)
		}
	},

	mutations: {
	}
}