<template>
	<div id="placemen-tests">
		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="640"
			content-class="rounded-xl"
			persistent
			id="placemen-tests-dialog"
		>
			<v-form ref="form" @submit.prevent="savePlacementTest" :disabled="submitLoading">
				<v-card class="py-4 px-4">
					<v-card-title class="lightBlue--text pb-4">
						<span v-if="testData.editTestId">تعديل الامتحان</span>
						<span v-else>إضافة امتحان</span>
					</v-card-title>
					<v-card-text>
						<v-row class="ma-0">
							<!-- grade -->
							<v-col md="4" sm="6" cols="12" class="pa-2">
								<v-autocomplete 
									v-model="testData.gradeId"
									:items="gradesHasPlacementTest"
									item-text="name"
									item-value="id"
									hide-details
									class="grey--text rounded-medium"
									label="الصف"
									auto-select-first
									dense
									outlined
									:rules="rules.required"
								></v-autocomplete>
							</v-col>

							<!-- gender -->
							<v-col md="4" sm="6" cols="12" class="pa-2">
								<v-autocomplete
									v-model.number="testData.studentsGender"
									:items="genders"
									item-text="plural"
									item-value="id"
									label="الفئة"
									outlined
									hide-details
									auto-select-first
									dense
									class="rounded-medium grey--text"
									:rules="rules.requiredAsNumber"
								></v-autocomplete>
							</v-col>

							<!-- date -->
							<v-col md="4" cols="12" class="pa-2">
								<v-menu
									ref="dateMenu"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									min-width="auto"
									:disabled="submitLoading"
								>
									<template  v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="testData.date"
											hide-details
											outlined
											dense
											class="rounded-medium"
											prepend-inner-icon="mdi-calendar"
											readonly
											:disabled="submitLoading"
											v-bind="attrs"
											v-on="on"
											label="تاريخ الامتحان"
											:rules="rules.required"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="testData.date"
										:max="(new Date(Date.now()*1.0188 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
										min="1950-01-01"
										@change="$refs.dateMenu.save($event)"
									></v-date-picker>
								</v-menu>
							</v-col>

							<!-- time -->
							<v-col cols="12" class="pa-2 text-center">
								<v-time-picker
									v-model="testData.time"
									format="ampm"
									:header-color="isValid ? 'grey' : 'error lighten-2'"
									width="200"
									:landscape="$vuetify.breakpoint.smAndUp"
									:disabled="submitLoading"
									:rules="rules.required"
									@change="isValid = true;"
								></v-time-picker>
							</v-col>
						</v-row>

						<!-- subjects -->
						<div class="d-flex align-center">
							<p class="text-body-1 primary--text mt-3">مواد السبر</p>

							<!-- add -->
							<v-btn
								v-if="!submitLoading"
								color="warning"
								icon
								@click="placemenTestsSubjects.push({
									no: placemenTestsSubjects.length,
									name: null,
									mark: null
								})"
							>
								<v-icon>mdi-plus-circle-outline</v-icon>
							</v-btn>
						</div>

						<!-- rows -->
						<v-row dense class="ma-0">
							<v-col
								v-for="(subject, index) in placemenTestsSubjects"
								:key="index"
								md="6"
								cols="12"
							>
								<v-row dense class="ma-0">
									<!-- name -->
									<v-col sm="6" cols="12">
										<v-text-field
											v-model="subject.name"
											label="المادة"
											outlined
											dense
											hide-details
											class="grey--text rounded-medium"
											:rules="rules.required"
										/>
									</v-col>
								
									<!-- mark -->
									<v-col sm="4" cols="12">
										<v-text-field
											v-model.number="subject.mark"
											label="العلامة"
											outlined
											dense
											hide-details
											hide-spin-buttons
											type="number"
											class="grey--text rounded-medium"
											:rules="rules.requiredAsNumber"
										/>
									</v-col>
								
									<!-- remove -->
									<v-col cols="2">
										<v-btn
											v-if="!submitLoading"
											color="warning"
											icon
											:disabled="Boolean(subject.id)"
											@click="
												placemenTestsSubjects.splice(index, 1);
												placemenTestsSubjects.forEach(
													(c, i, arr) => c.no > index ? arr[i].no--  : null
												)
											"
										>
											<v-icon>mdi-minus-circle-outline</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							type="submit"
							color="lightGreen white--text"
							class="rounded-xl px-5"
							:loading="submitLoading"
							:disabled="submitLoading"
						>
							حفظ
						</v-btn>
						<v-btn
							text
							@click="dialog = false"
							:disabled="submitLoading"
							class="rounded-xl px-5"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- confirm disable dialog -->
		<v-dialog
			v-model="confirmDialog.disableDialog"
			width="430"
			content-class="rounded-xl"
			persistent
		>
			<v-card class="py-4 px-4">
				<v-card-title class="error--text text--lighten-2 pb-4">
					تأكيد تعطيل امتحان
				</v-card-title>
				<v-card-text>
					هل أنت متأكد من تعطيل الامتحان <span class="error--text text--lighten-2">{{confirmDialog.disableText}}</span>؟
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						type="submit"
						color="error lighten-2 white--text"
						class="rounded-xl px-5"
						:disabled="submitLoading"
						:loading="submitLoading"
						@click="
							confirmDialog.isChangeActivePlacement
								? (() => { confirmDialog.disableDialog = false; confirmDialog.activeDialog = true; })()
								: changePlacementTestState(confirmDialog.placementTestId, confirmDialog.placementTestIsActive)
						"
					>
						تعطيل
					</v-btn>
					<v-btn
						text
						class="rounded-xl px-5"
						:disabled="submitLoading"
						@click="confirmDialog.disableDialog = false"
					>
						إلغاء
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- confirm activate dialog -->
		<v-dialog
			v-model="confirmDialog.activeDialog"
			width="430"
			content-class="rounded-xl"
			persistent
		>
			<v-card class="py-4 px-4">
				<v-card-title class="lightBlue--text pb-4">
					تأكيد تفعيل امتحان
				</v-card-title>
				<v-card-text>
					هل أنت متأكد من تفعيل الامتحان <span class="lightBlue--text">{{confirmDialog.activeText}}</span>؟
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						type="submit"
						color="lightGreen white--text"
						class="rounded-xl px-5"
						:disabled="submitLoading"
						:loading="submitLoading"
						@click="changePlacementTestState(confirmDialog.placementTestId, confirmDialog.placementTestIsActive)"
					>
						تفعيل
					</v-btn>
					<v-btn
						text
						class="rounded-xl px-5"
						:disabled="submitLoading"
						@click="confirmDialog.activeDialog = false"
					>
						إلغاء
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<!-- tabs -->
		<div v-if="loading" class="d-flex justify-center align-center" style="height: 65vh">
			<v-progress-circular	
				indeterminate
				color="primary"
				class="mx-auto"
				size="40"
			/>
		</div>
		<v-tabs v-else v-model="tab" grow class="rounded-medium overflow-hidden elevation-3">
			<!-- active-class="deep-purple lighten-5" -->
			<v-tab
				v-for="grade in gradesHasPlacementTest"
				:key="grade.id"
			>
				{{grade.name}}
			</v-tab>

			<v-tab-item
				v-for="grade in gradesHasPlacementTest"
				:key="grade.id"
				class="pa-6 bgColor"
			>
				<v-row>
					<v-col
						v-for="gender in genders"
						:key="gender.id"
						md="6" cols="12"
						class="pa-2"
					>
						<v-card elevation="1" class="rounded-medium">
							<v-card-title class="text-body-1">
								{{gender.plural}}
							</v-card-title>
							<v-card-text>
								<v-card
									v-for="(placementTest, index) in filterPlacemenTests(gender.id, grade.id)"
									:key="placementTest.id"
									elevation="0"
									class="rounded-medium"
									color="bgColor"
									:class="{'mt-3': index !== 0}"
								>
									<v-card-text class="d-flex align-center py-1">
										<v-switch
											v-model="placementTest.isActive"
											:label="moment(placementTest.testDate)"
											hide-details
											inset
											dense
											readonly
											class="flex-grow-1 ma-0"
											:disabled="submitLoading"
											:loading="submitLoading && placementTest.id === placementTestChangeId"
											@click="findConfirmDialogState(placementTest)"
										></v-switch>
								
										<v-btn
											icon
											:disabled="submitLoading"
											@click="testData.editTestId = placementTest.id; testData.gradeId = grade.id; testData.studentsGender = gender.id; dialog = true"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</v-card-text>
								</v-card>

								<p
									v-if="filterPlacemenTests(gender.id, grade.id).length === 0"
									class="text-center mb-0"
								>
									لا يوجد امتحانات
								</p>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-tab-item>
		</v-tabs>

		<!-- add button -->
		<v-btn
			fab
			dark
			fixed
			left
			bottom
			color="primary"
			@click="dialog = true"
		>
			<v-icon>mdi-plus</v-icon>
		</v-btn>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { genders, gendersIds } from '@/helpers/enums'
import moment from 'moment'
import rules from '@/helpers/validation rules'
export default {
	name: 'PlacementTests',

	data() {
		return {
			// helpers
			tab: 0,
			loading: false,
			genders,
			gendersIds,
			rules,
			
			placementTestChangeId: null,

			dialog: false,
			submitLoading: null,
			isValid: true,

			confirmDialog: {
				isChangeActivePlacement: false,
				
				disableDialog: false,
				disableText: null,

				activeDialog: false,
				activeText: null,

				placementTestId: null,
				placementTestIsActive: null,
			},
			
			// data
			testData: {
				editTestId: null,
				date: null,
				time: null,
				gradeId: null,
				studentsGender: null,
			},

			placemenTestsSubjects: [],
		}
	},

	watch: {
		dialog(val) {
			if (val) {
				if (this.testData.editTestId) {
					const placementTest = this.getPlacementTestById(this.testData.editTestId);
					this.testData.date = moment(placementTest.testDate).format('YYYY-MM-DD');
					this.testData.time = moment(placementTest.testDate).format('HH:mm');

					placementTest.exams.forEach((c, index) => {
						this.placemenTestsSubjects.push({
							no: index,
							id: c.id,
							name: c.subject,
							mark: c.mark
						})
					})
				} else {
					this.testData.gradeId = this.gradesHasPlacementTest[this.tab].id;
				}
			} else {
				this.$refs.form.reset();
				this.isValid = true;
				this.testData.editTestId = null;
				this.testData.time = null;
				this.placemenTestsSubjects = []
			}
		}
	},

	computed: {
		...mapState({
			grades: state => state.grades.grades,
			placementTests: state => state.placementTests.placementTests,
		}),
		...mapGetters({
			getGradeById: 'grades/getGradeById',
			getPlacementTestById: 'placementTests/getPlacementTestById',
		}),

		gradesHasPlacementTest() {
			return this.grades.filter(c => c.hasPlacementTest);
		}
	},

	methods: {
		filterPlacemenTests(genderId, gradeId) {
			return this.placementTests.filter(c => c.studentsGender === genderId && c.gradeId === gradeId)
		},

		savePlacementTest() { 
			this.isValid = this.$refs.form.validate() && this.testData.time;
			if (this.isValid) {
				this.submitLoading = true
				const dateInISO = `${this.testData.date}T${this.testData.time}`;
				const actionName = this.testData.editTestId ? 'update' : 'create';
				this.$store.dispatch(`placementTests/${actionName}`, {
					id: this.testData.editTestId,
					gradeId: this.testData.gradeId,
					studentsGender: this.testData.studentsGender,
					testDate: dateInISO,
					successRatio: 60,
					paymentAmount: this.getGradeById(this.testData.gradeId).payments.registrationPayment,
					examIds:
						this.testData.editTestId
							? this.placemenTestsSubjects.filter(c => c.id).map(c => c.id)
							: null
				}).then((placemenTestId) => {
					const saveSubject = (subject, actionName) => {
						return this.$store.dispatch(`subjects/${actionName}`, {
							id: subject.id,
							name: subject.name,
							maxMark: subject.mark,
							placementTestId: this.testData.editTestId || placemenTestId,
						});
					}

					const promises = [];
					const placementTest = this.getPlacementTestById(this.testData.editTestId);
					if (placementTest) {
						const exams = placementTest.exams;
						const examsIds = exams.map(c => c.id);
						const placemenTestsSubjectsIds = this.placemenTestsSubjects.map(c => c.id);
						this.placemenTestsSubjects.forEach(c => {
							if (examsIds.includes(c.id)) {
								const matchedExam = exams.find(e => e.id === c.id);
								if (c.name !== matchedExam.subject || c.mark !== matchedExam.mark) {
									promises.push(saveSubject(c, 'update'));
								}
							} else {
								promises.push(saveSubject(c, 'create'))
							}
						});
						
						examsIds.forEach(id => {
							if (!placemenTestsSubjectsIds.includes(id)) {
								promises.push(saveSubject({ id }, 'delete'));
							}
						})
					} else {
						this.placemenTestsSubjects.forEach(c => {
							promises.push(saveSubject(c, 'create'));
						})
					}

					Promise.all(promises).then(() => {
						this.fetchData();
						this.dialog = false;
					}).finally(() => {
						this.submitLoading = false;
					})
				})
			}
		},

		findConfirmDialogState(placementTest) {
			const grade = this.getGradeById(placementTest.gradeId);
			const placementTests = this.filterPlacemenTests(placementTest.studentsGender, grade.id);
			
			this.confirmDialog.placementTestId = placementTest.id;
			this.confirmDialog.placementTestIsActive = placementTest.isActive;
			
			const activePlacementTest = placementTests.find(c => c.id !== placementTest.id && c.isActive);

			if (activePlacementTest && !placementTest.isActive) {
				this.confirmDialog.isChangeActivePlacement = true;
				this.confirmDialog.disableDialog = true;
			} else if (!activePlacementTest && !placementTest.isActive) {
				this.confirmDialog.isChangeActivePlacement = false;
				this.confirmDialog.activeDialog = true;
			} else if (!activePlacementTest) {
				this.confirmDialog.isChangeActivePlacement = false;
				this.confirmDialog.disableDialog = true;
			}
				
			this.confirmDialog.activeText =
				this.getGradeById(placementTest.gradeId).name + ' ' +
				this.genders.find(c => c.id === placementTest.studentsGender).plural + ' ' +
				this.moment(placementTest.testDate);

			this.confirmDialog.disableText = 
				activePlacementTest
					? 	this.getGradeById(activePlacementTest.gradeId).name + ' ' +
						this.genders.find(c => c.id === activePlacementTest.studentsGender).plural + ' ' +
						this.moment(activePlacementTest.testDate)
					: this.confirmDialog.activeText
		},

		fetchData(isFirstLoad = false) {
			this.loading = true;
			Promise.all([
				!isFirstLoad || this.grades.length === 0 ? this.$store.dispatch('grades/fetchAll') : null,
				this.$store.dispatch('placementTests/fetchAll')
			]).finally(() => {
				this.loading = false;
			})
		},

		changePlacementTestState(placemenTestsId, state) {
			const actionName = !state ? 'updateActive' : 'disable'
			this.placementTestChangeId = placemenTestsId;
			this.submitLoading = true;
			this.$store.dispatch(`placementTests/${actionName}`, {
				id: placemenTestsId
			}).then(() => {
				this.$store.dispatch('placementTests/fetchAll').finally(() => {
					this.placementTestChangeId = null;
					this.submitLoading = false;
					
					this.confirmDialog.activeDialog = false;
					this.confirmDialog.disableDialog = false;
				})
			})
		},

		// helpers
		moment(date) {
			return moment(date).locale('ar').format('dddd DD-MM-YYYY الساعة hh:mm a')
		}
	},

	created() {
		this.fetchData(true);
	},

	metaInfo: {
		title: 'الامتحانات',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style lang="scss">
#placemen-tests {
	.v-input--switch .v-input__slot {
		justify-content: space-between;
		flex-direction: row-reverse;
		flex-grow: 1;

		.v-label--is-disabled {
			color: rgba(0, 0, 0, 0.6);
		}
	}
	.v-input--selection-controls__input {
		transform: scaleX(-1);
	}
}
</style>