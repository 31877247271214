import { BASE_API_URL } from '@/constants';
import { getQueryUrl } from '@/helpers/helpers';

export default {
	namespaced: true,

	actions: {
		placementTest({ commit }, { userId, centerId, placementTestId, status, onlyWithPassExemption, withMarks }) {
			const url = BASE_API_URL + '/studentsReport/placementTest';
			getQueryUrl(
				url,
				{ userId, centerId, placementTestId, status, onlyWithPassExemption, withMarks, sortByPass: true },
				true
			);
			return new Promise((resolve) => {
				setTimeout(() => { resolve('') }, 1000);
			})
		},

		exportMarksTemplate({ commit }, { userId, centerId, placementTestId }) {
			const url = BASE_API_URL + '/studentsReport/marks/template/export';
			getQueryUrl(url, { userId, centerId, placementTestId }, true);
			return new Promise((resolve) => {
				setTimeout(() => { resolve('') }, 1000);
			})
		},

		transports({ commit }, { centerId, gradeLevel, gradeId, gender, regionIds, withTransportation }) {
			const url = BASE_API_URL + '/studentsReport/transports';
			getQueryUrl(url, { centerId, gradeLevel, gradeId, gender, regionIds, withTransportation }, true);
			return new Promise((resolve) => {
				setTimeout(() => { resolve('') }, 1000);
			})
		},

		studentDataAndMarks({ commit }, { centerId, gradeLevel, gradeId, gender, regionIds }) {
			const url = BASE_API_URL + '/studentsReport/studentsScreening';
			getQueryUrl(url, { centerId, gradeEducationalLevel: gradeLevel, gradeId, gender, regionIds }, true);
			return new Promise((resolve) => {
				setTimeout(() => { resolve('') }, 1000);
			})
		},

		cloths({ commit }, { centerId, gradeLevel, gradeId, gender }) {
			const url = BASE_API_URL + '/studentsReport/dress';
			getQueryUrl(url, { centerId, gradeEducationalLevel: gradeLevel, gradeId, gender }, true);
			return new Promise((resolve) => {
				setTimeout(() => { resolve('') }, 1000);
			})
		},

		clothsOrder({ commit }, { centerId, gradeLevel, gradeId, gender }) {
			const url = BASE_API_URL + '/studentsReport/dressOrder';
			getQueryUrl(url, { centerId, gender, gradeId, gradeEducationalLevel: gradeLevel }, true);
			return new Promise((resolve) => {
				setTimeout(() => { resolve('') }, 1000);
			})
		}
	}
}