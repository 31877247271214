import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		subjects: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/exam/all').then(({ data }) => {
				commit(types.STORE_SUBJECTS, data);
				return data
			})
		},

		create({ commit }, { name, placementTestId, maxMark }) {
			return axios.post('/exam', { subject: name, placementTestId, maxMark })
		},

		update({ commit }, { id, name, placementTestId, maxMark }) {
			return axios.put(`/exam/${id}`, { subject: name, placementTestId, maxMark })
		},

		delete({ commit }, { id }) {
			return axios.delete(`/exam/${id}`)
		},
	},

	mutations: {
		[types.STORE_SUBJECTS](state, subjects) {
			state.subjects = subjects;
		}
	},

	getters: {
		getSubjectById: state => id => state.subjects.find(c => c.id === id)
	}
}