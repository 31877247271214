import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		placementTests: [],
		activePlacementTests: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/placementTests').then(({ data }) => {
				commit(types.STORE_PLACEMENT_TESTS, data)
				return data;
			})
		},
		fetchAllActive({ commit }) {
			return axios.get('/placementTests/getActive').then(({ data }) => {
				commit(types.STORE_ACTIVE_PLACEMENT_TESTS, data)
				return data;
			})
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/placementTests/${id}`).then(({ data }) => {
				return data;
			})
		},
		create({ commit }, { gradeId, studentsGender, testDate, successRatio, paymentAmount }) {
			return axios.post('/placementTests', {
				gradeId,
				studentsGender,
				testDate,
				successRatio,
				paymentAmount
			}).then(({ data }) => data)
		},
		update({ commit }, { id, gradeId, studentsGender, testDate, successRatio, paymentAmount, examIds }) {
			return axios.put(`/placementTests/${id}`, {
				gradeId,
				studentsGender,
				testDate,
				successRatio,
				paymentAmount,
				examIds
			})
		},
		updateActive({ commit }, { id }) {
			return axios.put(`/placementTests/${id}/SetActive`);
		},
		disable({ commit }, { id }) {
			return axios.put(`/placementTests/${id}/disable`);
		},
		updateSuccessRatio({ commit }, { id, successRatio }) {
			return axios.put(`/placementTests/${id}/modifySuccessRatio`, { successRatio });
		}
	},

	mutations: {
		[types.STORE_PLACEMENT_TESTS](state, placementTests) {
			state.placementTests = placementTests;
		},
		[types.STORE_ACTIVE_PLACEMENT_TESTS](state, activePlacementTests) {
			state.activePlacementTests = activePlacementTests;
		}
	},

	getters: {
		getPlacementTestById: state => id => state.placementTests.find(c => c.id === id)
	}
}