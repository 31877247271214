<template>
	<div>
		<!-- filter & print & import actions -->
		<v-form class="filter" @submit.prevent="options.page = 1; filterData(true);">
			<v-row dense class="align-center mx-0 mb-0">
				<!-- grade -->
				<v-col cols="6" sm="6" md="3" lg="1">
					<v-autocomplete
						v-model="filter.gradeId"
						:items="grades.filter(c => c.hasPlacementTest)"
						item-text="name"
						item-value="id"
						label="الصف"
						outlined
						hide-details
						dense
						auto-select-first
						append-icon
						:disabled="loading"
						@change="filter.placementTestId = null; successRatio = null;"
						class="rounded-medium grey--text"
					></v-autocomplete>
				</v-col>

				<!-- gender -->
				<v-col cols="6" sm="6" md="3" lg="1">
					<v-autocomplete
						v-model.number="filter.genderId"
						:items="genders"
						item-text="plural"
						item-value="id"
						label="الجنس"
						auto-select-first
						outlined
						hide-details
						dense
						append-icon
						:disabled="loading"
						@change="filter.placementTestId = null; successRatio = null;"
						class="rounded-medium grey--text px"
					></v-autocomplete>
				</v-col>

				<!-- placement test -->
				<v-col cols="12" sm="6" md="3" lg="2">
					<v-autocomplete
						v-model="filter.placementTestId"
						:items="
							placementTests.filter(
								c => c.gradeId === filter.gradeId && c.studentsGender === filter.genderId
							)
						"
						item-value="id"
						:item-text="(item) => getFullDate(item.testDate)"
						hide-details
						outlined
						dense
						class="rounded-medium"
						append-icon
						:loading="!placementTests.length"
						:disabled="!(filter.gradeId && filter.genderId !== null && filter.genderId >= 0) || loading"
						@change="successRatio = getPlacementTestById(filter.placementTestId)?.successRatio"
						label="الامتحان"
					></v-autocomplete>
				</v-col>

				<!-- student no -->
				<v-col cols="12" sm="6" md="3" lg="1">
					<v-text-field
						v-model="filter.studentNo"
						label="الرقم"
						outlined
						hide-details
						dense
						:disabled="loading || !filter.placementTestId"
						class="rounded-medium grey--text"
					></v-text-field>
				</v-col>

				<!-- student name -->
				<v-col cols="12" sm="6" md="3" lg="1">
					<v-text-field 
						v-model="filter.studentName"
						hide-details
						class="grey--text rounded-medium grey--text"
						label="الاسم"
						dense
						outlined
						:disabled="loading || !filter.placementTestId"
					></v-text-field>
				</v-col>

				<!-- search button -->
				<v-col cols="auto">
					<v-btn
						type="submit"
						color="primary"
						class="rounded-xl px-5"
						:disabled="loading || !filter.placementTestId || updateSuccessRatioLoading"
					>عرض</v-btn>
				</v-col>

				<v-spacer/>

				<!-- success ratio -->
				<v-col lg="2" md="4" cols="6" class="d-flex flex-wrap justify-center">
					<v-flex md7 sm6>
						<!-- success ratio -->
						<v-text-field
							v-model.number="successRatio"
							label="حد النجاح"
							type="number"
							min="0"
							max="100"
							hide-spin-buttons
							hide-details
							prefix="%"
							dense
							outlined
							:append-icon="isEditSuccessRatio ? 'mdi-pencil-off' : 'mdi-pencil'"
							:readonly="!isEditSuccessRatio"
							:disabled="loading || !filter.placementTestId || updateSuccessRatioLoading"
							class="rounded-medium me-3"
							persistent-placeholder
							:rules="[(val) => !isNaN(val) && val > 0 && val <= 100 || false]"
							@click:append="isEditSuccessRatio = !isEditSuccessRatio"
							@keypress.enter="isEditSuccessRatio ? updateSuccessRatio() : null"
						></v-text-field>
					</v-flex>

					<!-- update success ratio button -->
					<v-flex md3 sm6>
						<v-btn
							v-if="isEditSuccessRatio"
							color="lightGreen white--text"
							class="rounded-xl px-5"
							:loading="updateSuccessRatioLoading"
							:disabled="loading || updateSuccessRatioLoading"
							@click="updateSuccessRatio"
						>
							تحديث
						</v-btn>
					</v-flex>
				</v-col>

				<v-spacer/>

				<!-- print -->
				<v-col cols="auto">
					<v-btn
						color="lightGreen white--text"
						class="rounded-xl px-5"
						:loading="printLoading"
						:disabled="loading || printLoading || updateSuccessRatioLoading || isFilterFieldsEmpty"
						@click="downloadFile()"
					>طباعة</v-btn>
				</v-col>

				<!-- export actions -->
				<v-col cols="auto">
					<v-btn
						color="lightBlue white--text"
						class="rounded-xl px-5"
						:disabled="isFilterFieldsEmpty"
						@click="importDialog = true"
					>
						استيراد العلامات
					</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- import dialog -->
		<v-dialog v-model="importDialog" width="465" content-class="rounded-xl" persistent>
			<v-form ref="importForm" @submit.prevent="importMarks" :disabled="submitLoading">
				<v-card class="py-4 px-4">
					<v-card-title class="lightBlue--text pb-4">
						استيراد العلامات
					</v-card-title>
					<v-card-text>
						<v-row>
							<!-- grade -->
							<v-col sm="6" cols="12" class="px-2">
								<v-autocomplete
									v-model="importGradeId"
									:items="grades.filter(c => c.hasPlacementTest)"
									item-text="name"
									item-value="id"
									label="الصف"
									outlined
									hide-details
									dense
									auto-select-first
									append-icon
									:disabled="loading"
									@change="importPlacementTestId = null"
									class="rounded-medium grey--text px"
								></v-autocomplete>
							</v-col>

							<!-- gender -->
							<v-col sm="6" cols="12" class="px-2">
								<v-autocomplete
									v-model.number="importGenderId"
									:items="genders"
									item-text="plural"
									item-value="id"
									label="الجنس"
									auto-select-first
									outlined
									hide-details
									dense
									append-icon
									:disabled="loading"
									@change="importPlacementTestId = null"
									class="rounded-medium grey--text px"
								></v-autocomplete>
							</v-col>

							<!-- placement test -->
							<v-col cols="12" class="px-2">
								<v-autocomplete
									v-model="importPlacementTestId"
									label="الامتحان"
									:items="
										placementTests.filter(
											c => c.gradeId === importGradeId && c.studentsGender === importGenderId
										)
									"
									item-value="id"
									:item-text="(item) => getFullDate(item.testDate)"
									hide-details
									outlined
									dense
									class="rounded-medium"
									append-icon
									:loading="!placementTests.length"
									:rules="rules.required"
								></v-autocomplete>
							</v-col>

							<!-- import file -->
							<v-col cols="12" class="px-2">
								<v-file-input
									v-model="importFile"
									hide-details
									class="grey--text rounded-medium grey--text"
									label="ملف excel"
									dense
									outlined
									:rules="rules.file"
									accept=".xlsx, .xls"
								></v-file-input>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							type="submit"
							color="lightGreen white--text"
							class="rounded-xl px-5"
							:loading="submitLoading"
							:disabled="submitLoading"
						>
							استيراد
						</v-btn>
						<v-btn
							text
							@click="importDialog = false"
							:disabled="submitLoading"
							class="rounded-xl px-5"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- marks dialog -->
		<v-dialog v-model="marksDialog" width="500" content-class="rounded-xl" persistent>
			<v-form
				ref="marksForm"
				@submit.prevent="saveStudentMarks"
			>
				<v-card class="py-4 px-4">
					<v-card-title class="lightBlue--text pb-4">
						{{isAddingMarks ? 'إدخال' : 'تعديل'}} العلامات

						<!-- student name -->
						<span class="text-body-1 grey--text text--darken-3 ms-2">
							({{`${studentData.firstName || ''} ${studentData.lastName || ''}`}})
						</span>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col
								v-for="(item, index) in marksInputs"
								:key="index"
								sm="6"
								cols="12"
							>
								<v-text-field
									v-model.number="marksInputs[index].value"
									:label="marksInputs[index].label"
									outlined
									dense
									class="rounded-medium"
									type="number"
									min="0"
									hide-spin-buttons
									hide-details
									:prefix="maxValueMark(item.examId) + ' /'"
									:rules="[val => !isNaN(val) && typeof val !== 'string' && val >= 0 && val <= maxValueMark(item.examId)]"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							type="submit"
							color="lightGreen white--text"
							class="rounded-xl px-5"
							:loading="submitLoading"
							:disabled="submitLoading"
						>
							حفظ
						</v-btn>
						<v-btn
							text
							@click="marksDialog = false"
							:disabled="submitLoading"
							class="rounded-xl px-5"
						>
							إلغاء
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- exemption dialog -->
		<exemption-dialog
			v-model="exemptionDialog"
			:studentHasExemption="studentHasExemption"
			:exemptionStudentId="exemptionStudentId"
			@succeeded="filterData(isFiltered);"
		/>

		<!-- tabs -->
		<v-tabs
			v-model="activeTab"
			centered
			grow
			height="35"
			class="rounded-medium elevation-2 mb-1"
			style="overflow: hidden"
			show-arrows
			center-active
			@change="filterData(true)"
		>
			<v-tab
				v-for="tab in tabs"
				:key="tab.id"
				:disabled="loading || isFilterFieldsEmpty"
			>
				{{tab.name}}
			</v-tab>
		</v-tabs>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : students"
			:loading="loading"
			:footer-props="{ 'items-per-page-options': [25, 50, 100] }"
			fixed-header
			dense
			:disable-sort="loading || !students.length"
			:height="$vuetify.breakpoint.height - 266"
			:options.sync="options"
			:server-items-length="totalItems"
			class="elevation-2 rounded-medium pt-2 px-2"
		>
			<!-- no -->
			<template v-slot:item.no="{ index }">
				{{index + 1}}
			</template>

			<!-- name -->
			<template v-slot:item.firstName="{ item }">
				{{`${item.firstName} ${item.lastName ? item.lastName : '' }`}}
			</template>
			
			<!-- state -->
			<template v-slot:item.state="{ item }">
				{{ getStudentStatusText(item.state) | isAvailable }}
			</template>
			
			<!-- all marks -->
			<template v-slot:item.allMarks="{ item }">
				<v-row
					v-if="item.marks.length"
					class="flex-md-row flex-column justify-space-around ma-0 me-md-6"
				>
					<v-col
						v-for="item in item.marks"
						:key="item.id"
						cols="auto"
						class="py-0 px-1"
					>
						<span class="text-caption grey--text text--darken-1">{{item.subject}}</span>: 
						<span>{{item.mark}}</span>
					</v-col>
				</v-row>
				<template v-else>-</template>
			</template>

			<!-- result total -->
			<template v-slot:item.marks="{ item }">
				{{ item.marks.length ? item.marks.map(c => c.mark).reduce((a, b) => a + b) : '-'}}
			</template>

			<!-- exemption -->
			<template v-slot:item.exemption="{ item }">
				{{
					(item.exemption 
						? item.exemption.description
						: null
					) | isAvailable
				}}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex" style="padding: 2px 0">
					<!-- edit -->
					<v-tooltip top>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								:color="!item.marks.length ? 'lightGreen' : 'warning'"
								class="rounded-medium text-body-2 white--text"
								width="32"
								min-width="32"
								height="32"
								@click="studentData = item; marksDialog = true;"
							>
								<v-icon dense>
									{{
										!item.marks.length
											? 'mdi-text-box-edit-outline'
											: 'mdi-square-edit-outline'
									}}
								</v-icon>
							</v-btn>
						</template>
						{{isAddingMarks ? 'إدخال' : 'تعديل'}} العلامات
					</v-tooltip>

					<!-- exemption -->
					<template>
						<v-tooltip top>
							<template v-slot:activator="{ attrs, on }">
								<v-btn
									@click="exemptionStudentId = item.id; exemptionDialog = true"
									:color="item.hasExemption ? 'deepOrange' : 'lightBlue'"
									class="ms-1 rounded-medium text-body-2 white--text"
									width="32"
									min-width="32"
									height="32"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-alert-circle-outline</v-icon>
								</v-btn>
							</template>

							{{item.hasExemption ? 'مستثنى' : 'استثناء'}}
						</v-tooltip>
					</template>
				</div>
			</template>

			<!-- page text -->
			<template v-slot:footer.page-text="items"> 
				<span>{{ items.pageStart }} - {{ items.pageStop }} من {{ items.itemsLength }}</span>
			</template>
		</v-data-table>

		<!-- totals -->
		<v-card elevation="1" class="flex-grow-1 mt-1 rounded-medium">
			<v-card-text class="d-flex flex-wrap justify-sm-space-between justify-space-around black--text d-flex px-2 py-1">
				<div v-if="[0, 1].includes(activeTab)">
					عدد الناجحين: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						{{statistics.studentsSucceeded || 0}} طالب
					</span>
				</div>
				<div v-if="[0, 2].includes(activeTab)">
					عدد الراسبين: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						{{statistics.studentsFailed || 0}} طالب
					</span>
				</div>
				<div v-if="[0, 3].includes(activeTab)">
					عدد المجتازين: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						{{statistics.studentsSucceededExemptions || 0}} طالب
					</span>
				</div>
				<div v-if="[0].includes(activeTab)">
					العدد الكلي: 
					<span class="text-body-1 font-weight-medium darkRed--text mx-1" >
						{{statistics.studentsCount || 0}} طالب
					</span>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import ExemptionDialog from '@/components/ExemptionDialog.vue'
import moment from 'moment';
import { genders, studentsStatus } from '@/helpers/enums';
import { getFullDate, getSortData } from '@/helpers/helpers';
import { mapGetters, mapState } from 'vuex';
import rules from '@/helpers/validation rules';

export default {
	name: 'PlacementTestResults',

	components: {
		ExemptionDialog
	},

	data() {
		return {
			genders,
			studentsStatus,
			rules,

			importDialog: false,
			importGradeId: null,
			importGenderId: null,

			submitLoading: false,
			loading: false,
			updateSuccessRatioLoading: false,
			
			isEditSuccessRatio: false,

			printLoading: false,

			activeTab: 0,
			tabs: [
				{ id: 1, name: 'الكل' },
				{ id: 2, name: 'الناجحين' },
				{ id: 3, name: 'الراسبين' },
				{ id: 4, name: 'المجتازين' },
			],

			headers: [
				{ text: '#', value: 'no', align: 'center', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, width: 30 },
				{ text: 'الرقم', value: 'studentNo', class: 'px-1', cellClass: 'text-body-2 px-1', divider: true, sortable: false, },
				{ text: 'الاسم', value: 'firstName', class: 'px-1', cellClass: 'text-body-2 px-1' },
				{ text: 'م. التاسع', value: 'ninthGradeTotal', class: 'px-1', cellClass: 'text-body-2 px-1' },
				{ text: 'العلامات', value: 'allMarks', width: 450, align: 'center', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false },
				{
					text: 'المجموع',
					value: 'marks',
					class: 'px-1',
					cellClass: 'text-body-2 px-1',
					sort: (marksA, marksB) => {
						if (!marksA.map(c => c.mark).length || !marksB.map(c => c.mark).length) return 0;
						const totalA = marksA.map(c => c.mark).reduce((a, b) => a + b);
						const totalB = marksB.map(c => c.mark).reduce((a, b) => a + b);
						if (totalA < totalB) return -1
						if (totalA > totalB) return 1
						return 0
					}
				},
				{ text: 'الحالة', value: 'state', class: 'px-1', cellClass: 'text-body-2 px-1' },
				{ text: 'توضيح الاستثناء', value: 'exemption', width: 180, class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false, },
				{ text: '', value: 'actions', class: 'px-1', cellClass: 'text-body-2 px-1', sortable: false },
			],

			// pagination
			options: {},
			totalItems: null,

			// filter
			filter: {
				gradeId: null,
				genderId: null,
				placementTestId: null,
				studentNo: null,
				studentName: null,
			},
			isFiltered: false,

			// marks dialog
			marksDialog: false,
			studentData: {
				id: null,
				placementTestId: [],
				marks: [],
			},
			marksInputs: [],

			// exemption dialog
			exemptionDialog: false,
			exemptionStudentId: null,
			studentHasExemption: false,

			/* DATA */
			// import data
			importPlacementTestId: null,
			importFile: null,

			// placementTestData
			successRatio: null,
		}
	},

	watch: {
		importDialog(val) {
			if (val) {
				this.importGradeId = this.filter.gradeId;
				this.importGenderId = this.filter.genderId;
				this.importPlacementTestId = this.filter.placementTestId;
			} else {
				this.$refs.importForm.reset();
			}
		},

		marksDialog(val) {
			if (val) {
				// for adding marks
				if (this.isAddingMarks) {
					const placementTest = this.getPlacementTestById(this.studentData.placementTestId);
					this.marksInputs = placementTest.exams.map(exam => ({
						id: exam.id,
						examId: exam.id,
						label: exam.subject,
						value: null,
					}))
				} else {
				// for updating marks
					const marks = this.studentData.marks;
					marks.forEach(item => {
						this.marksInputs.push({
							id: item.id,
							examId: item.examId,
							label: item.subject,
							value: item.mark
						})
					});
				}
			} else {
				this.marksInputs = [];
				this.studentData = {
					id: null,
					placementTestId: [],
					marks: []
				}
			}
		},

		exemptionDialog(val) {
			if (val) {
				const student = this.getStudentById(this.exemptionStudentId);
				this.studentHasExemption = student.hasExemption;
			} else {
				this.exemptionStudentId = null;
				this.studentHasExemption = false;
			}
		},

		'filter.placementTestId'() {
			this.$store.dispatch('students/reset');
		},
		
		options() {
			if (this.isFiltered) this.filterData(this.isFiltered);
		}
	},

	computed: {
		...mapState({
			grades: state => state.grades.grades,
			placementTests: state => state.placementTests.placementTests,

			students: state => state.students.students,
			statistics: state => state.students.statistics,
		}),

		...mapGetters({
			getGradeById: 'grades/getGradeById',
			getStudentById: 'students/getStudentById',
			getPlacementTestById: 'placementTests/getPlacementTestById'
		}),

		isAddingMarks() { return !this.studentData.marks.length },

		isFilterFieldsEmpty() {
			return (this.filter.placementTestId === null && this.filter.studentNo === null && this.filter.studentName === null);
		}
	},

	methods: {
		// filter
		filterData(isFilter, isFirstLoad) {
			this.loading = true;
			this.isFiltered = isFilter;
			this.$eventBus.$emit('disable-refresh');

			const { page, itemsPerPage, sortBy, sortDesc } = this.options;

			const { name: byName, ninthGrade: byNinthGrade, marks: byMarks, state: byState } =
				getSortData({ sortBy, sortDesc }, {
					name: 'firstName',
					grade: 'gradeId',
					ninthGrade: 'ninthGradeTotal',
					marks: 'marks',
					state: 'state',
				});

			return this.$store.dispatch('students/fetchAllRegistered', {
				studentNo: isFilter ? this.filter.studentNo : null,
				name: isFilter ? this.filter.studentName : null,
				gradeId: isFilter ? this.filter.gradeId : null,
				gender: isFilter ? this.filter.genderId : null,
				placementTestId: isFilter ? this.filter.placementTestId : null,
				studentPlacementState: this.getStudentsStatus(),
				onlyWithPassExemption: this.activeTab === 3,
				withoutNukhbaStudents: true,
				withMarks: true,
				page,
				perPage: itemsPerPage,
				orderByName: byName,
				orderByGrade: null,
				orderByNinthGradeTotal: byNinthGrade,
				orderByMarks: byMarks || byState,
			}).then((data) => {
				this.totalItems = data.totalCount;
			}).finally(() => {
				if (!isFirstLoad) {
					this.loading = false;
				}
				this.$eventBus.$emit('enable-refresh');
			})
		},

		// success ratio
		updateSuccessRatio() {
			if (this.successRatio) {
				this.updateSuccessRatioLoading = true;
				this.$store.dispatch('placementTests/updateSuccessRatio', {
					id: this.filter.placementTestId,
					successRatio: this.successRatio
				}).then(() => {
					this.$store.dispatch('placementTests/fetchAll');
					this.isEditSuccessRatio = false;
					this.filterData(true)
				}).finally(() => {
					this.updateSuccessRatioLoading = false;
				})
			}
		},

		importMarks() {
			if (this.$refs.importForm.validate()) {
				this.submitLoading = true;
				this.$store.dispatch('studentsMarks/importExcel', {
					placementTestId: this.importPlacementTestId,
					file: this.importFile
				}).then(({ data: errorRows }) => {
					if (errorRows.length) {
						this.$eventBus.$emit(
							'show-snackbar',
							true,
							'تم استيراد العلامات .. لكن هناك خطأ في إدخالات الأسطر التالية: ' + errorRows
						);
					} else {
						this.$eventBus.$emit('show-snackbar', false, 'تم استيراد العلامات بنجاح');
					}
					this.importDialog = false;
					if (this.isFiltered) this.filterData(true);
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		// marks
		saveStudentMarks() {
			if (this.$refs.marksForm.validate()) {
				const actionName = this.isAddingMarks ? 'setMarks' : 'updateMarks';
				
				this.submitLoading = true;
				this.$store.dispatch(`studentsMarks/${actionName}`, {
					placementTestId: this.studentData.placementTestId,
					studentId: this.studentData.id,
					marks:
						this.isAddingMarks
							? this.marksInputs.map(c => ({ examId: c.examId, mark: c.value }))
							: this.marksInputs.map(c => ({ id: c.id, mark: c.value }))
				}).then(() => {
					const message =
						this.isAddingMarks
							? 'تم إدخال علامات الطالب بنجاح'
							: 'تم تحديث علامات الطالب بنجاح'
					this.marksDialog = false;
					this.$eventBus.$emit('show-snackbar', false, message);
					this.filterData(true);
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		maxValueMark(examId) {
			const placementTest = this.getPlacementTestById(this.filter.placementTestId);
			const mark = placementTest.exams.find(c => c.id === examId).mark;
			return mark
		},

		// print
		downloadFile() {
			this.printLoading = true;
			this.$store.dispatch('studentsReports/placementTest', {
				userId: JSON.parse(localStorage.getItem('auth')).id,
				centerId: JSON.parse(localStorage.getItem('auth')).centerId,
				placementTestId: this.filter.placementTestId,
				status: this.getStudentsStatus(),
				onlyWithPassExemption: this.activeTab === 3,
				withMarks: true
			}).then(() => {
				this.$eventBus.$emit('show-snackbar', false, 'سيبدأ التنزيل خلال ثوانٍ ...');
			}).finally(() => {
				this.printLoading = false;
			})
		},

		// helpers
		getStudentStatusText(state) {
			switch (state) {
			case studentsStatus.passed: return 'ناجح';
			case studentsStatus.failed: return 'راسب';
			case studentsStatus.withDraw: return 'منسحب';
			case studentsStatus.retaken: return 'إعادة سبر';
			default: return null;
			}
		},
		getStudentsStatus() {
			switch (this.activeTab) {
			case 1:
			case 3: return studentsStatus.passed;
			case 2: return studentsStatus.failed;
			}
		},
		moment,
		getFullDate,
	},

	created() {
		this.loading = true;
		Promise.all([
			this.grades.length === 0 ? this.$store.dispatch('grades/fetchAll') : null,
		]).finally(() => {
			this.loading = false;
		})

		this.$eventBus.$on('refresh', () => { this.filterData(this.isFiltered) });
	},

	mounted() {
		this.$eventBus.$emit('disable-refresh');
		this.$store.dispatch('students/reset');
	},

	metaInfo: {
		title: 'نتائج السبر',
		titleTemplate: '%s | أسرة النخبة التعليمية'
	}
}
</script>

<style>

</style>