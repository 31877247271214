export default {
	namespaced: true,

	actions: {
		importExcel({ commit }, { placementTestId, file }) {
			const formData = new FormData()
			formData.append('marks', file);
			return axios.post(`/studentsPlacementTest/${placementTestId}/setMarks`, formData);
		},

		setMarks({ commit }, { placementTestId, studentId, marks }) {
			const data = [{ studentId, examMark: marks }];
			return axios.post(`/studentsPlacementTest/${placementTestId}/SetMarksInWindow`, data);
		},

		updateMarks({ commit }, { marks }) {
			// marks is Array of objects each object = { id: '', mark: 0 }
			return axios.put('/studentsPlacementTest/updateMarks', { marks });
		}
	}
}