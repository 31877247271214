import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		regions: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/regions')
				.then(({ data }) => {
					commit(types.STORE_REGIONS, data)
					return data;
				})
		},

		fetchAllByFilter({ commit }, { regionsName }) {
			return axios.get('/regions', { params: { regionsName } }).then(({ data }) => data)
		},
		
		create({ commit }, { name, notes }) {
			return axios.post('/regions', { name, notes }).then(({ data }) => data);
		},

		update({ commit }, { id, name, notes }) {
			return axios.put(`/regions/${id}`, { name, notes });
		},

		delete({ commit }, { id }) {
			return axios.delete(`/regions/${id}`)
		}
	},

	mutations: {
		[types.STORE_REGIONS](state, regions) {
			state.regions = regions;
		}
	},

	getters: {
		getRegionById: state => id => state.regions.find(c => c.id === id)
	}
}