<template>
	<v-app-bar
		id="navbar"
		color="bgColor"
		dense
		app
		elevation ="0"
		clipped
		height="60"
	>
		<div
			class="d-flex align-center"
			@click="$route.name !== 'home' ? $router.push({ name: 'home' }) : null"
			style="cursor: pointer"
		>
			<div>
				<v-img
					max-height="54"
					max-width="54"
					:src="centerLogo ? BASE_API_URL + '/' + centerLogo : '/assets/elite-logo.png'"
					lazy-src="/assets/placeholder-image.png"
					contain
				/>
			</div>
			<div class="d-sm-block d-none mx-2 hide">
				<v-progress-linear
					v-if="centers.length === 0"
					indeterminate
					height="6"
					class="rounded-xl"
					style="width: 60px"
				></v-progress-linear>
				<p v-else class="text--size purple--text darken-1--text mb-0">
					{{centerName}}
				</p>
			</div>
		</div>

		<v-spacer></v-spacer>

		<!-- notifications -->
		<v-btn icon v-if="0">
			<v-icon 
				medim
				color="grey darken-4"
			>
				mdi-bell
			</v-icon>
		</v-btn>

		<!-- refresh -->
		<v-btn
			v-if="['students-data', 'accounting', 'placement-tests-lists', 'placement-tests-results'].includes($route.name)"
			icon
			small
			class="refresh-btn"
			:class="{ 'refresh-btn--rotate': rotateBtn}"
			:disabled="disableRefresh"
			@click="rotateBtn = true; $eventBus.$emit('refresh')"
		>
			<v-icon color="lightGreen darken-1">
				mdi-sync
			</v-icon>
		</v-btn>

		<!-- menu -->
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="transparent"
					v-bind="attrs"
					v-on="on"
					class="grey--text"
					elevation="0"
					light
					inset
				>
					<v-icon medim color="grey darken-4" class="me-1">
						mdi-account-circle
					</v-icon>
					<span class="mb-0 d-sm-block d-none  black--text">{{self.name}}</span>
					<v-icon medium class="ms-1"> mdi-chevron-down </v-icon>
				</v-btn>
			</template>

			<!-- logout -->
			<v-list dense>
				<v-list-item @click="logout">
					<v-list-item-icon class="me-3"><v-icon>mdi-logout</v-icon></v-list-item-icon>
					<v-list-item-title>تسجيل الخروج</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-divider inset vertical class="mx-3"></v-divider>

		<v-img width="40" max-width="40" src="/assets/edunix-logo.png"></v-img>
	</v-app-bar>
</template>

<script>
import { BASE_API_URL } from '@/constants'
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'Navbar',

	data() {
		return {
			rotateBtn: false,
			disableRefresh: false,
			BASE_API_URL
		}
	},

	watch: {
		rotateBtn(val) {
			if (val) {
				setTimeout(() => {
					this.rotateBtn = false;
				}, 400);
			}
		}
	},

	computed: {
		...mapState({
			self: state => state.auth.self,
			isAdmin: state => state.auth.isAdmin,
			currentCenterId: state => state.centers.currentCenterId,
			centers: state => state.centers.centers,
		}),

		...mapGetters({
			getCenterById: 'centers/getCenterById',
		}),

		centerName() {
			const defaultName = 'أسرة النخبة التعليمية';
			if (this.isAdmin && this.$route.name === 'home') {
				return defaultName;
			} else {
				return this.getCenterById(this.currentCenterId)?.name ?? defaultName;
			}
		},
		centerLogo () {
			return this.getCenterById(this.currentCenterId)?.logo ?? null
		}
	},

	methods: {
		logout() {
			this.$store.dispatch('auth/logout');
			this.$router.replace({ name: 'login' });
		}
	},

	created() {
		this.$eventBus.$on('disable-refresh', () => { this.disableRefresh = true });
		this.$eventBus.$on('enable-refresh', () => { this.disableRefresh = false });
	}
}
</script>
<style lang="scss">
.text--size {
	font-size: 1.2rem;
	@media only screen and (max-width:600px) {
		font-size: 1rem;
	}
	@media only screen and (min-width:600px) and (max-width:960px) {
		font-size: 1.3rem;
	}
}
@media only screen and (min-width:600px) and (max-width:672px) {
	.hide {
		display: none;
	}
}

.refresh-btn {
	&--rotate {
		animation: rotate .4s ease forwards;

		@keyframes rotate {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(-360deg);
			}
		}
	}
}
</style>

<style lang="scss">
#navbar {
	.v-divider--vertical.v-divider--inset {
		align-self: center;
		max-height: 35px;
	}
}
</style>
